import { NgModule } from '@angular/core';

// EVE COMPONENTS
import {EveContainerComponent} from "./eve-container/eve-container.component";
import {EveInputComponent} from "./eve-input/eve-input.component";
import {EveButtonComponent} from "./eve-buttons/eve-button/eve-button.component";
import {EveSaveButtonComponent} from "./eve-buttons/eve-save-button/eve-save-button.component";
import {EveToggleComponent} from "./eve-toggle/eve-toggle.component";
import {EveSliderComponent} from "./eve-slider/eve-slider.component";
import { EveLabelingComponent } from './eve-labeling/eve-labeling.component';
import { EveTimePickerComponent } from './eve-time-picker/eve-time-picker.component';

// ANGULAR COMPONENTS
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSliderModule} from "@angular/material/slider";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";

// THIRD PARTY COMPONENTS
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  EveUserCarouselItemComponent
} from "./eve-carousel/eve-carousel-items/eve-user/eve-user-carousel-item.component";
import { EveSelectComponent } from './eve-select/eve-select.component';
import {MatSelectModule} from "@angular/material/select";
import { EvePromptComponent } from './eve-prompt/eve-prompt.component';
import { EveDatePickerComponent } from './eve-date-picker/eve-date-picker.component';
import {DirectivesModule} from "../directives/directives.module";
import { EveValidationErrorsComponent } from './eve-validation-errors/eve-validation-errors.component';
import { EveDestinationComponent } from './eve-destination/eve-destination.component';
import { EveAvatarModalComponent } from './eve-avatar/eve-avatar-modal/eve-avatar-modal.component';
import { EveModalComponent } from './eve-modal/eve-modal.component';
import {LyImageCropperModule} from "@alyle/ui/image-cropper";
import {LySliderModule} from "@alyle/ui/slider";
import { EveCropComponent } from './eve-avatar/eve-avatar-modal/eve-crop/eve-crop.component';
import {LyIconModule} from "@alyle/ui/icon";
import { EveAvatarComponent } from './eve-avatar/eve-avatar.component';
import { EveAvatarImageComponent } from './eve-avatar/eve-avatar-image/eve-avatar-image.component';
import { EveNumberAutocompleteComponent } from './eve-number-autocomplete/eve-number-autocomplete.component';
import { EveCallRecordingComponent } from './eve-call-recording/eve-call-recording.component';
import { PipesModule } from '../pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { EveTimeZoneAutocompleteComponent } from './eve-timezone-autocomplete/eve-timezone-autocomplete.component';
import { EveScheduleActionTypesAutocompleteComponent } from './eve-schedule-action-types-autocomplete/eve-schedule-action-types-autocomplete.component';
import { EveInvalidationComponent } from './eve-invalidation/eve-invalidation.component';
import { EveCarousel } from './eve-carousel/eve-carousel.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EveTileMenuComponent, EveTileMenuItemComponent } from './eve-tile-menu/eve-tile-menu.component';
import { EvePromptManagerComponent } from './eve-prompt-manager/eve-prompt-manager.component';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';

import { MatIconModule } from '@angular/material/icon';
import { EvePromptManagerDailogComponent } from './eve-prompt-manager/eve-prompt-manager-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';

import { EveLoadingComponent } from './eve-loading/eve-loading.component';
import { EveConfirmDialogComponent } from './eve-confirm-dialog/eve-confirm-dialog.component';
import { EveInteralNumberRangesComponent } from './eve-internal-number-ranges/eve-internal-number-ranges.component';
import { EveNumberModalComponent } from './eve-number-autocomplete/eve-number-modal/eve-number-modal.component';
import { EveInternalRangesComponent } from './eve-number-autocomplete/eve-internal-ranges/eve-internal-ranges.component';
import { EveExternalRangesComponent } from './eve-number-autocomplete/eve-external-ranges/eve-external-ranges.component';
import { EveAreaNumberComponent } from './eve-number-autocomplete/eve-area-number/eve-area-number.component';

import {InputSwitchModule} from 'primeng/inputswitch';
import { EveMultipleSelectionItemsComponent } from './eve-multiple-selection-items/eve-multiple-selection-items.component';
import { EveNumberSelectionListComponent } from './eve-number-selection-list/eve-number-selection-list.component';
import { EveAuthenticationComponent } from './eve-authentication/eve-authentication.component';
import { EveNumberTranslationComponent } from './eve-number-translation/eve-number-translation.component';
import { EveSipTrunkPresentationComponent } from './eve-sip-trunk-presentation/eve-sip-trunk-presentation.component';
import { EveSipTrunkNumberManagerComponent } from './eve-sip-trunk-number-manager/eve-sip-trunk-number-manager.component';
import { EveSipTrunkNumberModalComponent } from './eve-sip-trunk-number-modal/eve-sip-trunk-number-modal.component';
import {PickListModule} from 'primeng/picklist';

@NgModule({
  exports: [
    EveContainerComponent,
    EveInputComponent,
    EveButtonComponent,
    EveSaveButtonComponent,
    EveToggleComponent,
    EveUserCarouselItemComponent,
    EveSliderComponent,
    EveLabelingComponent,
    EveSelectComponent,
    EvePromptComponent,
    EveDatePickerComponent,
    EveTimePickerComponent,
    EveDestinationComponent,
    EveValidationErrorsComponent,
    EveModalComponent,
    EveAvatarModalComponent,
    EveAvatarComponent,
    EveNumberAutocompleteComponent,
    EveCallRecordingComponent,
    EveTimeZoneAutocompleteComponent,
    EveScheduleActionTypesAutocompleteComponent,
    EveInvalidationComponent,
    EveCarousel,
    EveTileMenuComponent,
    EveTileMenuItemComponent,
    EvePromptManagerComponent,
    EvePromptManagerDailogComponent,
    EveLoadingComponent,
    EveConfirmDialogComponent,
    EveInteralNumberRangesComponent,
    EveNumberModalComponent,
    EveInternalRangesComponent,
    EveExternalRangesComponent,
    EveAreaNumberComponent,

    EveMultipleSelectionItemsComponent,
    EveNumberSelectionListComponent,
    EveAuthenticationComponent,
    EveNumberTranslationComponent,
    EveSipTrunkPresentationComponent,
    EveSipTrunkNumberManagerComponent,
    EveSipTrunkNumberModalComponent
  ],
  declarations: [
    EveContainerComponent,
    EveInputComponent,
    EveButtonComponent,
    EveSaveButtonComponent,
    EveToggleComponent,
    EveUserCarouselItemComponent,
    EveSliderComponent,
    EveLabelingComponent,
    EveSelectComponent,
    EvePromptComponent,
    EveDatePickerComponent,
    EveTimePickerComponent,
    EveValidationErrorsComponent,
    EveDestinationComponent,
    EveAvatarModalComponent,
    EveModalComponent,
    EveCropComponent,
    EveAvatarComponent,
    EveAvatarImageComponent,
    EveNumberAutocompleteComponent,
    EveCallRecordingComponent,
    EveTimeZoneAutocompleteComponent,
    EveScheduleActionTypesAutocompleteComponent,
    EveInvalidationComponent,
    EveCarousel,
    EveTileMenuComponent,
    EveTileMenuItemComponent,
    EvePromptManagerComponent,
    EvePromptManagerDailogComponent,
    EveLoadingComponent,
    EveConfirmDialogComponent,
    EveInteralNumberRangesComponent,
    EveNumberModalComponent,
    EveInternalRangesComponent,
    EveExternalRangesComponent,
    EveAreaNumberComponent,

    EveMultipleSelectionItemsComponent,
    EveNumberSelectionListComponent,
    EveAuthenticationComponent,
    EveNumberTranslationComponent,
    EveSipTrunkPresentationComponent,
    EveSipTrunkNumberManagerComponent,
    EveSipTrunkNumberModalComponent
  ],
  imports: [
    MatButtonModule,
    AutoCompleteModule,
    InputTextModule,
    ButtonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSliderModule,
    FormsModule,
    MatInputModule,
    CommonModule,
    NgxMaterialTimepickerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    LyImageCropperModule,
    LySliderModule,
    LyIconModule,

    PipesModule,
    MatListModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatStepperModule,
    InputTextModule,
    InputTextareaModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    InputSwitchModule,
    PickListModule
  ]
  ,
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class EveComponentsModule {
}
