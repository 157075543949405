import { Component, Input, ViewChild } from '@angular/core';
import { faCirclePlus, faClock, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CallQueue, Schedule, ScheduleAction, ScheduleType, BankHoliday, CallQueueInput } from '../../../../../generated/graphql';
import { ControlContainer, FormArray, FormGroup, NgForm } from "@angular/forms";
import { Observable, of } from "rxjs";
import { Positions } from "./eve-select-dates/eve-select-dates.component";
import { ScheduleValidationService } from './eve-select-dates/eve-select-dates-validation.service';


@Component({
  selector: 'eve-call-queue-scheduling',
  templateUrl: './eve-call-queue-scheduling.component.html',
  styleUrls: ['./eve-call-queue-scheduling.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class EveCallQueueSchedulingComponent {

  @Input() scheduling!: FormGroup;
  @Input() organisationNode!: String;

  titleIcon : IconDefinition = faClock;
  addPanelIcon : IconDefinition = faCirclePlus;
  showAddSchedule : boolean = false;


  constructor(private scheduleValidationService: ScheduleValidationService) {  }

  ngOnInit(): void {
    
  }




  get schedules(): FormArray { 
    return this.scheduling.get('schedules') as FormArray
  }

  onAddSchedule() {
    const nowDate = new Date();
    const dateFrom = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
    const dateTo = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
    dateTo.setDate(dateTo.getDate() + 1)



    this.schedules.push(this.scheduleValidationService.generateFromGroup({
      scheduleType: (null as any) as ScheduleType,
      scheduleAction: ScheduleAction.Open,
      friday: false,
      monday: false,
      saturday: false,
      sunday: false,
      thursday: false,
      timeFrom: "PT0S",
      timeTo: "PT23H59M",
      timeZoneString: "",
      tuesday: false,
      wednesday: false,
      dateFrom: dateFrom,
      dateTo: dateTo,
      bankHolidays: [] as BankHoliday[]
    } as Schedule))

  }


  onScheduleRemove(formGroup:FormGroup, index: number){
    this.schedules.removeAt(index);
  }

  swap(index1: number, index2: number) {
    const schedulesFormArray = this.schedules;
    const schedules = [...schedulesFormArray.value];
    if (index2 > 0 && index1 < schedules.length - 1) {
      [schedules[index1], schedules[index2]] = [schedules[index2], schedules[index1]];
      schedulesFormArray.setValue(schedules);
    }
  }


}
