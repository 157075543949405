import { AfterContentInit, AfterViewInit, ChangeDetectorRef, ContentChild, Directive, HostBinding, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { tap } from 'rxjs';

@Directive({
  selector: '.p-inputgroupOld',
})
export class PrimeNgInputGroupStyling implements AfterContentInit {


  @HostBinding('class')
  elementClass = 'custom-theme';


  @ContentChild(NgModel) ngModel: NgModel|null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
    ) {
  

  }
  ngAfterContentInit(): void {

    this.ngModel?.statusChanges?.subscribe((v : string) => {

      var newClass = "";

      if (this.ngModel?.dirty == true || this.ngModel?.touched == true) {
        if (v == "PENDING") newClass = "inputgroup-validation-pending";
        else if (v == "VALID") newClass = "inputgroup-valid";
        else if (v == "INVALID") newClass = "inputgroup-invalid";
      }

      if (this.elementClass != newClass) {
        this.elementClass = newClass;
        this.changeDetectorRef.detectChanges();
      }

    });
  }

}
