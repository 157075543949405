import { Directive } from '@angular/core';
import { Host, Self, Optional } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';

@Directive({
  selector: 'p-autoComplete',
})
export class PrimeNgAutoCompleteShadowdomFix {

  constructor(
    @Host() @Self() @Optional() public hostSel: AutoComplete) {
    // Now you can access specific instance members of host directive

    let app = (<any>hostSel)._app;
    hostSel.show = () => {
      if (hostSel.multiInputEL || hostSel.inputEL) {
        let hasFocus = hostSel.multiple ? hostSel.multiInputEL.nativeElement.ownerDocument.activeElement == hostSel.multiInputEL.nativeElement : (hostSel.inputEL.nativeElement.ownerDocument.activeElement?.shadowRoot?.activeElement ?? hostSel.inputEL.nativeElement.ownerDocument.activeElement) == hostSel.inputEL.nativeElement;

        if (!hostSel.overlayVisible && hasFocus) {
          hostSel.overlayVisible = true;
        }
      }
    }

    hostSel.bindDocumentClickListener = () => {

      setTimeout(() => {
        if (!hostSel.documentClickListener) {
          const documentTarget = hostSel.el ? hostSel.el.nativeElement.ownerDocument : 'document';
          hostSel.documentClickListener = hostSel.renderer.listen(documentTarget, 'click', (event) => {
            if (event.which === 3) {
              return;
            }
            if (!hostSel.inputClick && !hostSel.isDropdownClick(event)) {
              hostSel.hide();
            }
            hostSel.inputClick = false;
            hostSel.cd.markForCheck();
          });
        }

      })
    }
  }
}
