<div novalidate autocomplete="off" [formGroup]="externalRangeForm">
  <div class="p-grid p-fluid" style="margin: 20px;">
    <div class="p-col-2">
      Area Number:
    </div>
    <div class="p-col-10">
      <eve-area-number 
      id="area"
      [(ngModel)]="eveAreaNumber" 
      formControlName="area" 
      name="area"
      (ngModelChange)="onEveAreaNumberChange($event)"></eve-area-number>
    </div>
    <div class="p-col-3">
      Show Standard Numbers
    </div>
    <div class="p-col-2">
      <p-inputSwitch [(ngModel)]="displayStandardNumbers"  formControlName="displayStandardNumber"
        (ngModelChange)="onDisplayStandardNumberChange($event)"></p-inputSwitch>
    </div>
    <div class="p-col-1"></div>
    <div class="p-col-3">
      Show Gold Numbers
    </div>
    <div class="p-col-2">
      <p-inputSwitch [(ngModel)]="displayGoldNumbers" formControlName="displayGoldenNumber"
        (ngModelChange)="onDisplayGoldNumberChange($event)"></p-inputSwitch>
    </div>
     <div class="p-col-12">
      <eve-number-selection-list [filteredInput]="filteredInput" [(ngModel)]="selectedNumbers" id="Orders" name="Orders" formControlName="selectedNumbers"
        (ngModelChange)="onNgModelChange($event)"></eve-number-selection-list>
        <!-- <eve-validation-errors name="Order Numbers" [validationControl]="getFormControl('selectedNumbers')" ></eve-validation-errors> -->
    </div>
  </div>
</div>