import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild
} from '@angular/core';
import {  combineLatest, firstValueFrom, Observable, Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map, startWith, switchMap,  tap } from "rxjs/operators";
import { PromptManager_GetPromptsGQL, PromptManager_DeletePromptGQL, Prompt, PromptManager_CreatePromptGQL, PromptManager_UpdatePromptGQL, PromptInput, Library, PromptManager_PromptNameVaildationGQL } from '../../../generated/graphql';
import { MatPaginator } from '@angular/material/paginator';
import { EveLoadingComponent } from '../eve-loading/eve-loading.component';
import { MatDialog } from '@angular/material/dialog';
import { EveConfirmDialogComponent } from '../eve-confirm-dialog/eve-confirm-dialog.component';
import { AudioService } from 'src/app/services/audio.service';
import { StreamState } from '../../services/audio.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NameUniqueValidator } from '../../helpers/validations/prompt-validation';
import { AppService } from '../../services/app.service';
import { EvePortalCoreUrlPipe } from '../../pipes/eve-portal-core-url/eve-portal-core-url.pipe';
import { AuthService } from '../../services/auth.service';
import { jwtDecode} from 'jwt-decode';

@Component({
  selector: 'eve-prompt-manager',
  templateUrl: './eve-prompt-manager.component.html',
  styleUrls: ['./eve-prompt-manager.component.css']
})
export class EvePromptManagerComponent {
 

  @Input()
  organisationNode!: string;


  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(EveLoadingComponent, { static: true }) loadingComponent !: EveLoadingComponent;

  refresh: Subject<null> = new Subject<null>();
  search: Subject<string> = new Subject<string>();
  prompts!: Observable<Prompt[] | null>;
  totalCount?: number;

  displayedColumns: string[] = ['name', 'description', 'action'];


  editingPrompt: Prompt | null = null;

  fileFormGroup!: FormGroup;
  detailsFormGroup!: FormGroup;

 

  @ViewChild('fileInput') fileInput!: ElementRef;
  state!: StreamState;
  public isPlaying = true;

  audioStatus = {promptId: null, isPlaying: false} as any;

  constructor(
    private authService: AuthService,
    private getPromptsGQL: PromptManager_GetPromptsGQL,
    private createPromptGQL: PromptManager_CreatePromptGQL,
    private updatePromptGQL: PromptManager_UpdatePromptGQL,
    private deletePromptGQL: PromptManager_DeletePromptGQL,

    private fb: FormBuilder,
    private audioService: AudioService,
    private httpClient: HttpClient,
    private promptNameVaildationGQL: PromptManager_PromptNameVaildationGQL,
    private appservice: AppService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private evePortalCoreUrlPipe: EvePortalCoreUrlPipe
  ) {

    // listen to stream state
    this.audioService.getState()
    .subscribe(state => {
      this.state = state;
      console.log("Prompt Manager Audio State: ", JSON.stringify(state));

      this.audioStatus = {...this.audioStatus, isPlaying: this.state.playing}
      
    });

    setTimeout(() => {

      this.prompts = combineLatest(
        this.search.pipe(startWith("") ,tap(() => this.paginator.pageIndex = 0)),
        this.paginator.page.pipe(startWith(null)),
        this.refresh.pipe(startWith(null))
      )
      .pipe(
          switchMap(([search]) => {
            return getPromptsGQL.fetch({
              organisationNode: this.organisationNode,
              name: search,
              skip: this.paginator.pageSize * this.paginator.pageIndex,
              take: this.paginator.pageSize
            }, {fetchPolicy:'network-only'});
          }),
          tap(searchResult => this.totalCount = searchResult?.data.prompts?.totalCount),
          map(r => {
            return JSON.parse(JSON.stringify(r.data.prompts?.items as Prompt[]));
          })
        );

    }, 0);


  }

  generateForm(prompt: Prompt): void {
    this.fileFormGroup = this.fb.group({
      file: this.fb.control(""),
      fileName: this.fb.control(prompt.library.name, []),
      libraryId: this.fb.control(prompt.library.libraryId, [Validators.required]),
      promptId: prompt.promptId ?? 0
    });

    this.fileFormGroup.get("file")?.valueChanges.subscribe(_file => {
      this.processFile(this.fileInput.nativeElement.files[0]);

    })

    this.detailsFormGroup = this.fb.group({
      name: this.fb.control(prompt.name, [Validators.required, Validators.maxLength(50)] ),
      description: this.fb.control(prompt.name, [Validators.required,  Validators.maxLength(50)])
    });

    const nameControl = this.detailsFormGroup.get('name');
    nameControl?.addAsyncValidators(
      NameUniqueValidator(name => this.promptNameVaildationGQL.fetch({ organisationNode: this.organisationNode, promptId: prompt.promptId ?? -1, name: name })
        .pipe(
          map(r => r.data.prompt == null)
        )
      )
    );
  }
   

  processFile(file: File) {
    this.loadingComponent.show = true;
    const reader = new FileReader();

    reader.addEventListener('load', async (event: any) => {

      try {
        const jwt = await firstValueFrom(this.authService.Jwt);
        const a = jwtDecode(jwt) as any;


        if (a.AdminOrganisationLevel != "1" && event.target.result.length > 3000000) {
          this.toastr.error("Prompt Upload Failed : Prompt is too large - limit is 2MB.");

          this.fileFormGroup.get("libraryId")?.setErrors({
            uploadFailed: "Prompt Upload Failed : Prompt is too large - limit is 2MB."
          });

          return;
        }


        if (a.AdminOrganisationLevel == "1" && event.target.result.length > 41943040) {
          this.toastr.error("Prompt Upload Failed : Prompt is too large - limit is 40MB."); 

          this.fileFormGroup.get("libraryId")?.setErrors({
            uploadFailed: "Prompt Upload Failed : Prompt is too large - limit is 40MB."
          }); 
          return;
        }

        var base64 = event.target.result.split(",")[1];

        const form = new FormData()
        form.append('file', file)


      
        const librayId = await firstValueFrom(this.httpClient.post(this.evePortalCoreUrlPipe.transform('/library?organisationNode=' + encodeURIComponent(this.organisationNode)), form));

        this.fileFormGroup.get("fileName")?.setValue(file.name);
        this.fileFormGroup.get("libraryId")?.setValue(librayId);
      }
      catch (ex: any) {
        this.toastr.error("Prompt Upload Failed : " + ex.message ?? ex);
        this.fileFormGroup.get("libraryId")?.setErrors({
          uploadFailed: ex.message ?? ex
        }); 
      }
      finally {
        this.loadingComponent.show = false;
      }
      
    });
    reader.readAsDataURL(file);

  }

  onSearchChange(event: any) {
    this.search.next(event.currentTarget.value);
  }


  onNewPromptClick() {

    debugger;
    this.editingPrompt = {
      promptId: null as any,
      name: "",
      description: "",
      library: {
        libraryId: null as any,
      } as Library,
      organisationNode: this.organisationNode,
    };

    this.generateForm(this.editingPrompt);
  }

 
  async onDeleteClick(prompt: Prompt) {

    let dialogRef = this.dialog.open(EveConfirmDialogComponent, {
      data: {
        icon: "fa fa-exclamation-triangle",
        title: "Delete",
        message: `Are you sure you want to delete prompt \"${prompt.name}\"?`
      },
    });


    const result: boolean = await firstValueFrom(dialogRef.afterClosed());


    if (result != true) return;


    this.loadingComponent.show = true;

    try {
      const response = await firstValueFrom(this.deletePromptGQL.mutate({
        promptId: prompt.promptId
      }));
      this.refresh.next(null);
    }
    catch (ex: any) {
      if (ex.name == "ApolloError") this.toastr.error(`Error deleting prompt - ${ex.message}`, "Error");
      else this.toastr.error(`Error deleting prompt - ${ex}`, "Error");
    }
    finally {
      this.loadingComponent.show = false
    }

      

  }

  onEditClick(prompt: Prompt) {
    this.editingPrompt = JSON.parse(JSON.stringify(prompt));
    if (this.editingPrompt != null)this.generateForm(this.editingPrompt);
  }


  onFileUploadClick() {
    this.fileInput.nativeElement.click();
  }

  @HostListener("dragover", ["$event"]) onDragOver(evt:any) {
    evt.preventDefault()
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files[0] != null) this.processFile(event.dataTransfer?.files[0]);
  }

  async onSaveClick() { 
    debugger;
    this.detailsFormGroup.updateValueAndValidity();
    if (this.detailsFormGroup.invalid || this.detailsFormGroup.pending) {
      this.detailsFormGroup.markAllAsTouched();
      return;
    }

    const prompt: PromptInput = {
      promptId: this.editingPrompt?.promptId,
      name: this.detailsFormGroup.get("name")?.value,
      description: this.detailsFormGroup.get("description")?.value,
      libraryId: this.fileFormGroup.get("libraryId")?.value,
      organisationNode: this.editingPrompt?.organisationNode
    };

     
    

    if (prompt.name == this.editingPrompt?.name) delete prompt.name;
    if (prompt.description == this.editingPrompt?.description) delete prompt.description;
    if (prompt.libraryId == this.editingPrompt?.library?.libraryId) delete prompt.libraryId;

    this.loadingComponent.show = true;

    try {
      if ((prompt.promptId ?? 0) > 0) {

        delete prompt.organisationNode;
        await firstValueFrom(this.updatePromptGQL.mutate({
          prompt: prompt
        }));

      }
      else {

        delete prompt.promptId;
        await firstValueFrom(this.createPromptGQL.mutate({
          prompt: prompt
        }));

      }

      this.editingPrompt = null;
      this.refresh.next(null);
    }
    catch (ex: any) {
      if (ex.name == "ApolloError") this.toastr.error(`Error saving prompt - ${ex.message}`, "Error");
      else this.toastr.error(`Error saving prompt - ${ex}`, "Error");
    }
    finally {
      this.loadingComponent.show = false;
    }

  }

 
  onPlayPrompt = (prompt: Prompt, audio: any) => {
    this.onPlay(this.evePortalCoreUrlPipe.transform('/prompt/' + prompt.promptId), audio);
  }


  onPlayLibrary = (libraryId: number, audio: any) => {
    this.onPlay(this.evePortalCoreUrlPipe.transform('/library/' + libraryId), audio);
  }



  onPlay = async (url: string, audio: HTMLAudioElement) => {

    if ((audio as any).url == url) {
      audio.play();
      return;
    }

    try { 
      const getRequest = await this.httpClient.get(url,
        { responseType: 'blob', observe: 'response' }
      );

      const response = await firstValueFrom(getRequest);

      // Create a URL for the blob
      const objectUrl = URL.createObjectURL(response.body ?? new Blob());
      audio.src = objectUrl;
      audio.play();

      (audio as any).url = url;
    }
    catch (ex: any) {
      this.toastr.error(`Failed to play prompt - ` + ex.message ?? ex);
    }
  }

  onPause = (audio: HTMLAudioElement) => {
    audio.pause();
  }

  onStop = (audio: HTMLAudioElement) => {
    audio.pause();
    audio.currentTime = 0;
  }


  onChangeDetection() {

  }

  onFileFormPlay = ()=>{
    // console.log('fileFormGroup:', JSON.stringify(this.fileFormGroup))
    // console.log('detailsFormGroup', JSON.stringify(this.detailsFormGroup))
    let libraryId = this.fileFormGroup.get('libraryId')?.value;
    let url = this.evePortalCoreUrlPipe.transform('/library/' + libraryId);
    this.audioService.stop();
    this.playStream(url);
  }


  onFileFormPause = () => {
    this.audioService.pause();
  }

  onFileFormStop = () => {
    this.audioStatus = {...this.audioStatus, isPlaying: false}
    this.audioService.stop();
  }

  playStream(url:string) {
    this.audioService.playStream(url).subscribe(events => { // listening for fun here
      console.log('Audio Manager Events:', JSON.stringify(event))
    });
  }

}
