import {Component, ComponentRef, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import { faList, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { CallQueue, CallQueueInput, CallQueueUser, CallQueueUserInput, EveUser } from '../../../../../../../generated/graphql';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Sortable, { Options, SortableEvent } from 'sortablejs';

declare var $: any;


@Component({
  selector: 'priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriorityComponent),
      multi: true
    }
  ]
})

export class PriorityComponent implements ControlValueAccessor {

  

  @Input()
  eveUsers!: EveUser[];

  @Output()
  eveUsersChange: EventEmitter<EveUser[]> = new EventEmitter<EveUser[]>();


  onChange: any = () => { }
  onTouch: any = () => { }


  constructor() {

  }
    
  

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveUsers = value;
  }

 


  setDisabledState?(isDisabled: boolean): void {
  }


  onEnd = (event: SortableEvent) => {
    debugger;
    this.eveUsersChange.emit(this.eveUsers);
    this.onChange(this.eveUsers);
    this.onTouch(this.eveUsers); 
  }

  options: Options = {
    animation: 150,
    // onEnd: this.onEnd
    onEnd: this.onEnd
  };

}
