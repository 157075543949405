





<div class="p-inputgroup" style="margin-bottom: 0px;">

  <p-autoComplete [ngModel]="eveModel"
                  (ngModelChange)="onNgModelChange($event)"
                  [suggestions]="prompts | async"
                  (completeMethod)="filter($event)"
                  field="name"
                  [minLength]="1"
                  [dropdown]="true"
                  [showClear]="eveModel != null"
                  [showEmptyMessage]="true"
                  (onClear)="onNgModelChange(null)"
                  [placeholder]="placeholder"
                  [forceSelection]="true"
                  styleClass="w-full">
  </p-autoComplete>

  <button *ngIf="!isPlaying" type="button" pButton class="p-button-icon-only" (click)="playPrompt();$event.stopPropagation();" [disabled]="eveModel == null">
    <i class="fa fa-play"></i>
  </button>
  <button *ngIf="isPlaying" type="button" pButton class="p-button-icon-only" (click)="pausePrompt();$event.stopPropagation();">
    <i class="fa fa-pause"></i>
  </button>
  <button type="button" pButton class="p-button-icon-only"  (click)="onOpenPromptManager()">
    <i class="fa fa-plus"></i>
  </button>
</div>

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors [name]="placeholder"></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
  <ng-container *ngTemplateOutlet="validationTemplate">
  </ng-container>
</ng-container> 

<audio #audio preload="auto">
</audio>
