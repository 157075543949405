import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {EveAvatarModalComponent} from "./eve-avatar-modal/eve-avatar-modal.component";

@Component({
  selector: 'eve-avatar',
  templateUrl: './eve-avatar.component.html',
  styleUrls: ['./eve-avatar.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveAvatarComponent),
      multi: true
    }
  ]
})

export class EveAvatarComponent implements OnInit {

  private _avatarImageId : number | null = null;

  @Input() set avatarImageId(value: number | null){
    this._avatarImageId = value;
  }

  get avatarImageId(): number | null{
    return this._avatarImageId;
  }

  
  @Input() organisationNode!: string;

  onChange: any = () => { }
  onTouch: any = () => { }

  private mode: string = 'add';
  constructor(
    public dialog: MatDialog
    ) {} 
  ngOnInit(): void {
    
  }

  showModal(): void {

    const dialogRef = this.dialog.open(EveAvatarModalComponent, {
      width: '535px',
      height: '435px',
      data: { mode: this.mode, avatarImageId: this.avatarImageId, organisationNode: this.organisationNode},

    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.cancelled) return;

      this.avatarImageId = result.avatarImageId;
     
      this.mode = result.mode;
      this.onChange(this.avatarImageId);
      this.onTouch(this.avatarImageId);
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.avatarImageId = value;
  }
}
