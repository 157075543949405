import { Directive } from "@angular/core";
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from "@angular/forms";
import { ApolloQueryResult } from "@apollo/client/core/types";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { GetCallQueueIdByQueueNameGQL, GetCallQueueIdByQueueNameQuery } from "../../../../generated/graphql";


@Directive({
  selector: '[callQueueNameUniqueAsyncValidator]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: CallQueueNameUniqueAsyncValidatorDirective, multi: true}]
})
export class CallQueueNameUniqueAsyncValidatorDirective implements AsyncValidator {

  constructor(private getCallQueueIdByQueueNameGQL: GetCallQueueIdByQueueNameGQL) {  }
   
  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    if (control.value == null){
      return of(null);
    }
    return this.getCallQueueIdByQueueNameGQL
      .fetch({
        callQueueName: control.value,
        companyNode: "/"
      })
      .pipe(
        map((response: ApolloQueryResult<GetCallQueueIdByQueueNameQuery>) => {
          return response.data.callQueue == null ? null : {callQueueNameUnique: {value: control.value}};
        })
      );
  }
}
