import { Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';

import {GetNumbersGQL, GetNumbersQuery, NumberFilterInput} from "../../../generated/graphql";

import {map} from "rxjs/operators";
import {BehaviorSubject, lastValueFrom, Observable, ReplaySubject} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AutoComplete} from "primeng/autocomplete";
import { ApolloQueryResult } from '@apollo/client/core/types';
import { MatDialog } from '@angular/material/dialog';
import { EveNumberModalComponent } from './eve-number-modal/eve-number-modal.component';

@Component({
  selector: 'eve-number-autocomplete',
  templateUrl: './eve-number-autocomplete.component.html',
  styleUrls: ['./eve-number-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveNumberAutocompleteComponent),
      multi: true
    }
  ]
})
export class EveNumberAutocompleteComponent implements ControlValueAccessor {

  selectedNumber: any;
  filteredNumbers!: any[]|undefined;

  @Input() placeholder : string = '';
  @Input() name : string = '';

  @Input()eveModel!: any;
  @Output() eveModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  organisationNode: string | null = null;


  @Input()
  numberFilter: NumberFilterInput|null = null;

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  onChange: any = () => { }
  onTouch: any = () => { }


  search:string = "";

  constructor(public getNumbersGQL:GetNumbersGQL,public dialog: MatDialog) { }

  filterDestination(event: any) {
    this.search = event.query;
    const source = this.getNumbersGQL.fetch({
      organisationId: getCompanyId(this.organisationNode),
      inUse:false,
      number: this.search,
      and: this.numberFilter != null ? [this.numberFilter] : null
    }, {
      fetchPolicy: 'network-only'
    }).pipe(
      map((response: ApolloQueryResult<GetNumbersQuery>) => {

        const page = response.data.numbers?.items ?? [];
        // @ts-ignore
        const results = [...page];
        // @ts-ignore
        results.length = response.data.numbers?.totalCount;

        return results;
      })
    ).subscribe(n => {

      this.filteredNumbers = n;

    });

  }

  onLazyLoad($event:any){
    const search = this.search;

    var source = this.getNumbersGQL.fetch({
      organisationId: getCompanyId(this.organisationNode),
      inUse: false,
      number: search,
      skip: $event.first,
      take:$event.last - $event.first,
      and: this.numberFilter != null ? [this.numberFilter] : null
    }, {
      fetchPolicy: 'network-only' 
    }).pipe(
      map((response: ApolloQueryResult<GetNumbersQuery>) =>{
        const page = response.data.numbers?.items ?? [];
        return page;
      })
    ).subscribe(page => {

      if (search != this.search) return;

      page.forEach((item, i) => {
        if (this.filteredNumbers == null) return;
        this.filteredNumbers[$event.first + i] = item;
      });
      // @ts-ignore
      this.filteredNumbers = [...this.filteredNumbers];
    });

  }

  onClear(){
    this.onNgModelChange(null);
  }

  onNgModelChange(prompt: any) {

    this.eveModel = prompt;
    this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }

  onPlusButtonClick = (event: any) => {
    const dialogRef = this.dialog.open(EveNumberModalComponent, {
      width: '700px',
      height: 'auto',
      data: {
        organisationId: getCompanyId(this.organisationNode)
      }
    });
  }

}



export const getCompanyId = (organisationNode: String|null):number => {
  if (organisationNode == null) return -1;
  const companyId = organisationNode.split("/")[4];
  return parseInt(companyId);
}



