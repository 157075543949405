<div class="p-col-12">
    <button type="button" class="evebutton" (click)="clearOrderNumberSelection()">Clear Selection</button>
</div>
<div id="numberSelection">
    <div id="number-order-control" *ngIf="numbers" [style.height.px]="math.ceil(numbers.length / 4) * 25">
        <eve-multiple-selection-items *ngFor="let num of numbers; let i=index" [id]="num">
            {{num.number}}
        </eve-multiple-selection-items>
    </div>
</div>

<div id="numberSelectionHelper"  *ngIf="!numbers">
    <span>Please select an area and at least one option of either show standard or show gold numbers.</span>
</div>
<ng-container *ngIf="validationTemplate == null">
    <eve-validation-errors [name]="placeholder"></eve-validation-errors>
  </ng-container>
  <ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
  </ng-container>