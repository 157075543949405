<div>
  <!-- Trigger/Open The Modal -->

  <button  class="image-wrapper" (click)="showModal()">
  </button>

  <!-- The Modal -->
  <div [style.display]="display" class="modal">

    <!-- Modal content -->
    <div class="modal-content" [style.width]="width" [style.height]="height" >
        <div class="eve-modal-header">
          <h2 class="eve-modal-header-title">
            {{title}}
          </h2>
        </div>
          <ng-content></ng-content>

        <div class="modal-footer">
          <eve-button (click)="handleCancel()">cancel</eve-button>
        </div>
    </div>

  </div>
</div>
