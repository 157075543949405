import { Component, ContentChild, Input, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GetNumberTranslationsGQL } from '../../../generated/graphql';
import { map } from 'rxjs';


@Component({
  selector: 'eve-number-translation',
  templateUrl: './eve-number-translation.component.html',
  styleUrls: ['./eve-number-translation.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveNumberTranslationComponent),
      multi: true
    }
  ]
})
export class EveNumberTranslationComponent implements ControlValueAccessor {
  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  @Input() placeholder!: string;
 
  public eveModel?: number;
  onChange: any = () => { };
  onTouch: any = () => { };

  numberTranslationOptions: any[] = [];
  filteredOptions!: string[];

  constructor(private getNumberTranslationGQL: GetNumberTranslationsGQL) {
    this.getNumberTranslationGQL.fetch().pipe(map((response: any) => {
      console.log('Number translations: ', JSON.stringify(response.data.numberTranslations));
      const numberTranslations = JSON.parse(JSON.stringify(response.data.numberTranslations));
      const filteredNumberTranslations = numberTranslations.filter((x: any) => x.isInbound === true);
      return filteredNumberTranslations;
    })).subscribe({
      next: (translations) => {
        this.numberTranslationOptions = [...translations];
      }
    });
  }
  writeValue(value?: any): void {
    this.eveModel = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  filterOptions($event: any) {
    let filtered: any[] = [];
    let query = $event.query;

    for (let i = 0; i < this.numberTranslationOptions.length; i++) {
      let option = this.numberTranslationOptions[i];
      if (option.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        filtered.push(option);
      }
    }

    this.filteredOptions = [...filtered];
  }

  onClear() {
    this.onNgModelChange(null);
  }

  onNgModelChange(option: any) {
    this.eveModel = option;

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }
}
