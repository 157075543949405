import { QueryList } from "@angular/core";
import { ISelectionItems } from "../interfaces/i-selection-items";
import { Subject, Subscription, fromEvent, map, merge } from 'rxjs';

export class MultiSelection<T extends ISelectionItems> {
  private subscription!: Subscription;
  private lastIndex: number | null = null;
  private firstIndex: number | null = null;

  private activeChanges = new Subject();
  activeChanges$ = this.activeChanges.asObservable();

  constructor(private items: QueryList<T>) {
    this.init();
  }
  init() {
    const clicks$ = this.getListeners();
    this.subscription = merge(...clicks$).subscribe(({ comp, isShift, index }) => {
      // if(isShift === false){
      //     comp.isActive() ? comp.setInactive() : comp.setActive();
      //     this.lastIndex = index;
      // }else{
      //     const start = index;
      //     const end = this.lastIndex;
      //     // const range = Math.min(start, end), Math.max(start, end) + 1;
      //     const inRange = this.items.toArray().slice(Math.min(start, end), Math.max(start, end) + 1);

      //     const isActive = comp.isActive();
      //     inRange.forEach(current => isActive ? current.setInactive() : 
      //                                           current.setActive());
      // }

      if (this.lastIndex === null) {
        comp.isActive() ? comp.setInactive() : comp.setActive();
        this.lastIndex = index;
        this.firstIndex = index;
      } else {

        const start = this.firstIndex ?? 0;
        const end = this.lastIndex ?? 0;

        if (index === start) {
          this.firstIndex = this.lastIndex
        } else if (index === end) {
          this.lastIndex = this.firstIndex
        } if (index < end && index > start) {
          this.firstIndex = index;
        }
        else if (index > end) {
          this.lastIndex = index;
        } else if (index < start) {
          this.firstIndex = index;
        }

        const inRange = this.items.toArray().slice((this.firstIndex ?? 0), (this.lastIndex ?? 0) + 1);

        const isActive = comp.isActive();
        this.items.toArray().forEach(current => {
          current.setInactive();
          current.setStartEndInactive();
        });

        inRange.forEach(current => current.setActive());

        let length = inRange.length;
        if (length > 0) {
          inRange[0].setStartEndActive();
          inRange[length - 1].setStartEndActive();
        }
      }

      this.activeChanges.next(this.getActives());
    });
  }

  getActives() {
    return this.items.filter(item => item.isActive());
  }

  setInactives() {
    return this.items?.toArray().forEach(current => {
      current.setInactive();
      current.setStartEndInactive();
    });
  }

  private getListeners = () => {
    return this.items.map((item, index) => {
      return fromEvent(item.getElement(), 'click').pipe(
        map((event: Event) => event as MouseEvent),
        map((event: MouseEvent) => {
          return {
            index,
            isShift: event.shiftKey,
            comp: item
          };
        })
      );
    });
  }

  destroy() {
    this.subscription.unsubscribe();
  }

  public clear = () =>{
    this.lastIndex = null;
    this.firstIndex = null;
  }
}
