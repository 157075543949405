


<div class="p-inputgroup">
  <input pInputText
         [(ngModel)]="ngModel"
         [min]="minDate"
         [matDatepicker]="$any(picker)"
         (dateInput)="onDateChange($event.value)"
         (click)="picker.open()">

  <ng-container *ngIf="validationTemplate == null">
    <eve-validation-errors></eve-validation-errors>
  </ng-container>
  <ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
  </ng-container>
</div>

<mat-datepicker #picker></mat-datepicker>

