import { Component, OnInit, forwardRef } from '@angular/core';
import { AreaNumbersFilterInput, AvailableDdiModel } from '../../../../generated/graphql';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from '../../../services/app.service';
import { EveExternalRangeValidationService } from './eve-external-range-validation.service';

@Component({
  selector: 'eve-external-ranges',
  templateUrl: './eve-external-ranges.component.html',
  styleUrls: ['./eve-external-ranges.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveExternalRangesComponent),
      multi: true
    }
  ]
})
export class EveExternalRangesComponent implements OnInit, ControlValueAccessor {

  public eveAreaNumber: any = null;
  public displayStandardNumbers: boolean = true;
  public displayGoldNumbers: boolean = true;

  public selectedNumbers: any[] | null = null
  public filteredInput: AreaNumbersFilterInput = { area: this.eveAreaNumber, showGoldNumbers: this.displayGoldNumbers, showStandardNumbers: this.displayStandardNumbers}

  public eveModel = {}
  onChange: any = () => { }
  onTouch: any = () => { }

  public externalRangeForm!:FormGroup

  constructor(
    private appService: AppService,
    private eveInternalRangesValidationService: EveExternalRangeValidationService
    ) {
   
   }

  writeValue(value: any): void {
    this.eveModel = value;
    this.externalRangeForm = this.eveInternalRangesValidationService.generateValidationForm();
  }
  registerOnChange(fn: any): void {
   this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
  }

  onEveAreaNumberChange = (areaNumber: any) => {
   this.filteredInput = {...this.filteredInput, area: areaNumber}
  }

  onDisplayStandardNumberChange = (showStandardNumbers: boolean) => {
    this.filteredInput = {...this.filteredInput, showStandardNumbers: showStandardNumbers}
  }

  onDisplayGoldNumberChange = (showGoldNumbers: boolean) => {
    this.filteredInput = {...this.filteredInput, showGoldNumbers: showGoldNumbers}
  }

  onNgModelChange = (selectedNumbers: any) => {

    let numbers : AvailableDdiModel[] = [];
    selectedNumbers?.forEach((element: any) => {
      numbers.push({
        levelCode: element.levelCode,
        number: element.number,
        supplierCode: element.supplierCode
      })
    });

    this.eveModel = {area: this.eveAreaNumber, orderNumbers: numbers, organisationId: this.appService.organisationId};

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }
  getFormControl = (formControlName: string ): AbstractControl  => {
    return this.externalRangeForm.get(formControlName) as FormControl;
  }
}
