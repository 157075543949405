import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from "rxjs/operators";
import { MatPaginator } from '@angular/material/paginator';
import {
  CallQueue,
  CallQueueInput,
  CallQueueUser,
  CallQueueUserInput,
  DistributionType,
  EveUser,
  GetCallQueueIdByQueueNameGQL,
  GetEveUsersGQL,
  GetEveUsersQuery
} from "../../../../../generated/graphql";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { ApolloQueryResult } from '@apollo/client/core/types';
import { EveCallQueueDetailsValidationService } from './eve-call-queue-details-validation.service';
import { EveCarouselSearchResult } from '../../../../components/eve-carousel/eve-carousel.component';

@Component({
  selector: 'eve-call-queue-details',
  templateUrl: './eve-call-queue-details.component.html',
  styleUrls: ['./eve-call-queue-details.component.css']
})
export class EveCallQueueDetailsComponent implements OnInit, AfterViewInit{

  @Input() callQueue!: FormGroup
  @Input() organisationNode!: String;
  @Input() users!: EveUser[];


  distributionType = DistributionType;

  constructor() {}


  ngOnInit(): void {
    

  }
 
  ngAfterViewInit(): void {
  
  }
  title() {
    const name = this.callQueue.get("name")?.value;
    if (name == null || name == "") return "New Call Queue"
    else return name;
  }



 


}
