import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _organisationNode:string | null = null;

  private _organisationId?: number;

  public get organisationNode(): string | null{
   return this._organisationNode;
  }

  public set organisationNode(value: string | null){
    this._organisationNode = value;
  }

  public get organisationId(){
    if (this._organisationNode == null ) { return this._organisationId ?? -1;}
    const companyId = this._organisationNode.split("/")[4];
    return parseInt(companyId);
  }

  public set organisationId(value: number){
    this._organisationId = value;
  }
constructor() { }

}
