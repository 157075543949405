import {Component, Input} from '@angular/core';
import {
  faChevronDown,
  faChevronUp,
  faPhoneSquare,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";


import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'eve-container',
  templateUrl: './eve-container.component.html',

  animations: [
    trigger('collapseEveContainer', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden', minHeight: 0, paddingTop: 0, paddingBottom: 0, overflow: "hidden" })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
  ,
  styleUrls: ['./eve-container.component.css']
})
export class EveContainerComponent {

  @Input() title: string ='';
  @Input() icon: string = "fa fa-group";


  collapsed = false;

  constructor() {}

  collapsedToggle() {
    this.collapsed = !this.collapsed;
  }

  expandPanel() {
    this.collapsed = false;
  }

  collapsePanel() {
    this.collapsed = true;
  }
}
