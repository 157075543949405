import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EveInternalRangesValidationService } from './eve-internal-ranges-validation.service';
import { debounceTime, filter, first, firstValueFrom } from 'rxjs';
import { INumberRange } from '../../../interfaces/i-number-ranges';
import { GetInternalNumberRangesGQL, GetInternalNumberRangesQuery, UpdateInternalNumberGQL } from '../../../../generated/graphql';
import { ToastrService } from 'ngx-toastr';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'eve-internal-ranges',
  templateUrl: './eve-internal-ranges.component.html',
  styleUrls: ['./eve-internal-ranges.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveInternalRangesComponent),
      multi: true
    }
  ]
})
export class EveInternalRangesComponent implements OnInit {


  @Input() 
  organisationId!: number;

  orginalNumberRanges!: ApolloQueryResult<GetInternalNumberRangesQuery>;
  internalRanges!: FormArray;

  @Output()
  completed: EventEmitter<boolean> = new EventEmitter();

  constructor( 
    private getInternalNumberRangesGQL: GetInternalNumberRangesGQL, 
    private eveInternalRangesValidationService: EveInternalRangesValidationService,
    private updateInternalNumberGQL: UpdateInternalNumberGQL, 
    private toastr: ToastrService,
    private loaderService: LoaderService
  )
  {

  }


  async ngOnInit() {
    try {
      this.orginalNumberRanges = await firstValueFrom(this.getInternalNumberRangesGQL.fetch({ organisationId: this.organisationId }, { fetchPolicy: 'no-cache' }));
      this.internalRanges = this.eveInternalRangesValidationService.generateValidationForm(this.organisationId, this.orginalNumberRanges.data.internalNumberRanges);
    }
    catch (ex: any) {
      if (ex.name == "ApolloError") this.toastr.error(`Error loading internal number ranges - ${ex.message}`, "Error");
      else this.toastr.error(`Error loading internal number ranges - ${ex}`, "Error");
    }
    
  }

  onAddClick() { 
    this.internalRanges.push(this.eveInternalRangesValidationService.buildRange(this.organisationId, {from:"", to:""}));
  }

  removeInternalNumberRange = (index: number) => {
    this.internalRanges.removeAt(index); 
  }


  public async onSaveClick() {
    debugger;


    try {
      this.loaderService.showLoader();

      this.internalRanges.updateValueAndValidity({ onlySelf: false, emitEvent: true });

      let status = this.internalRanges.status;
      if (status == "PENDING") {
        const statusObserable = this.internalRanges.statusChanges.pipe(
          filter(s => s == "VALID" || s == "INVALID")
        );

        status = await firstValueFrom(statusObserable);
      }

      if (status != "VALID") {
        this.internalRanges.markAllAsTouched();
        return;
      }

      const updateInternalNumberInput = this.eveInternalRangesValidationService.generateInternalNumberInput(this.organisationId, this.internalRanges, this.orginalNumberRanges.data.internalNumberRanges);


      try {
        const response = await firstValueFrom(this.updateInternalNumberGQL.mutate({ updateInternalNumber: updateInternalNumberInput }));
        this.completed.emit(true);
      }
      catch (ex: any) {

        if (ex.name == "ApolloError") this.toastr.error(`Error Saving internal number ranges - ${ex.message}`, "Error");
        else this.toastr.error(`Error Saving internal number ranges - ${ex}`, "Error");
      }

    }
    finally {
      this.loaderService.hideLoader();
    }
  }
  
}
