import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'eve-labeling',
  templateUrl: './eve-labeling.html',
  styleUrls: ['./eve-labeling.component.css']
})
export class EveLabelingComponent implements OnInit {

  @Input() text: string = '';
  @Input() left: string = '0px';
  @Input() textAlign: string= 'left';
  @Input() labelColumnWidth: string = '0px';
  @Input() componentColumnWidth: string = '0px';
  @Input() rowHeight!: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
