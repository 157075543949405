import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from "@angular/material/stepper";

// EVE
import { EveComponentsModule } from "../../components/eve-components.module";
import { EveCallQueuePageComponent } from "./eve-call-queue-page.component";
import { EveCallQueueDetailsComponent } from "./components/details/eve-call-queue-details.component";
import { SkillRatingComponent } from './components/agent-priority/components/skill-rating/skill-rating.component';
import { PriorityComponent } from './components/agent-priority/components/priority/priority.component';
import { EveCallQueueAgentSettingsComponent } from "./components/agent-settings/eve-call-queue-agent-settings.component";
import { EveCallQueueConfigurationComponent } from './components/configuration/eve-call-queue-configuration.component';
import { EveCallQueueSchedulingComponent } from './components/scheduling/eve-call-queue-scheduling.component';
import { EveSelectDatesComponent } from './components/scheduling/eve-select-dates/eve-select-dates.component';
import { DirectivesModule } from "../../directives/directives.module";


// THIRD PARTY
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { PipesModule } from '../../pipes/pipes.module';
import { InputTextModule } from 'primeng/inputtext';

import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SkillRatingFilterPipe } from './pipes/skill-rating.pipe/skill-rating-filter.pipe';
import { EveUserSelectedListComponent } from './components/eve-user-selected-list/eve-user-selected-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SortablejsModule } from '../../../libraries/ngx-sortablejs/sortablejs.module';

const routes: Routes = [
  {
    title:"Eve - New Call Queue",
    path: 'Queue/Configuration',
    component: EveCallQueuePageComponent
  },
  {
    title: "Eve - Edit",
    path: 'Queue/Configuration/Index/:guid',
    component: EveCallQueuePageComponent
  },
  {
    title: "Eve - Edit",
    path: ':logInAs/Queue/Configuration/Index/:guid',
    component: EveCallQueuePageComponent
  },

  {
    title:"Eve - New Call Queue",
    path: 'CallQueue/Configuration',
    component: EveCallQueuePageComponent
  },
  {
    title: "Eve - Edit",
    path: 'CallQueue/Configuration/Index/:guid',
    component: EveCallQueuePageComponent
  },
  {
    title: "Eve - Edit",
    path: ':logInAs/CallQueue/Configuration/Index/:guid',
    component: EveCallQueuePageComponent
  }
];


@NgModule({
  exports:[
    RouterModule
  ],
  declarations: [
    EveCallQueuePageComponent,
    EveCallQueueDetailsComponent,
    SkillRatingComponent,
    PriorityComponent,
    EveCallQueueAgentSettingsComponent,
    EveCallQueueConfigurationComponent,
    EveCallQueueSchedulingComponent,
    EveSelectDatesComponent,
    SkillRatingFilterPipe,
    EveUserSelectedListComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    EveComponentsModule,
    MatGridListModule,
    MatCardModule,
    FontAwesomeModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,

    PipesModule,
    
    InputTextModule,
    AutoCompleteModule,
    SortablejsModule
  ]
})
export class EveCallQueuePageModule {
}
