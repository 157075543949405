import { Component, Input } from '@angular/core';

@Component({
  selector: 'eve-save-button',
  template: `
<eve-button>
  Save
</eve-button>
`,
  styleUrls: ['./eve-save-button.component.css']
})

export class EveSaveButtonComponent {

}
