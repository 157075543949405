import { Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef } from '@angular/core';
import { Observable, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { ObservableInput } from 'ngx-observable-input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestinationType, GetDestinationsGQL, GetDestinationsQuery } from '../../../generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core/types';





@Component({
  selector: 'eve-destination',
  templateUrl: './eve-destination.component.html',
  styleUrls: ['./eve-destination.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveDestinationComponent),
      multi: true
    }
  ]
})
export class EveDestinationComponent implements ControlValueAccessor {

  selectedDestination: any;
  filteredDestinations!: any[]|undefined;

  @Input() placeholder : string = '';
  @Input() name : string = '';

  @Input()eveModel!: any;
  @Input() organisationNode: string|null = null;
  @Output() eveModelChange: EventEmitter<any> = new EventEmitter<any>();



  @Input("disabled")
  disabled!: boolean;

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  onChange: any = () => { }
  onTouch: any = () => { }


  search:string = "";

  constructor(private getDestinationsGQL: GetDestinationsGQL) {  }


  filterDestination(event: any) {
    this.search = event.query;

    var source = this.getDestinationsGQL.fetch({
      organisationNode: this.organisationNode,
      search: this.search,
      skip: 0,
      take:10,
    }).pipe(
      map((response: ApolloQueryResult<GetDestinationsQuery>) =>{

        const page = response.data.destinations?.items ?? [];
        // @ts-ignore
        const results = [...page];
        // @ts-ignore
        results.length = response.data.destinations?.totalCount;

        return results;
      })
    );


    lastValueFrom(source).then(d => {
      this.filteredDestinations = d;
    })
  }

  onLazyLoad($event:any){
    const search = this.search;

    var source = this.getDestinationsGQL.fetch({
      organisationNode: this.organisationNode,
      search: search,
      skip: $event.first,
      take:$event.last - $event.first,
    }).pipe(
      map((response: ApolloQueryResult<GetDestinationsQuery>) =>{
        const page = response.data.destinations?.items ?? [];
        return page;
      })
    );

    lastValueFrom(source).then(page => {
      if (search != this.search) return;

      page.forEach((item, i) =>{
        if (this.filteredDestinations == null) return;
        this.filteredDestinations[$event.first + i] = item;
      });
      // @ts-ignore
      this.filteredDestinations = [...this.filteredDestinations];
    })
  }

  onClear(){
    this.onNgModelChange(null);
  }

  onNgModelChange(prompt: any) {

    if (typeof prompt === 'string') {
      if (prompt.length > 0) this.eveModel = { destinationId: null, name: prompt };
      else this.eveModel = null;
    }
    else this.eveModel = prompt;
    
    this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
