

<div class="p-inputgroup" style="margin-bottom: 0px;">
  <p-autoComplete [ngModel]="eveModel" class="eveInput"
                  (ngModelChange)="onNgModelChange($event)"
                  [suggestions]="filteredNumbers"
                  (completeMethod)="filterDestination($event)"
                  field="number"
                  [minLength]="1"
                  [dropdown]="true"
                  [showClear]="eveModel != null"
                  [showEmptyMessage]="true"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="34"
                  [lazy]="true"
                  (onLazyLoad)="onLazyLoad($event)"
                  (onClear)="onClear()"
                  placeholder="Number"
                  [forceSelection]="true"
                  styleClass="w-full">
  </p-autoComplete>

  <button type="button" pButton class="p-button-icon-only" (click)="onPlusButtonClick($event)">
    <i class="fa fa-plus"></i>
  </button>

  </div>

  <ng-container *ngIf="validationTemplate == null">
    <eve-validation-errors [name]="placeholder"></eve-validation-errors>
  </ng-container>

  <ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
  </ng-container>


