import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduleAction, ScheduleType, GetBankHolidaysGQL, GetBankHolidaysQuery, BankHoliday } from '../../../../../../generated/graphql';
import { ControlContainer, FormGroup, NgForm } from "@angular/forms";
import { ApolloQueryResult } from '@apollo/client/core/types';
import { DatePipe } from '@angular/common';
import { map, Observable } from 'rxjs';

export interface Positions {
  current: number,
  next: number
}

@Component({
  selector: 'eve-select-dates',
  templateUrl: './eve-select-dates.component.html',
  styleUrls: ['./eve-select-dates.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [DatePipe]
})
export class EveSelectDatesComponent implements OnInit {
  constructor(
    public getBankHolidaysGQL: GetBankHolidaysGQL,
    private datePipe: DatePipe) {}

  @Input() formGroup!: FormGroup;
  @Input() organisationNode!: String;


  @Output() onMoveUp: EventEmitter<EveSelectDatesComponent> = new EventEmitter<EveSelectDatesComponent>();
  @Output() onMoveDown: EventEmitter<EveSelectDatesComponent> = new EventEmitter<EveSelectDatesComponent>();


  
  @Output() onRemove: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  


  public bankHolidays!: Observable<BankHoliday[]>;
  
  public scheduleType = ScheduleType;
  scheduledAction = ScheduleAction;
  
  currentDate: string | null = null;
  ngOnInit(): void {

    this.currentDate = this.datePipe.transform(new Date(), 'yyy-MM-dd');


    this.bankHolidays = this.getBankHolidaysGQL.fetch({
      date: this.currentDate,
      top: 10,
      regionId: 1
    }).pipe(
      map(r => r.data.bankHolidays?.nodes?.map(b => Object.assign({}, b, {date: new Date(b.date)}) ?? []) as BankHoliday[])
    );
    

  }

  onClickRemove(){
    this.onRemove.emit(this.formGroup);
  }

  onMovePanelUp() {
    this.onMoveUp.emit();
  }

  onMovePanelDown() {
    this.onMoveDown.emit();
  }


  bankHolidayComparer(a: any, b: any) {
    return a.bankHolidayId === b.bankHolidayId;
  }

}

