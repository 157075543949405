import { Component, HostBinding, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { ISelectionItems } from '../../interfaces/i-selection-items';


@Component({
  selector: 'eve-multiple-selection-items',
  templateUrl: './eve-multiple-selection-items.component.html',
  styleUrls: ['./eve-multiple-selection-items.component.css']
})
export class EveMultipleSelectionItemsComponent implements OnInit, ISelectionItems {

  @Input() id!: any;
  @HostBinding('class.active') active = false;
  
  @HostBinding('class.startEndActive') startEndActive = false;
  
  constructor(private host: ElementRef) { }
  
  setActive() { this.active = true; }

  setInactive() { this.active = false; }

  isActive() { return this.active }

  getElement() { return this.host.nativeElement }


  setStartEndActive = () => {
    this.startEndActive = true;
  };
  
  setStartEndInactive = () => {
    this.startEndActive = false;
  };

  ngOnInit() {
  }

}
