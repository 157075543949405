import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';


import { GetReservedNumbersGQL, GetExtensionDailingGQL, InternalNumberInput } from '../../../../generated/graphql';
import { AppService } from '../../../services/app.service';
import { Observable, firstValueFrom, map, of } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { BasicValidations } from '../../../helpers/validations/basic-validation';


@Injectable({ providedIn: 'root' })
export class EveInternalRangesValidationService {


  constructor(private fb: FormBuilder,
    private getReservedNumbersGQL: GetReservedNumbersGQL,
    private getExtensionDailingGQL: GetExtensionDailingGQL) {
  }


  public generateValidationForm(organisationId: number, internalNumberRanges: InternalNumberRange[]): FormArray {


    const formGroup = this.fb.array(
      internalNumberRanges.map(r => this.buildRange(organisationId, r))
    )

    debugger;

   // formGroup.addValidators(InternalNumberOverLappingRangesValidator());
    return formGroup;
  }



  buildRange(organisationId: number, internalNumberRange: InternalNumberRange): FormGroup {
    let formgroup = this.fb.group({
      from: [
        internalNumberRange.from,
        [
          Validators.required,
          BasicValidations.number()
        ],
        [
          InternalNumberLengthValidator(() => this.getExtensionDailingGQL.fetch({ organisationId: organisationId }).pipe(map(r => r.data.company?.extensionDailing ?? -1)))
        ]
      ],


      to: [
        internalNumberRange.to,
        [
          Validators.required,
          BasicValidations.number(),
          GreaterThanOrEqualValidatorFunc(f => f.parent?.get('to')?.value, f => f.parent?.get('from')?.value)
        ],
        [
          InternalNumberLengthValidator(() => this.getExtensionDailingGQL.fetch({ organisationId: organisationId }).pipe(map(r=> r.data.company?.extensionDailing ?? -1)))
        //  InternalNumberOverLappingRangesValidator(),
            //CheckResevedNumberValidator(this.getReservedNumbersGQL),
          // CheckInternalNumberLength(this.getExtensionDailingGQL, this.appservice) 
        ] 
      ]
    });


    // formgroup.addValidators(InternalNumberOverLappingRangesValidator());
    formgroup.addAsyncValidators(CheckResevedNumberValidator(this.getReservedNumbersGQL));

    let fromControl = formgroup.get('from');
    let toControl = formgroup.get('to');


    fromControl?.valueChanges.subscribe(() => {
      if (formgroup.value['from'] !== null) {
        toControl?.markAsDirty();
        toControl?.updateValueAndValidity();
      }
    });



   // fromControl?.addValidators(CheckInternalNumberLength(0));
   // toControl?.addValidators(CheckInternalNumberLength(0));
    return formgroup;
  }

  generateInternalNumberInput(organisationId: number, formArray: FormArray, orginalNumberRanges: InternalNumberRange[]): InternalNumberInput {

    const numbers = formArray.controls.flatMap(c => {
      const from = c.get("from") as FormControl<string>;
      const to = c.get("to") as FormControl<string>;

      const fromNumber = parseInt(from.value);
      const toNumber = parseInt(to.value);

      return Array.from({ length: (toNumber - fromNumber) + 1 }, (v, k) => "" + (fromNumber + k));

    });


    const organinalNumbers = orginalNumberRanges.flatMap(r => {

      const fromNumber = parseInt(r.from);
      const toNumber = parseInt(r.to);

      return Array.from({ length: (toNumber - fromNumber) + 1 }, (v, k) => "" + (fromNumber + k));

    });

    debugger;



    const internalNumberInput: InternalNumberInput = {
      organisationId: organisationId,
      createdInternalNumbers: numbers.filter(n => organinalNumbers.indexOf(n) == -1),
      deletedInternalNumbers: organinalNumbers.filter(n => numbers.indexOf(n) == -1),
    }




    return internalNumberInput;
  }
}

function GreaterThanOrEqualValidatorFunc(aFunc: (formControl: AbstractControl) => string | null, bFunc: (formControl: AbstractControl) => string | null) {

  return (formControl: AbstractControl) => {


    let a = aFunc(formControl);
    let b = bFunc(formControl);

    if (a == null || b == null || a === '' || b === '') return null;

    let aValue = parseInt(a);
    let bValue = parseInt(b);

    if (isNaN(aValue) || isNaN(bValue) || aValue >= bValue) {
      return null;
    }
    formControl.markAsDirty();
    return {
      greaterThanOrEqual: {
        value: bValue,
        greaterThanOrEqual: bValue
      }
    };
  }
}





interface InternalNumberRange {
  from: string,
  to: string
}


function InternalNumberLengthValidator(obserableFn: () => Observable<number>): AsyncValidatorFn {

  return (c: AbstractControl<String>): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return obserableFn().pipe(map(length => {
      debugger;
      return c.value.length == length ? null : {
          internalNumberLength: { value: length }
      };
    }));

  }
}


function CheckResevedNumberValidator(getReservedNumbersGQL: GetReservedNumbersGQL): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    
    const formGroup = control as FormGroup;

    const fromControl = formGroup.get('from');
    const toControl = formGroup.get('to');

    if (fromControl == null || toControl == null) return of(null);

    const from = fromControl.value;
    const to = toControl.value;

    const fromNumber = parseInt(from);
    const toNumber = parseInt(to);

    
    if (isNaN(fromNumber) || isNaN(toNumber)) return of(null);

    const numbers = Array.from({ length: (toNumber - fromNumber) + 1 }, (v, k) => "" + (fromNumber + k));


    return getReservedNumbersGQL.fetch().pipe(map(response => {
      let hasUsedReservedList = numbers.some(n => response.data.reservedNumbers.includes(n)); 
      return hasUsedReservedList ? { reservedNumber: { value: control.value } } : null;
    }));

  };
}

function InternalNumberOverLappingRangesValidatorFormGroup(): AsyncValidatorFn {

  return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {



    debugger
    let formGroup = c as FormGroup;
    let formArray = c.parent as FormArray;

   

    const numberGroups = formArray.controls.map(c => {
      const from = c.get("from") as FormControl<string>;
      const to = c.get("to") as FormControl<string>;

      const fromNumber = parseInt(from.value);
      const toNumber = parseInt(to.value);

      return {
        control: c,
        numbers: Array.from({ length: (toNumber - fromNumber) + 1 }, (v, k) => "" + (fromNumber + k))
      };

    });



    numberGroups.forEach(a => {
      if (numberGroups.some(b => b.control != a.control && a.numbers.some(number => b.numbers.includes(number)))) {
        a.control.setErrors({ overlappingranges: true });
        a.control.markAsDirty();
      }
      else if (a.control.errors != null && a.control.hasError("overlappingranges")) {
        delete a.control.errors['overlappingranges'];
        a.control.updateValueAndValidity();
      }
    });



    return of(null);

  }
}


function InternalNumberOverLappingRangesValidatorFormArray(): AsyncValidatorFn {

  return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {



    debugger
    let formArray = c as FormArray;

    const numberGroups = formArray.controls.map(c => {
      const from = c.get("from") as FormControl<string>;
      const to = c.get("to") as FormControl<string>;

      const fromNumber = parseInt(from.value);
      const toNumber = parseInt(to.value);

      return {
        control: c,
        numbers: Array.from({ length: (toNumber - fromNumber) + 1 }, (v, k) => "" + (fromNumber + k))
      };

    });

   

    numberGroups.forEach(a => {
      if (numberGroups.some(b => b.control != a.control && a.numbers.some(number => b.numbers.includes(number)))) {
        a.control.setErrors({ overlappingranges: true });
        a.control.markAsDirty();
      }
      else if (a.control.errors != null && a.control.hasError("overlappingranges")) {
        delete a.control.errors['overlappingranges'];
        a.control.updateValueAndValidity();
      }
    });



    return of(null);

  }
}
