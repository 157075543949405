
import { Pipe, PipeTransform } from '@angular/core';
import { CallQueueUser } from 'src/generated/graphql';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'sortBy', pure:false})
export class SortByPipe implements PipeTransform {

  transform(value: any[], property:string='', order:string = 'asc'): any[] {

    const sortFunc = (userA:any,userB:any) =>
      (userA[property] ?? '') - (userB[property] ?? '');
    return value.sort(sortFunc);
  }
}