import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationOptionsType } from 'src/generated/graphql';

@Pipe({
  name: 'authentication'
})
export class AuthenticationPipe implements PipeTransform {

  transform(value: AuthenticationOptionsType): string {
    if(value === AuthenticationOptionsType.IpAddress) return  'IP Address';
    if(value === AuthenticationOptionsType.Username) return 'Username';
    return '';
  }

}
