import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ValidationDirectivesModule} from "./validation/validation.directives.module";
import { PrimeNgAutoCompleteShadowdomFix } from './primeng/autocomplete/shadowdom-fix/primeng.autocomplete.shadowdom-fix.directive';
import { PrimeNgCalendarShadowdomFix } from './primeng/calendar/shadowdom-fix/primeng.calendar.shadowdom-fix.directive';
import { PrimeNgInputGroupStyling } from './primeng/p-input-group/error-styling/primeng.calendar.shadowdom-fix.directive';
import { ImageDirective } from './img/img.directive';
import { DownloadFileDirective } from './download-file/download-file.directive';



@NgModule({
  imports:[
    ValidationDirectivesModule
  ],
  declarations: [
    PrimeNgInputGroupStyling,
    PrimeNgAutoCompleteShadowdomFix,
    PrimeNgCalendarShadowdomFix,
    ImageDirective,
    DownloadFileDirective
  ],
  exports: [
    PrimeNgInputGroupStyling,
    PrimeNgAutoCompleteShadowdomFix,
    PrimeNgCalendarShadowdomFix,
    ValidationDirectivesModule,
    ImageDirective,
    DownloadFileDirective
  ]
})
export class DirectivesModule { }
