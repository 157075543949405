import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {ObservableInput} from "ngx-observable-input";
import {Observable} from "rxjs";

@Component({
  selector: 'eve-avatar-image',
  templateUrl: './eve-avatar-image.component.html',
  styleUrls: ['./eve-avatar-image.component.css'],
})
export class EveAvatarImageComponent implements  OnInit{

  // @ObservableInput()
  // @Input("avatarImageId")
  // avatarImageId$!: Observable<number|null>;
  @Input()
  avatarImageId!: number | null;

  @Input()
  avatarCaption!: string;

  defaultAvatarImageUrl: string = "assets/images/DefaultHuntGroup.png";
  
  avatarImageSrc: string = ''

  constructor() { 
    this.avatarImageId == null ? "assets/images/DefaultHuntGroup.png" : `library/${this.avatarImageId}` 
  }

  ngOnInit(): void {
  }


}
