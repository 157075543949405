<p-autoComplete name="number translation"
                [ngModel]="eveModel"
                (ngModelChange)="onNgModelChange($event)"
                (onClear)="onClear()"
                [showEmptyMessage]="true"
                [suggestions]="filteredOptions"
                (completeMethod)="filterOptions($event)"
                [dropdown]="true"
                [showClear]="eveModel != null"
                field="name"
                [placeholder]="placeholder"
                [forceSelection]="true">
</p-autoComplete>

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors [name]="placeholder"></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
  <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>
