import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


import { GetInternalNumberRangesGQL, GetInternalNumberRangesQuery, InternalNumberInput, UpdateInternalNumberGQL, CreateOrderNumbersGQL, CreateOrderNumbersInput } from '../../../../generated/graphql';
import {AppService} from '../../../services/app.service';
import {ApolloQueryResult} from '@apollo/client/core/types';
import { filter, firstValueFrom, startWith, map} from 'rxjs';
import { INumberRange } from '../../../interfaces/i-number-ranges';
import { EveExternalRangeValidationService } from '../eve-external-ranges/eve-external-range-validation.service';
import { EveInternalRangesValidationService } from '../eve-internal-ranges/eve-internal-ranges-validation.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EveInternalRangesComponent } from '../eve-internal-ranges/eve-internal-ranges.component';
import { LoaderService } from '../../../services/loader.service';

@Component({selector: 'eve-number-modal', templateUrl: './eve-number-modal.component.html', styleUrls: ['./eve-number-modal.component.css']})
export class EveNumberModalComponent implements OnInit {

    public action : string | null = null;
    public OrderNumbersModel = [];


  public organisationId!: number;

  @ViewChild(EveInternalRangesComponent) 
  internalRangesComponent!: EveInternalRangesComponent;

    constructor(
        public dialogRef : MatDialogRef < EveNumberModalComponent >,  
        private createOrderNumberGQL: CreateOrderNumbersGQL,
        private externalRangeValidationService: EveExternalRangeValidationService,
      private toastr: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: { organisationId: number },
      private loaderService: LoaderService
    )
    {
      this.organisationId = this.data.organisationId;
    }


  ngOnInit() {
  }

    onCancelClick = (event : any) => {
        this.dialogRef.close();
    }

  



    onPrevClick = () => {
        this.action = null;
    }
    
    

  onPlaceOrder = async () => {
    try {
      this.loaderService.showLoader();
      debugger;
      let formGroup = this.externalRangeValidationService.externalRangeFormGroup;
      let areaControl = formGroup.get('area');
      areaControl?.markAsDirty();
      areaControl?.updateValueAndValidity();

      let selectedNumbersControl = formGroup.get('selectedNumbers');
      selectedNumbersControl?.markAsDirty();
      selectedNumbersControl?.updateValueAndValidity();

      if (selectedNumbersControl?.invalid === false && areaControl?.invalid === false) {
        let createOrderNumbersInput: CreateOrderNumbersInput = JSON.parse(JSON.stringify(this.OrderNumbersModel));

        createOrderNumbersInput.organisationId = this.organisationId;

        createOrderNumbersInput.orderNumbers = JSON.parse(JSON.stringify(createOrderNumbersInput.orderNumbers));

        try {
          await firstValueFrom(this.createOrderNumberGQL.mutate({ createOrderNumbers: createOrderNumbersInput }));
          this.dialogRef.close();
        }
        catch (ex: any) {
          if (ex.name == "ApolloError") this.toastr.error(`Error placing order - ${ex.message}`, "Error");
          else this.toastr.error(`Error placing order - ${ex}`, "Error");
        }

      }
    }
    finally {
      this.loaderService.hideLoader();
    }
  }
   

  onCompeleted() {
    this.dialogRef.close();
  }
}
