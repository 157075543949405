<ng-container [formGroup]="formGroup">

  <eve-tile-menu colour="gradient" *ngIf="formGroup.get('scheduleType')?.value == null" formControlName="scheduleType" class="scheduleTypeSelection">
    <eve-tile-menu-item icon="fa fa-calendar-o fa-5x" name="Date" [value]="scheduleType.Date"></eve-tile-menu-item>
    <eve-tile-menu-item icon="fa fa-clock-o fa-5x" name="Weekly" [value]="scheduleType.Recurring"></eve-tile-menu-item>
    <eve-tile-menu-item icon="fa fa-plane fa-5x" name="Bank Holidays" [value]="scheduleType.BankHoliday"></eve-tile-menu-item>
  </eve-tile-menu>

  <ng-container *ngIf="formGroup.get('scheduleType')?.value != null">



    <eve-tile-menu size="small" colour="white" style="margin-bottom: 20px;" *ngIf="formGroup.get('scheduleType')?.value === scheduleType.Recurring" formControlName="recurringSchedule" multiselect="true" class="weeklySchedule">
      <eve-tile-menu-item name="Monday" value="Monday"></eve-tile-menu-item>
      <eve-tile-menu-item name="Tuesday" value="Tuesday"></eve-tile-menu-item>
      <eve-tile-menu-item name="Wednesday" value="Wednesday"></eve-tile-menu-item>
      <eve-tile-menu-item name="Thursday" value="Thursday"></eve-tile-menu-item>
      <eve-tile-menu-item name="Friday" value="Friday"></eve-tile-menu-item>
      <eve-tile-menu-item name="Saturday" value="Saturday"></eve-tile-menu-item> 
      <eve-tile-menu-item name="Sunday" value="Sunday"></eve-tile-menu-item>
    </eve-tile-menu>

    <eve-tile-menu size="medium" colour="white" style="margin-bottom: 20px;" *ngIf="formGroup.get('scheduleType')?.value === scheduleType.BankHoliday" formControlName="bankHolidays" multiselect="true" class="bankHolidaySchedule" [comparer]="bankHolidayComparer">
      <eve-tile-menu-item *ngFor="let bankHoliday of bankHolidays | async" [name]="bankHoliday.title + ' ' + bankHoliday.date.getFullYear()" [value]="bankHoliday"></eve-tile-menu-item>
    </eve-tile-menu>


    <div class="container">
      <div class="row input-group-row " *ngIf="formGroup.get('scheduleType')?.value == scheduleType.Date">
        <div class="label-text col-3">
          Date From
        </div>
        <div class="col-3">
          <eve-date-picker formControlName="dateFrom">
          </eve-date-picker>
        </div>

        <div class="label-text col-2">
          Date To
        </div>
        <div class="col-3">
          <eve-date-picker formControlName="dateTo">
          </eve-date-picker>
        </div>
      </div>

      <div class="row input-group-row ">
        <div class="label-text col-3">
          Time From
        </div>
        <div class="col-3">
          <eve-time-picker formControlName="timeFrom">
          </eve-time-picker>
        </div>

        <div class="label-text col-2">
          Time To
        </div>
        <div class="col-3">
          <eve-time-picker formControlName="timeTo">
          </eve-time-picker>
        </div>
      </div>

      <div class="row input-group-row">
        <div class="label-text col-3">
          Action Type
        </div>
        <div class="col-5">
          <eve-schedule-action-types-autocomplete formControlName="scheduleAction" placeholder="Action Type"></eve-schedule-action-types-autocomplete>
        </div>
      </div>

      <div class="row input-group-row" *ngIf="formGroup.get('scheduleAction')?.value ==scheduledAction.Forward">
        <div class="label-text col-3">
          Forward Number
        </div>
        <div class="col-5">
          <eve-destination formControlName="forwardDestination" placeholder="Forward Number" [organisationNode]="organisationNode">
          </eve-destination>
        </div>
      </div>

     

      <div class="row input-group-row">
        <div class="label-text col-3">
          Prompt
        </div>
        <div class="col-5">
          <eve-prompt formControlName="prompt" [organisationNode]="organisationNode"></eve-prompt>
        </div>
      </div>
    </div>

   
     


      <div class="fixed-move-button">
        <button type="button" class="evebutton move-up" (click)="onMovePanelUp();$event.stopPropagation();">
          <i class="fa fa-arrow-up"></i>
        </button>
        <button type="button" class="evebutton move-down" (click)="onMovePanelDown();$event.stopPropagation();">
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>
 
  </ng-container>

  <div class="delete-schedule-container" (click)="onClickRemove()">
    <div class="delete-schedule-button">
      <i class="delete-schedule-icon fa fa-close"></i>
    </div>
  </div>

</ng-container>
