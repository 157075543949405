<eve-container title="Scheduling" icon="fa fa-clock-o" [formGroup]="scheduling">
  
  <div class="container">
    <div class="row" style="padding-bottom: 40px;">
      <div class="col-sm-2" style="color: rgb(119, 119, 119);">
        Timezone
      </div>
      <div class="col-sm-7">
        <eve-timezone-autocomplete name="Timezone" formControlName="timezone"></eve-timezone-autocomplete>
      </div>
    </div>
  </div>


  <div class="schedules-sequence">
    <ng-container>
      <ng-container *ngFor="let schedule of schedules.controls;let i =index;">

        <eve-select-dates [formGroup]="schedule" [organisationNode]="organisationNode" (onMoveUp)="swap(i - 1, i)" (onMoveDown)="swap(i, i + 1)" (onRemove)="onScheduleRemove($event, i)">
        </eve-select-dates>

      </ng-container>
    </ng-container>
  </div>


  <div id="add-panel-button" (click)="onAddSchedule()">

    <button mat-fab color="primary" class="icon-button-large">
      <i class="fa fa-plus"></i>
    </button>
    <div>Add new schedule</div>
  </div>
  <br />
</eve-container>
