import {Directive, Input} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";


@Directive({
  selector: '[testValidatorDirective]',
  providers: [{provide: NG_VALIDATORS, useExisting: TestValidatorDirective, multi: true}]
})
export class TestValidatorDirective implements Validator {
  @Input('testValidatorDirective') forbiddenName = '';

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
    /*return this.forbiddenName ? forbiddenNameValidator(new RegExp(this.forbiddenName, 'i'))(control)
      : null;*/
  }
}
