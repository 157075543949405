
<div class="p-inputgroup">
  <input aria-label="default time"
         pInputText [ngxTimepicker]="timePicker"
         [(ngModel)]="time"
         [format]="24"
         [disabled]="disabled"
         readonly>

  <ng-container *ngIf="validationTemplate == null">
    <eve-validation-errors></eve-validation-errors>
  </ng-container>
  <ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
  </ng-container>
</div>

<ngx-material-timepicker #timePicker
                         (timeSet)="setTime($event)"
                         [theme]="eveTimePickerTheme">
</ngx-material-timepicker>

