import {Component, ContentChild, EventEmitter, forwardRef, HostListener, Input, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import { CallRecording } from '../../../generated/graphql';
import { CallRecordingPipe } from '../../pipes/call-recording/call-recording.pipe';
import { first } from 'rxjs';


const callRecordingPipe: CallRecordingPipe = new CallRecordingPipe();

@Component({
  selector: 'eve-call-recording',
  templateUrl: './eve-call-recording.component.html',
  styleUrls: ['./eve-call-recording.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveCallRecordingComponent),
      multi: true
    }
  ]
})
export class EveCallRecordingComponent  implements ControlValueAccessor {

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  _eveModel!: CallRecording
  // @Input() eveModel!: CallRecording;
  get eveModel(): CallRecording{
    return this._eveModel;
  }
  @Input()  set eveModel(value: CallRecording){
    this._eveModel = value;
    this.callRecording = this.callRecordingOptions.filter(x => x.value.toLowerCase() === this._eveModel?.toLowerCase())[0];
  }
  @Output() eveModelChange: EventEmitter<CallRecording> = new EventEmitter<CallRecording>();

  @Input()
  placeholder: string = "Call Recording";


  onChange: any = () => { }
  onTouch: any = () => { }


  callRecordingOptions: any[] = [
    { label: callRecordingPipe.transform(CallRecording.None), value: CallRecording.None },
    { label: callRecordingPipe.transform(CallRecording.Days30), value: CallRecording.Days30 },
    { label: '6 Months', value: 'MONTHS6' },
    { label: '12 Months', value: 'MONTHS12' },
    { label: '7 Years', value: 'YEARS7' }];

  filteredCallRecordingOptions!: string[];

  callRecording: any = null;

  

  constructor() {
    
   }

  filterCallRecording($event: any) {
    let filtered : any[] = [];
    let query = $event.query;
   
    for(let i = 0; i < this.callRecordingOptions.length; i++) {
      let option = this.callRecordingOptions[i];
      if (option.label.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        filtered.push(option);
      }
    }

    this.filteredCallRecordingOptions = [...filtered];

  }

  onClear(){
    this.onNgModelChange(null);
  }
  
  onNgModelChange(option: any) {
    this.callRecording = option;
    this.eveModel = option?.value;
    this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }
}
