import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {GetCallQueueIdByQueueNameGQL, GetCallQueueIdByQueueNameQuery} from '../../../../../generated/graphql';
import {ApolloQueryResult} from "@apollo/client/core";

export function callQueueNameUniqueValidator(getCallQueueIdByQueueNameGQL: GetCallQueueIdByQueueNameGQL, callQueueId: number | null, companyNode: String | null): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {

    if (control.value == null){
        return of(null);
    }  
     
    return getCallQueueIdByQueueNameGQL 
    .fetch({
      callQueueName: control.value,
      companyNode: companyNode
    })
    .pipe(
      map((response: ApolloQueryResult<GetCallQueueIdByQueueNameQuery>) => {
        let callQueue = response.data.callQueue;
        return callQueue == null || callQueueId === null || callQueue.callQueueId === callQueueId ? null : { nameUnique: {value: control.value}};
      })
    );
  };
} 

