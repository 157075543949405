import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, startWith } from 'rxjs';
import { CallQueue, Destination, Prompt } from 'src/generated/graphql';
import { BasicValidations } from '../../../../helpers/validations/basic-validation';
import { DestinationValidator, RequiredIfValidatorFunc } from '../scheduling/eve-select-dates/eve-select-dates-validation.service';





@Injectable({ providedIn: 'root' })
export class EveCallQueueConfigurationValidationService {

  constructor(private fb: FormBuilder) { }

  public generateValidationForm(callQueue: CallQueue): FormGroup<EveCallQueueConfigurationFormGroup> {
    const formGroup = this.fb.group<EveCallQueueConfigurationFormGroup>({
      placedInQueueMessagePrompt: this.fb.control(
        callQueue.placedInQueueMessagePrompt,
        callQueue.isLight == false ? [Validators.required] : null
      ),

      progressMessagePrompt: this.fb.control(
        callQueue.progressMessagePrompt,
        [Validators.required]
      ),

      progressRepeat: this.fb.control(
        callQueue.progressRepeat,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(60)]
      ),

      queueingMessagePrompt: this.fb.control(
        callQueue.queueingMessagePrompt,
        callQueue.isLight == false ? [Validators.required] : null
      ),

      playRingCallingAgent: this.fb.control(
        callQueue.playRingCallingAgent,
        [Validators.required]
      ),

      playQueuePosition: this.fb.control(
        callQueue.playQueuePosition,
        [DestinationValidator]
      ),

      playEstimatedTime: this.fb.control(
        callQueue.playEstimatedTime,
        [Validators.required]
      ),

      alternateQueueInfo: this.fb.control(
        callQueue.alternateQueueInfo,
        [Validators.required]
      ),

      statisticsPeriod: this.fb.control(
        callQueue.statisticsPeriod,
        [Validators.required, BasicValidations.number(), Validators.min(1), Validators.max(24)]
      ),

      _24hrStatisticsFixed: this.fb.control(
        callQueue._24hrStatisticsFixed,
        [Validators.required]
      ),

      _24hrStatisticsResetTime: this.fb.control(
        callQueue._24hrStatisticsResetTime,
        [Validators.required]
      ),

      callbackRequeue: this.fb.control(
        callQueue.callbackRequeue,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(120)]
      ),

      callbackAttempts: this.fb.control(
        callQueue.callbackAttempts,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(10)]
      ),

      callbackValidity: this.fb.control(
        callQueue.callbackValidity,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(240)]
      ),

      callbackMessagePrompt: this.fb.control(
        callQueue.callbackMessagePrompt,
        callQueue.isLight == false ? [Validators.required] : null
      )

     
    });

    const _24hrStatisticsFixedControl = formGroup.get("_24hrStatisticsFixed");
    _24hrStatisticsFixedControl?.valueChanges.pipe(startWith(_24hrStatisticsFixedControl.value)).subscribe(fixed => {
      const _24hrStatisticsResetTimeControl = formGroup.get("_24hrStatisticsResetTime");
      if (fixed == true) _24hrStatisticsResetTimeControl?.enable();
      else _24hrStatisticsResetTimeControl?.disable();

    });

    return formGroup;
  }

}

export interface EveCallQueueConfigurationFormGroup {

  placedInQueueMessagePrompt: FormControl<Prompt | null | undefined>;
  progressMessagePrompt: FormControl<Prompt | null | undefined>;
  progressRepeat: FormControl<number | null >;
  queueingMessagePrompt: FormControl<Prompt | null | undefined>;
  playRingCallingAgent: FormControl<boolean | null>;
  playQueuePosition: FormControl<boolean | null>;
  playEstimatedTime: FormControl<boolean | null>;
  alternateQueueInfo: FormControl<boolean | null>;
  statisticsPeriod: FormControl<number | null>;
  _24hrStatisticsFixed: FormControl<boolean | null>;
  _24hrStatisticsResetTime: FormControl<string | null>;
  callbackRequeue: FormControl<number | null>;
  callbackAttempts: FormControl<number | null>;
  callbackValidity: FormControl<number | null>;
  callbackMessagePrompt: FormControl<Prompt | null | undefined>;
}
