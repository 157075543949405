<eve-container title="Set Order" icon="fa fa-list">

  <div [sortablejs]="eveUsers" [sortablejsOptions]="options" style="display: flex; flex-wrap: wrap;">

    <eve-user-carousel-item [eveUser]="eveUser" *ngFor="let eveUser of eveUsers; index as i">
      <div class="call-queue-user-priority">
        <span class="position-number">{{i + 1}}</span>
      </div>
    </eve-user-carousel-item>
  </div>
</eve-container>
