import { NgModule } from '@angular/core';
import {EveCallQueuePageModule} from "./call-queue/eve-call-queue-page.module";
import { EveSlipTrunkPageModule } from './sip-trunk/eve-sip-trunk-page.module';

@NgModule({
  exports:[
  ],
  declarations: [],
  imports: [
    EveCallQueuePageModule,
    EveSlipTrunkPageModule
  ]

})
export class EvePagesModule {}
