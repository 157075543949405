import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {
  CallQueue,
  CreateCallQueueGQL,
  DistributionType,
  CallQueueInput,
  GetCallQueueByGuidGQL,
  GetCallQueueByGuidQuery,
  UpdateCallQueueGQL,
  EveUser
} from "../../../generated/graphql";
import { firstValueFrom, Observable, map } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CallQueueFormGroup, EveCallQueuePageValidationService } from './eve-call-queue-page-validation.service';
import { AppService } from '../../../app/services/app.service';
import { MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';




@Component({
  selector: 'app-call-queue-page',
  templateUrl: './eve-call-queue-page.component.html',
  styleUrls: ['./eve-call-queue-page.component.css']
})
export class EveCallQueuePageComponent implements OnInit {


  public callQueue!:CallQueue;

  
  @ViewChild('scrollTo')
  public scrollTo: ElementRef = {} as any;
  @ViewChild('stepper') stepper!: MatStepper;



  public formGroup!: FormGroup<CallQueueFormGroup>;
  public callQueueInput!: Observable<CallQueueInput | null>;


  distributionType = DistributionType;

  constructor(private route: ActivatedRoute,
    public createCallQueueGQL: CreateCallQueueGQL,

    public updateCallQueueGQL: UpdateCallQueueGQL,
    public getCallQueueByGuidGQL: GetCallQueueByGuidGQL,
    private appService: AppService,
    private validation: EveCallQueuePageValidationService,
    private toastr: ToastrService,
    private loaderService: LoaderService
  ) {


   
  }

  async ngOnInit(): Promise<any> {

    this.route.params.subscribe(async (params: any) => {
      const guid = params["guid"];
      if (guid != null) {

        // The call queue already exists.
        this.getCallQueueByGuidGQL.fetch({
          guid: guid
        }).subscribe((response: ApolloQueryResult<GetCallQueueByGuidQuery>) => {
          var cq = response.data.callQueue;
          this.callQueue = cq as any;
          this.appService.organisationNode = this.callQueue.organisationNode;
          this.formGroup = this.validation.generateValidationForm((JSON.parse(JSON.stringify(this.callQueue)) ?? {}) as CallQueue);
          this.callQueueInput = this.formGroup.valueChanges.pipe(map(c => this.validation.generateCallQueueInput(this.formGroup, this.callQueue)))

        
        });
      }
      else {
        //create empty callQueue
        const queryParams = await firstValueFrom(this.route.queryParams);
        const organisationNode = BuildOrganisationNode(queryParams);


        this.callQueue = {
          guid: "",
          isLight: queryParams["IsAcdLight"] == "true",
          _24hrStatisticsFixed: false,
          _24hrStatisticsResetTime: "PT0S",
          alternateQueueInfo: false,
          callbackAttempts: 0,
          callbackMessagePrompt: (null as any),
          callbackRequeue: 0,
          placedInQueueMessagePrompt: (null as any),
          playEstimatedTime: false,
          playQueuePosition: false,
          playRingCallingAgent: false,
          progressMessagePrompt: (null as any),
          progressRepeat: 1,
          queueingMessagePrompt: (null as any),
          statisticsPeriod: 1,
          callQueueId: 0,
          excludeMobile: true,
          forwardCallsAfter: 0,
          forwardCallsTo: null,
          maxAgentAttempts: 0,
          maxAgentAttemptsFallback: null,
          maxCallsInQueue: 0,
          maxWaitTime: 1,
          organisationNode: organisationNode,
          queueFullForwardTo: null,
          queueFullSendToBusy: false,
          showInSearch: true,
          useLongestWait: false,
          agentTimeOut: 30,
          callRecording: (null as any),
          checkAvailablePresence: false,
          helpNumber: null,
          missedCallsLogout: 0,
          number: (null as any),
          queueSize: 5,
          ringingAgents: 1,
          wrapUpTime: 0,
          description: "",
          name: "",
          distributionType: DistributionType.Priority,
          callbackValidity: 120,

          users: [],
          supervisors: [],
          schedules: [],
        };

        this.formGroup = this.validation.generateValidationForm((JSON.parse(JSON.stringify(this.callQueue)) ?? {}) as CallQueue);

        this.callQueueInput = this.formGroup.valueChanges.pipe(map(c => this.validation.generateCallQueueInput(this.formGroup, this.callQueue)))
      }
      
    });
  }

  title() {
    if (this.callQueue.name == "") return "New Call Queue"
    else return this.callQueue.name;
  }


  onStepChange() {
    setTimeout(() => {
      this.scrollTo.nativeElement.scrollIntoView();
    }, 0);
  }

  onCancelClick = () => {
    location.href = '/Search/groups'
  }

  onNextClicked = (formGroup: AbstractControl<any,any> | null) => {
    if (formGroup != null) {
      this.markAllAsTouched(formGroup as FormGroup);
      formGroup?.updateValueAndValidity();
    }

    if (formGroup == this.formGroup.get("distribution")) {
      if (this?.formGroup?.get("details")?.get("distributionType")?.value  == DistributionType.Skills && (this?.formGroup?.get("distribution")?.get("skillRating")?.value?.unallocated?.length ?? 0) > 0) {

        this.toastr.error("All users need to be assigned a skill rating");
        return;
      }
    }

    if (formGroup?.invalid == false) this.stepper.next();
  }
   
  public markAllAsTouched(formGroup: FormGroup): void {
    if (formGroup.controls == null) return;
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }


  async onSaveButtonClick() {

    this.markAllAsTouched(this.formGroup);
    this.formGroup.updateValueAndValidity();


    

    if (this.formGroup.invalid || this.formGroup.pending) return;


    var callQueueInput = this.validation.generateCallQueueInput(this.formGroup, this.callQueue);
    if (callQueueInput == null) {
      this.toastr.warning("no changes", "Warning");
      return;
    }




    
    try {
      this.loaderService.showLoader();

      if (callQueueInput.callQueueId == null) {


        const createCallQueueResult = await firstValueFrom(
          this.createCallQueueGQL.mutate({
            callQueue: callQueueInput,
          })
        );

        if (createCallQueueResult.errors?.length ?? 0 > 0) throw "";



      }
      else {

        const updateCallQueueResult = await firstValueFrom(
          this.updateCallQueueGQL.mutate({
            callQueue: callQueueInput
          })
        );

        if (updateCallQueueResult.errors?.length ?? 0 > 0) throw "";
       // alert("Success");
      }


      window.location.href = '/Search/Groups?toast=' + (callQueueInput.callQueueId == null ? 'TH_GlobalCreated' : 'TH_GlobalUpdated') + '&toastType=Call%20Queue&toastData=' + encodeURIComponent(callQueueInput.name ?? this.callQueue.name);

    }
    catch (ex : any) {
      this.loaderService.hideLoader();

      if (ex.name == "ApolloError") this.toastr.error(`Error saving CallQueue - ${ex.message}`, "Error");
      else this.toastr.error(`Error saving CallQueue - ${ex}`, "Error");
    }

  }


  get users() : EveUser[] {
    return this.callQueue.users.map(c => c.eveUser);
  }
}


export function BuildOrganisationNode(queryParams: any) {

  var organisaitonNode = "/1/";

  if (queryParams.WholesalerOrganisationId != null && queryParams.WholesalerOrganisationId != "undefined") organisaitonNode += `${queryParams.WholesalerOrganisationId}/`;
  if (queryParams.ResellerOrganisationId != null && queryParams.ResellerOrganisationId != "undefined") organisaitonNode += `${queryParams.ResellerOrganisationId}/`;
  if (queryParams.CompanyOrganisationId != null && queryParams.CompanyOrganisationId != "undefined") organisaitonNode += `${queryParams.CompanyOrganisationId}/`;
  if (queryParams.SiteOrganisationId != null && queryParams.SiteOrganisationId != "undefined") organisaitonNode += `${queryParams.SiteOrganisationId}/`;


  return organisaitonNode;
}


