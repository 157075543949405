import { Component, ContentChild, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { EveUser } from "../../../../../generated/graphql";
import { CallQueueUser } from "../../../../../generated/graphql";

import { faCheck, IconDefinition } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'eve-user-carousel-item',
  templateUrl: './eve-user-carousel-item.component.html',
  styleUrls: ['./eve-user-carousel-item.component.css']
})

export class EveUserCarouselItemComponent {
  @Input() eveUser! : EveUser;

  avatarLoaded: boolean = false


  @ContentChild('avatarExtras') avatarExtras!: TemplateRef<any>;

  constructor() {
  }




  

}
