import {
  Component, ComponentFactoryResolver, Directive, ElementRef, HostBinding, Input, ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'eve-loading',
  templateUrl: './eve-loading.component.html',
  styleUrls: ['./eve-loading.component.css']
})
export class EveLoadingComponent{

  @Input()
  show: boolean = false;

  @HostBinding('style.display') get display() { return this.show ? "block" : "none"};


}



