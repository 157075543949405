


  <ng-container *ngIf="currentUsers.length > 0">

    <eve-carousel title="Current {{title}}" [searchFunc]="searchFunc_Remove">
      <ng-template #itemTemplate let-eveUser="item">
        <eve-user-carousel-item [eveUser]="eveUser" (click)="onEveUserClick(eveUser)">

          <ng-template #avatarExtras>
            <div *ngIf="isSelected(eveUser) == false" class="selected">
              <i class="fa fa-remove"></i>
            </div>
          </ng-template>

        </eve-user-carousel-item>
      </ng-template>
    </eve-carousel>

  </ng-container>



  <eve-carousel title="Add {{title}}" [searchFunc]="searchFunc_Add">
    <ng-template #itemTemplate let-eveUser="item">
      <eve-user-carousel-item [eveUser]="eveUser" (click)="onEveUserClick(eveUser)">
        <ng-template #avatarExtras>
          <div *ngIf="isSelected(eveUser)" class="selected">
            <i class="fa fa-check"></i>
          </div>
        </ng-template>
      </eve-user-carousel-item>
    </ng-template>
  </eve-carousel>

  <!--
  <div style="padding-bottom: 30px;" class="clearfix">
    <h3 class="carousel-title">{{carouselTitle}}</h3>
    <input pInputText (keyup)="onSearchChange($event)" style="float:right; width:267px;" />
  </div>
  <div class="eveUsersPage">
    <ng-container *ngIf="eveUsersPage | async as _eveUsersPage else loading">
      <span (click)="paginator.previousPage()"
            class="fa fa-solid fa-angle-left eveUsersPaginationControl eveUsersPaginationControl-previous"
            [attr.disabled]="paginator.hasPreviousPage() == false"></span>

      <div class="clearfix">
        <eve-user-carousel-item *ngFor="let eveUser of _eveUsersPage.data.eveUsers.items" [eveUser]="eveUser"
                                (click)="onAddEveUserClick(eveUser)" [selected]="isEveUserSelected(addUsers, eveUser)" style="float:left"
                                [style.width]="(200 / paginator.pageSize) + '%'">
        </eve-user-carousel-item>
      </div>


      <span (click)="paginator.nextPage()"
            class="fa fa-solid fa-angle-right eveUsersPaginationControl eveUsersPaginationControl-next"
            [attr.disabled]="paginator.hasNextPage() == false">

      </span>
    </ng-container>
    <mat-paginator #paginator [length]="eveUsersTotalCount" [pageSize]="12" [pageSizeOptions]="[8, 12, 14, 18]">
    </mat-paginator>
    <ng-template #loading>
      loading
    </ng-template>
  </div>
    -->


