<p-autoComplete name="Authentication Type" *ngIf="AuthenticationOptions.length > 0"
    [ngModel]="selectedAuthenticationOption" (ngModelChange)="onNgModelChange($event)" (onClear)="onClear()"
    [showEmptyMessage]="true" [suggestions]="filteredAuthenticationOption" (completeMethod)="filterResults($event)"
    [dropdown]="true" [showClear]="eveModel != null" field="label" [placeholder]="placeholder" [forceSelection]="true"
    [disabled]="controlDisabled"
    >
</p-autoComplete>
<ng-container *ngIf="validationTemplate == null">
    <eve-validation-errors [name]="placeholder"></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>