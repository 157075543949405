import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eve-not-found',
  templateUrl: './eve-not-found.component.html',
  styleUrls: ['./eve-not-found.component.css']
})
export class EveNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
