import {AfterContentInit, AfterViewInit, Component, ContentChild, ContentChildren, Directive, EventEmitter, forwardRef, Injector, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {NgxMaterialTimepickerTheme} from "ngx-material-timepicker";
import {ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import moment from 'moment';
import { first, startWith } from 'rxjs';


@Component({
  selector: 'eve-tile-menu-item',
  styles: [`

    :host{
      diplay:block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tile-icon {
      position: relative;
      width: 100%;
      font-size: 72px;
      text-align: center;
    }

    .tile-text {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }


    .tile-tick {
      width: 28px;
      height: 28px;
      border-radius: 20px;
      position: absolute;
      background: #00B3CE;
      top: -8px;
      right: -9px;
      display:none;
    }

    .tile-tick-icon {
      color: #fff;
      position: absolute;
      top: 4px;
      left: 4.5px;
      font-size: 19px;
    }


  `],
  template: `
  <ng-template #itemTemplate>
    <div class="tile-tick">
      <i class="tile-tick-icon fa fa-check"></i>
    </div>
    
    <i class="tile-icon {{icon}}" *ngIf="icon != null"></i>
    <div class="tile-text">{{name}}</div>
  </ng-template>
`
})
export class EveTileMenuItemComponent {

  @Input()
  icon!: string;

  @Input()
  name!: string;

  @Input()
  value!: any;

  
  @ViewChild('itemTemplate')
  template!: TemplateRef<any>
}



@Component({
  selector: 'eve-tile-menu',
  templateUrl: './eve-tile-menu.component.html',
  styleUrls: ['./eve-tile-menu.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveTileMenuComponent),
      multi: true
    }
  ]
})
export class EveTileMenuComponent implements AfterContentInit, ControlValueAccessor {

  @Input()
  value!: any;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();


  @Input()
  multiselect: boolean = false;

  @ContentChildren(EveTileMenuItemComponent) viewChildren!: QueryList<EveTileMenuItemComponent>;
  items: EveTileMenuItemComponent[] = [];

  onChange: any = () => {}
  onTouch: any = () => { }


  @Input()
  comparer!: (a:any,b:any) => boolean ;

  constructor() {
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.viewChildren.changes.pipe(startWith(this.viewChildren)).subscribe(s => {
        var b = s;
        this.items = s.toArray();
      });
    }, 0)
    
  }


  onClick(item: EveTileMenuItemComponent) {


    if (this.multiselect) {
      if (this.value == null) this.value = [];

      const index = this.value.findIndex((i: any) => this.comparer?.call(null, i, item.value) ?? i == item.value)

      if (index == -1) this.value.push(item.value);
      else this.value.splice(index, 1);
    }
    else {
      this.value = item.value;
    }
    
    
    this.valueChange.emit(this.value);
    this.onChange(this.value);
    this.onTouch(this.value);
    
  }


  isSelected(item: EveTileMenuItemComponent) {
    return this.multiselect ? (this.value != null && this.value.find((i: any) => this.comparer?.call(null, i, item.value) ?? i == item.value)) : (item.value == this.value)
  }
  
  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.value = value;
  }
}



