import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'eve-toggle',
  templateUrl: './eve-toggle.component.html',
  styleUrls: ['./eve-toggle.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveToggleComponent),
      multi: true
    }
  ]
})
export class EveToggleComponent implements OnInit, ControlValueAccessor {

  @Input() eveModel: boolean = true;
  @Output() eveModelChange: EventEmitter<boolean> = new EventEmitter();

  onChange: any = () => { }
  onTouch: any = () => { }

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.eveModel = !this.eveModel;
    this.eveModelChange.emit(this.eveModel);
    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }
}
