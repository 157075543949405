<div  #scrollTo>
  <ng-container *ngIf="callQueue != null else loading">


    <mat-stepper #stepper [linear]="true" [animationDuration]="0" (selectionChange)="onStepChange()">
      <mat-step [stepControl]="formGroup.get('details')" label="Details">

        <form novalidate autocomplete="off" [formGroup]="formGroup">
          <eve-call-queue-details [callQueue]="formGroup.get('details')" [users]="users" [organisationNode]="callQueue.organisationNode">
          </eve-call-queue-details>
        </form>
        <div class="right-align">
          <button pButton class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button pButton class="evebutton" (click)="onNextClicked(formGroup.get('details'))">Next</button>
        </div>
      </mat-step>

      <mat-step #agentPriorityStep [stepControl]="formGroup.get('distribution')" label="Priority" *ngIf="formGroup.get('details')?.get('isLight')?.value == false">
        <form [formGroup]="formGroup.get('distribution')">


          <skill-rating *ngIf="formGroup.get('details')?.get('distributionType')?.value == distributionType.Skills" formControlName="skillRating">
          </skill-rating>

          <priority *ngIf="formGroup.get('details')?.get('distributionType')?.value == distributionType.Priority" formControlName="priorityUsers">
          </priority>

        </form>
        <div class="right-align">
          <button class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button matStepperPrevious class="evebutton">Prev</button>
          <button pButton class="evebutton" (click)="onNextClicked(formGroup.get('distribution'))">Next</button>
        </div>
      </mat-step> 

      <mat-step [stepControl]="formGroup.get('agentSettings')" label="Agent Settings">
        <form [formGroup]="formGroup">
          <eve-call-queue-agent-settings [callQueue]="formGroup.get('agentSettings')" [organisationNode]="callQueue.organisationNode">
          </eve-call-queue-agent-settings>
        </form>
        <div class="right-align">
          <button class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button matStepperPrevious class="evebutton">Prev</button>
          <button class="evebutton" (click)="onNextClicked(formGroup.get('agentSettings'))">
            Next
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="formGroup.get('configuration')" label="Configuration">
        <form [formGroup]="formGroup">
          <eve-call-queue-configuration [callQueue]="formGroup.get('configuration')" [organisationNode]="callQueue.organisationNode">
          </eve-call-queue-configuration>
        </form>
        <div class="right-align">
          <button class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button matStepperPrevious class="evebutton">Prev</button>
          <button class="evebutton" (click)="onNextClicked(formGroup.get('configuration'))">
            Next
          </button> 
        </div>
      </mat-step>

      <mat-step label="Supervisors" #supervisorStep *ngIf="formGroup.get('details')?.get('isLight')?.value == false">
        <form>


          <eve-container title="Supervisor Select" icon="fa fa-list" [formGroup]="formGroup">
            <eve-user-selected-list formControlName="supervisors" [title]="'Supervisors'" [currentUsers]="callQueue.supervisors"
                                    [organisationNode]="callQueue.organisationNode"></eve-user-selected-list>
          </eve-container>


        </form>
        <div class="right-align"> 
          <button class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button matStepperPrevious class="evebutton">Prev</button>
          <button class="evebutton" (click)="onNextClicked(formGroup.get('supervisors'))"> 
            Next 
          </button>
        </div>
      </mat-step> 

      <mat-step [stepControl]="formGroup.get('schedules')" label="Scheduling">

        <form novalidate autocomplete="off" [formGroup]="formGroup">
          <eve-call-queue-scheduling [scheduling]="formGroup.get('scheduling')" [organisationNode]="callQueue.organisationNode">
          </eve-call-queue-scheduling>
        </form>
        <div class="right-align">
          <button class="evebutton" (click)="onCancelClick()">Cancel</button>
          <button matStepperPrevious class="evebutton">Prev</button>
          <button class="evebutton" (click)="onSaveButtonClick()">
            Save
          </button>

        </div>
      </mat-step>
    </mat-stepper>



  </ng-container>
</div>
<ng-template #loading>
  <img src="/content/images/spinner.gif" style="margin: auto; display: block; " />
</ng-template>
