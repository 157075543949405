import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { GetSipTrunkGQL, UpdateSipTrunkGQL } from '../../../generated/graphql';
import { EveSipTrunkDetailsValidationService } from './details/eve-sip-trunk-details-validation.service';
import { IEveSipTrunkDetails } from './interfaces/i-eve-sip-trunk-details';
import { AbstractControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { IEveSipTrunkNumber } from './interfaces/i-eve-sip-trunk-number';

@Component({
  selector: 'eve-sip-trunk-page',
  templateUrl: './eve-sip-trunk-page.component.html',
  styleUrls: ['./eve-sip-trunk-page.component.css']
})
export class EveSipTrunkPageComponent implements OnInit {

  private trunkId?: number;

  public sipTrunkDetails!: IEveSipTrunkDetails;
  public sipTrunkDetailsForm!: FormGroup;

  private originalSipTrunkNumbers:IEveSipTrunkNumber[] = []; 
  constructor(private route: ActivatedRoute,
    private getSipTrunkGQL: GetSipTrunkGQL,
    private updateSipTrunkGQL: UpdateSipTrunkGQL,
    private sipTrunkDetailsValidationService: EveSipTrunkDetailsValidationService,
    private appService: AppService
    ) {
    var snapShot = this.route.snapshot.queryParams["id__sipTrunkId"];
    if(snapShot !== null)
    {
      this.trunkId = parseInt(snapShot, 16) - 56747;
    }
   }
   
  ngOnInit(): void {
    this.sipTrunkDetailsForm = this.sipTrunkDetailsValidationService.generateValidationForm();
    this.getSipTrunkGQL.fetch({sipTrunkId: this.trunkId}).subscribe(response => {
      this.sipTrunkDetails = {...JSON.parse(JSON.stringify(response.data.sipTrunk))};
      this.appService.organisationNode =  this.sipTrunkDetails.organisationNode;
      
      this.originalSipTrunkNumbers = [...this.sipTrunkDetails.sipTrunkNumbers]
      console.log('sipTrunkData: ', JSON.stringify(this.sipTrunkDetails));
    });
  }

  
  onCancelClick = () => {
    location.href = '/Search/groups'
  }

  onSaveButtonClick = async (formGroup: AbstractControl<any,any> | null) => {
    try {
      if (formGroup != null) {
        this.markAllAsTouched(formGroup as FormGroup);
        formGroup?.updateValueAndValidity();
      }
  
      const sipTrunkInput : any = {
        sipTrunkId: this.sipTrunkDetails.sipTrunkId
      };

      sipTrunkInput.organisationId = this.appService.organisationId;
      sipTrunkInput.organisationNode = this.appService.organisationNode;

      if (this.sipTrunkDetailsForm?.get("name")?.dirty){
        sipTrunkInput.name = this.sipTrunkDetails.name;
      }

      if (this.sipTrunkDetailsForm?.get("number")?.dirty){
        sipTrunkInput.number = { numberId: this.sipTrunkDetails.number.numberId};
      }

      if (this.sipTrunkDetailsForm?.get("authenticationType")?.dirty){
        sipTrunkInput.authenticationType = this.sipTrunkDetails.authenticationType;
      }

      if (this.sipTrunkDetailsForm?.get("authenticationUsername")?.dirty){
        sipTrunkInput.authenticationUsername = this.sipTrunkDetails.authenticationUsername;
      }

      if ( this.sipTrunkDetailsForm?.get("authenticationPassword")?.dirty){
        sipTrunkInput.authenticationPassword = this.sipTrunkDetails.authenticationPassword;
      }

      if ( this.sipTrunkDetailsForm?.get("ipAddress")?.dirty){
        sipTrunkInput.ipAddress = this.sipTrunkDetails.ipAddress;
      }

      if (this.sipTrunkDetailsForm?.get("inboundTranslation")?.dirty){
        const inboundTranslation = this.sipTrunkDetails.inboundTranslation
        delete inboundTranslation.__typename;
        
        sipTrunkInput.inboundTranslation = this.sipTrunkDetails.inboundTranslation;
      }

      if (this.sipTrunkDetailsForm?.get("sipTrunkPresentation")?.dirty){
        sipTrunkInput.sipTrunkPresentation = this.sipTrunkDetails.sipTrunkPresentation;
      }

      if (this.sipTrunkDetailsForm?.get("channels")?.dirty){
        sipTrunkInput.channels = this.sipTrunkDetails.channels;
      }

      if (this.sipTrunkDetailsForm?.get("inboundChannelsLimit")?.dirty){
        sipTrunkInput.inboundChannelsLimit = this.sipTrunkDetails.inboundChannelsLimit;
      }

      if (this.sipTrunkDetailsForm?.get("outboundChannelsLimit")?.dirty){
        sipTrunkInput.outboundChannelsLimit = this.sipTrunkDetails.outboundChannelsLimit;
      }
   
      if (this.sipTrunkDetailsForm?.get("callRecording")?.dirty){
        sipTrunkInput.callRecording = this.sipTrunkDetails.callRecording;
      }

      if (this.sipTrunkDetailsForm?.get("busyOnChannelsExceeded")?.dirty){
        sipTrunkInput.busyOnChannelsExceeded = this.sipTrunkDetails.busyOnChannelsExceeded;
      }

      if (this.sipTrunkDetailsForm?.get("barringLocal")?.dirty){
        sipTrunkInput.barringLocal = this.sipTrunkDetails.barringLocal;
      }

      if (this.sipTrunkDetailsForm?.get("barringMobile")?.dirty){
        sipTrunkInput.barringMobile = this.sipTrunkDetails.barringMobile;
      }

      if (this.sipTrunkDetailsForm?.get("barringFreephone")?.dirty){
        sipTrunkInput.barringFreephone = this.sipTrunkDetails.barringFreephone;
      }

      if (this.sipTrunkDetailsForm?.get("barring084")?.dirty){
        sipTrunkInput.barring084 = this.sipTrunkDetails.barring084;
      }

      if (this.sipTrunkDetailsForm?.get("barringDirectory")?.dirty){
        sipTrunkInput.barringDirectory = this.sipTrunkDetails.barringDirectory;
      }

      if (this.sipTrunkDetailsForm?.get("barringPremium")?.dirty){
        sipTrunkInput.barringPremium = this.sipTrunkDetails.barringPremium;
      }

      if (this.sipTrunkDetailsForm?.get("barringInternational")?.dirty){
        sipTrunkInput.barringInternational = this.sipTrunkDetails.barringInternational;
      }

      if (this.sipTrunkDetailsForm?.get("sipTrunkNumberGroup")?.dirty){
        const currentSipTrunkNumbers = this.sipTrunkDetails.sipTrunkNumbers;

        const addSipTrunkNumbers = currentSipTrunkNumbers.filter(item => item.sipTrunkNumberId === undefined);
        const deleteSipTrunkNumbers = this.originalSipTrunkNumbers.filter(item => currentSipTrunkNumbers.indexOf(item) < 0);
        const updateSipTrunkNumbers = this.originalSipTrunkNumbers.filter(item => currentSipTrunkNumbers.indexOf(item) >= 0);
      
        sipTrunkInput.addSipTrunkNumbers = this.convertToSipTrunkInput(addSipTrunkNumbers);
        sipTrunkInput.updateSipTrunkNumbers = this.convertToSipTrunkInput(updateSipTrunkNumbers);
        sipTrunkInput.deleteSipTrunkNumbers = this.convertToSipTrunkInput(deleteSipTrunkNumbers);
      }

      if (formGroup?.invalid == false){
        await firstValueFrom(this.updateSipTrunkGQL.mutate({sipTrunk: sipTrunkInput}));
      }

    } catch (error) {
      alert("Failed - " + JSON.stringify(error));
    }
  }
   
  private convertToSipTrunkInput = (sipTrunkNumbers: IEveSipTrunkNumber[]): any[] => {
    const sipTrunkInput = sipTrunkNumbers.map(item => {
      return {
        sipTrunkNumberId: item.sipTrunkNumberId,
        sipTrunkId: this.sipTrunkDetails.sipTrunkId,
        numberId: item.numberId,
        failoverNumber: item.failoverNumber,
        divertNumber: item.divertNumber,
        isDiverted: item.isDiverted
      };
     
    });

    return sipTrunkInput;
  };
  public markAllAsTouched(formGroup: FormGroup): void {
    if (formGroup.controls == null) return;
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }

}
