
<div mat-dialog-title class="eve-container-header">
	<div class="eve-container-header-icon">
		<i class="fa fa-list-ul"></i>
	</div>
	<div class="eve-container-header-title">Numbers</div>
</div>

<ng-container *ngIf="action === null">

	<div id="AddItemWizardPage-MainOptions" style="width: 100%; display: flex; justify-content: center;">
		<div class="option-item option-item-external col-xs-3" (click)="action = 'externalClick'">
			<div class="numbers-big">+123</div>
			<div>External</div>
		</div>

		<div class="option-item option-item-internal col-xs-3" (click)="action = 'internalClick'">
			<div class="numbers-big">+123</div>
			<div>Internal</div>
		</div>
	</div>

</ng-container>


<eve-internal-ranges *ngIf="action === 'internalClick'" [organisationId]="organisationId" (completed)="onCompeleted()"></eve-internal-ranges>

<eve-external-ranges *ngIf="action === 'externalClick'" [(ngModel)]="OrderNumbersModel"></eve-external-ranges>



<mat-dialog-actions align="end">
	<div class="button-menu" style="margin-bottom: 10px;">
		<button type="button" class="evebutton btn-cancel" (click)="onCancelClick($event)">Cancel</button>
		<button type="button" *ngIf="action !== null" class="evebutton btn-prev" (click)="onPrevClick()">Prev</button>
		<button type="button" *ngIf="action === 'externalClick'" class="evebutton" (click)="onPlaceOrder()">Place Order</button>
		<button type="button" *ngIf="action === 'internalClick'" class="evebutton" (click)="internalRangesComponent.onSaveClick()">Save</button>

	</div>

</mat-dialog-actions>
