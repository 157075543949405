import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { Observable, of, startWith } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";


@Component({
  selector: 'eve-select',
  templateUrl: './eve-select.component.html',
  styleUrls: ['./eve-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveSelectComponent),
      multi: true
    }
  ]
})

export class EveSelectComponent implements OnInit, ControlValueAccessor {


  @Input() placeholder = '';

  @Input() options!: any[];

  @Input() autoCompleteOptionDisplay!: string;

  @Input()ngModel!: any;
  @Output()ngModelChange: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild('eveSelectItemTemplate')
  eveSelectItemTemplate!:TemplateRef<any>;



  formControl = new FormControl('');

  onChange: any = () => {}
  onTouch: any = () => {}


  ngOnInit(): void {
    this.formControl = new FormControl(null);
    this.formControl.setValue(this.ngModel);
  }

  onOptionSelected(option: any){
    this.ngModel = option;
    this.ngModelChange.emit(this.ngModel);

    this.onChange(this.ngModel);
    this.onTouch(this.ngModel);
  }

  // autoCompleteOptionDisplayWith(option: any) {
  //   if (option == null) return '';
  //   if (typeof option == "string") return option;
  //   else if (this.autoCompleteOptionDisplay != null) return new Function("return `"+this.autoCompleteOptionDisplay +"`;").call(option);
  //   return JSON.stringify(option);
  // }

  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.ngModel = value;
  }
}


