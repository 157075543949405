import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './eve.component.html',
  styleUrls: ['./eve.component.scss',
    "../../node_modules/primeicons/primeicons.css",
    "../../node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../node_modules/primeng/resources/primeng.min.css"],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EveComponent {

  title: string = 'CQClientApp';


  constructor() {




    const getComputedStyle = window['getComputedStyle'];
    window['getComputedStyle'] = (element: Element, pseudoElt: string| null | undefined): CSSStyleDeclaration => {

      try {
        return getComputedStyle(element, pseudoElt);
      }
      catch (ex) {
        return getComputedStyle(window.document.body);
      }
    }
  }

}
