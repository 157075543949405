import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WebComponentOverlayContainer extends OverlayContainer implements OnDestroy {
  public constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    platform: Platform,
  ) {
    super(document, platform);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public override getContainerElement(): HTMLElement {
    if (
      !this._containerElement ||
      !this.getContainerParent().querySelector(`.${this._containerElement.className}`)
    ) {
      this._createContainer();
    }

    return this._containerElement;
  }

  public getRootElement(): Element | null | undefined {
    const shadowRoot = this.getContainerParent();

    return shadowRoot.getRootNode() as HTMLElement;
  }

  public createContainer(): void {
    this._createContainer();
  }

  protected override _createContainer(): void {
    super._createContainer();
    this._appendToRootComponent();
  }

  private _appendToRootComponent(): void {
    if (!this._containerElement) {
      return;
    }

    const rootElement = this.getRootElement();
    const parent = rootElement || this._document.body;

    parent.appendChild(this._containerElement);
  }

  // In my case - I take this function to separate ShadowDomService
  private getContainerParent(): ShadowRoot | Document {
    return this.document.querySelector('app-root')?.shadowRoot ?? this.document;
  }
}
