import { Component, ContentChild, Input, OnInit, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SipTrunkPresentationPipe } from '../../pipes/sip-trunk-presentation/sip-trunk-presentation.pipe';
import { SipTrunkPresentationType } from '../../../generated/graphql';

const sipTrunkPresentationPipe: SipTrunkPresentationPipe = new SipTrunkPresentationPipe();
@Component({
  selector: 'eve-sip-trunk-presentation',
  templateUrl: './eve-sip-trunk-presentation.component.html',
  styleUrls: ['./eve-sip-trunk-presentation.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveSipTrunkPresentationComponent),
      multi: true
    }
  ]
})
export class EveSipTrunkPresentationComponent implements OnInit, ControlValueAccessor {
  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;
  @Input() placeholder: string = 'Select an outbound Presentation...';
  
  public eveModel?: SipTrunkPresentationType;
  onChange: any = () => { };
  onTouch: any = () => { };

  presentationOptions: any[] = [
    { label: sipTrunkPresentationPipe.transform(SipTrunkPresentationType.Anonymous), value: SipTrunkPresentationType.Anonymous},
    { label: sipTrunkPresentationPipe.transform(SipTrunkPresentationType.Passthrough), value: SipTrunkPresentationType.Passthrough},
    { label: sipTrunkPresentationPipe.transform(SipTrunkPresentationType.UserDefined), value: SipTrunkPresentationType.UserDefined}
  ]
  filteredOptions!: string[];
  selectedOption: any = null;

  constructor() { }
  writeValue(value: SipTrunkPresentationType): void {
    this.eveModel = value;
    this.selectedOption = this.presentationOptions.filter(x => x.value.toLowerCase() === this.eveModel?.toString().toLowerCase())[0];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  filterOption($event: any) {
    let filtered : any[] = [];
    let query = $event.query;
   
    for(let i = 0; i < this.presentationOptions.length; i++) {
      let option = this.presentationOptions[i];
      if (option.label.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        filtered.push(option);
      }
    }

    this.filteredOptions = [...filtered];
  }

  onClear(){
    this.onNgModelChange(null);
  }

  onNgModelChange(option: any) {
    this.selectedOption = option;
    this.eveModel = option?.value;
    
    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

}
