<form novalidate autocomplete="off" [formGroup]="internalRanges" *ngIf="internalRanges">
  <div class="eve-container-body-inner row" *ngIf="internalRanges && internalRanges.length > 0">
    <div  class="container">

      <ng-container *ngFor="let internalNumberRange of internalRanges.controls; let i=index;">
        <div [formGroup]="internalNumberRange" class="row internal-range-row" [ngClass]="{'ng-row-invalid':internalNumberRange.errors != null}">


          <div class="col-2">From</div>
          <div class="col-4">
            <div class="input-group">
              <input pInputText formControlName="from"  />
              <eve-validation-errors name="From" [validationControl]="internalNumberRange.get('from')">
              </eve-validation-errors>
            </div>
          </div>


          <div class="col-2">To</div>
          <div class="col-4">
            <div class="input-group">
              <input pInputText formControlName="to" />
              <eve-validation-errors name="To" [validationControl]="internalNumberRange.get('to')">
              </eve-validation-errors>
            </div>
          </div>

          <div class="deleteRowButton" (click)="removeInternalNumberRange(i)"> <i class="deleteRowIcon fa fa-close"></i> </div>

          <eve-validation-errors name="Row" [validationControl]="internalNumberRange"></eve-validation-errors>
        </div>

      </ng-container> 
    </div>
  </div>

  <div style="text-align: center;">
    <button mat-fab color="primary" class="icon-button-large" (click)="onAddClick()">
      <mat-icon>add</mat-icon>
    </button>
    <br />
    Add new range 
  </div>
</form>
