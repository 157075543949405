import { Component, Input, OnInit } from '@angular/core';
import { IEveSipTrunkDetails } from '../interfaces/i-eve-sip-trunk-details';
import {  FormArray, FormGroup } from '@angular/forms';
import { EveSipTrunkNumberService } from '../../../components/eve-sip-trunk-number-modal/eve-sip-trunk-number.service';
import { EveSipTrunkDetailsValidationService } from './eve-sip-trunk-details-validation.service';
import { NumberFilterInput } from 'src/generated/graphql';
import { pairwise, startWith } from 'rxjs';

@Component({
  selector: 'eve-sip-trunk-details',
  templateUrl: './eve-sip-trunk-details.component.html',
  styleUrls: ['./eve-sip-trunk-details.component.css']
})
export class EveSipTrunkDetailsComponent implements OnInit {
  numberFilter: NumberFilterInput = {
    numberTypeId:{
      eq: 1
    }
  }

  constructor(
    private sipTrunkNumberService: EveSipTrunkNumberService,
    private sipTrunkValidationService: EveSipTrunkDetailsValidationService
    ) {}

  public _sipTrunkDetails!: IEveSipTrunkDetails
  @Input() set sipTrunkDetails(value: IEveSipTrunkDetails){
    this._sipTrunkDetails = value;

    let temp = value?.sipTrunkNumbers;
    if(temp){
      var list = 
      temp.map(x => {
          var element = {
          numberId: x.numberId,
          number: x.number,
          disabled:  false
        };

        if(element.numberId === value.number.numberId){
          element.disabled = true
        }
        return element
      });

      var element = list.filter(x => x.numberId === value.number.numberId);
      if(element === null){
        list.push({numberId: value.number.numberId, number: value.number.number1, disabled: true})
      }
      this.sipTrunkNumberService.sipTrunkSelectedNumbers = list;

      this._sipTrunkDetails.sipTrunkNumbers.forEach(x => this.sipTrunkNumbersFormArray.push(this.sipTrunkValidationService.buildSipTrunkNumberFormGroup(x.numberId, x.number)))

      if(this._sipTrunkDetails.sipTrunkId !== undefined)
      {
        this.sipTrunkDetailsForm.get('authenticationType')?.disable();
      }
      
      this.processChannels();
    }
  };

  get sipTrunkDetails(): IEveSipTrunkDetails{
    return this._sipTrunkDetails;
  }

  get sipTrunkNumbersFormArray(): FormArray {
    return this.sipTrunkValidationService.sipTrunkNumberFormArray;
  }

  @Input() sipTrunkDetailsForm!: FormGroup;

  ngOnInit(): void {
    console.log('SipTrunk Details: ', JSON.stringify(this.sipTrunkDetails));
    
  }

  public inboundChannelsLimitMax = 100;
  public outboundChannelsLimitMax = 100;

  public inboundChannelsLimitMin = 0;
  public outboundChannelsLimitMin = 0;

  public inboundChannelsLimitDisabled = true;

  // public isUserNameAuthenticationType = ():boolean =>{
  //   return this.sipTrunkDetails.authenticationType === 'USERNAME'
  // }

  processChannels =() => {
    this.sipTrunkDetailsForm.get('channels')?.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([channels, newChannels]:[any, any]) => {
        if(this.sipTrunkDetailsForm.get('authenticationType')?.value === 'USERNAME' ||
          channels === null || newChannels === null ) return;
        
        let newChannelsInt = parseInt(newChannels);
        let channelDiff = newChannelsInt - parseInt(channels);

        let inboundChannelsLimitControl = this.sipTrunkDetailsForm.get('inboundChannelsLimit');
        let outboundChannelsLimitControl = this.sipTrunkDetailsForm.get('outboundChannelsLimit');

        this.inboundChannelsLimitDisabled = true;
        if(channelDiff > 0){
          this.inboundChannelsLimitMax = newChannelsInt === 0 ? 1 : newChannelsInt;
          this.outboundChannelsLimitMax = newChannelsInt === 0 ? 1 : newChannelsInt;
        }

        if(newChannelsInt === 0 || newChannelsInt === 1){
          inboundChannelsLimitControl?.disable();
          outboundChannelsLimitControl?.disable();

          this.inboundChannelsLimitMin = 0;
          this.inboundChannelsLimitMin = 0;

          this.sipTrunkDetails.inboundChannelsLimit = newChannelsInt;
          this.sipTrunkDetails.outboundChannelsLimit = newChannelsInt;
        }else{
          inboundChannelsLimitControl?.enable();
          outboundChannelsLimitControl?.enable();

          this.inboundChannelsLimitMin = 1;
          this.outboundChannelsLimitMin = 1;

          let newInboundChannelsLimit = (this.sipTrunkDetails.inboundChannelsLimit || 1) + channelDiff;
          this.sipTrunkDetails.inboundChannelsLimit = newInboundChannelsLimit>= 1 ? 
            newInboundChannelsLimit > this.inboundChannelsLimitMax ? this.inboundChannelsLimitMax : newInboundChannelsLimit
            : 1;

          var newOutboundChannelsLimit = (this.sipTrunkDetails.outboundChannelsLimit || 1) + channelDiff;
          this.sipTrunkDetails.outboundChannelsLimit = newOutboundChannelsLimit>= 1 ? 
            newOutboundChannelsLimit > this.outboundChannelsLimitMax ? this.outboundChannelsLimitMax: newOutboundChannelsLimit
          : 1;
        }

        if (channelDiff < 0) {
          this.inboundChannelsLimitMax = newChannelsInt == 0 ? 1 : newChannelsInt;
          this.outboundChannelsLimitMax = newChannelsInt == 0 ? 1 : newChannelsInt;;
        }
    })
  }

  onNumberModelChange = (event: any) => {
    if(event.numberId === 0 ) return;
    let mainNumberId: number| null = null;
    mainNumberId = this.sipTrunkNumberService.sipTrunkSelectedNumbers.find(x => x.disabled===true)?.numberId;
    
    if(mainNumberId === event.numberId) return;

    this.sipTrunkNumberService.sipTrunkSelectedNumbers = this.sipTrunkNumberService.sipTrunkSelectedNumbers.filter(x => x.disabled === false);
    this.sipTrunkNumberService.sipTrunkSelectedNumbers.push({
      numberId: event.numberId,
      number: event.number,
      disabled: true
    })

    if(mainNumberId !== undefined)
    {
      let index = this.sipTrunkDetails.sipTrunkNumbers.findIndex(item => item.numberId === mainNumberId);
      if(index > -1){
        this.sipTrunkDetails.sipTrunkNumbers.splice(index, 1);
      }

      let indexArray = this.sipTrunkNumbersFormArray.controls.findIndex(item => item.get('numberId')?.value === mainNumberId)

      if(indexArray > -1){
        this.sipTrunkNumbersFormArray.controls.splice(indexArray, 1);
      }
    }

    this.sipTrunkDetails.sipTrunkNumbers.push({
      sipTrunkId: this.sipTrunkDetails.sipTrunkId,
      numberId: event.numberId,
      number: event.number,
      failoverNumber: null,
      divertNumber: null,
      isDiverted: false
    } as any)

    this.sipTrunkDetails.sipTrunkNumbers = [...this.sipTrunkDetails.sipTrunkNumbers]
    this.sipTrunkNumbersFormArray.push(this.sipTrunkValidationService.buildSipTrunkNumberFormGroup(event.numberId, event.number));
    this.sipTrunkNumbersFormArray.markAsDirty();
  }
}
