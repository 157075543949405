<div id="eve-crop">
<div [className]="classes.actions">
  <input #_fileInput type="file" (change)="cropper.selectInputEvent($event)" accept="image/*" hidden>
</div>

  <ly-img-cropper [withClass]="classes.cropper"
                  [config]="cropperConfig"
                  (cropped)="onCropped($event)"
                  (ready)="ready = true"
                  (cleaned)="ready = false">

  <span style="color: white;">
    <pre>Click here to select or
drag and drop an image</pre>
  </span>
  </ly-img-cropper>

  <div *ngIf="ready">
    <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
    <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
    <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
    <button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
    <button (click)="clear.emit()" ly-button appearance="icon"><ly-icon>delete_outline</ly-icon></button>
  </div>
<!--<div><img *ngIf="croppedImage" [src]="croppedImage"></div>-->
</div>
