import { NgModule } from "@angular/core";
import { CallRecordingPipe } from "./call-recording/call-recording.pipe";
import { EvePortalCoreUrlPipe } from "./eve-portal-core-url/eve-portal-core-url.pipe";
import { SortByPipe } from "./sort-by/sort-by.pipe";
import { AuthenticationPipe } from "./authentication/authentication.pipe";
import { SipTrunkPresentationPipe } from './sip-trunk-presentation/sip-trunk-presentation.pipe';

@NgModule({
  exports: [
    CallRecordingPipe,
    SortByPipe,
    EvePortalCoreUrlPipe
  ],
  declarations: [
    CallRecordingPipe,
    SortByPipe,
    EvePortalCoreUrlPipe,
    AuthenticationPipe,
    SipTrunkPresentationPipe
  ],
  imports: [

  ],
  providers: [
    EvePortalCoreUrlPipe
  ]
})
export class PipesModule { }
