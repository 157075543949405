<div mat-dialog-title class="eve-container-header">
  <div class="eve-container-header-icon">
    <i class="{{options.icon}}"></i>
  </div>


  <div class="eve-container-header-title">{{options.title}}</div>
</div>

<mat-dialog-content class="eve-container-body">
  {{options.message}}
</mat-dialog-content>
<mat-dialog-actions class="clearfix">
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Okay</button>
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
