import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'eve-invalidation',
  templateUrl: './eve-invalidation.component.html',
  styleUrls: ['./eve-invalidation.component.css']
})
export class EveInvalidationComponent {


  @Input()
  invalidationMessage!:String

}
