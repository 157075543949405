import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { map, startWith, switchMap, tap } from "rxjs/operators";
import { PromptManager_GetPromptsGQL, GetPromptsQuery, Prompt } from "../../../generated/graphql";
import {faPause, faPlay, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import { getCompanyId } from '../eve-number-autocomplete/eve-number-autocomplete.component';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `
<div mat-dialog-title class="eve-container-header">
  <div class="eve-container-header-icon">
    <i class="fa fa-play-circle"></i>
  </div>
  <div class="eve-container-header-title">Prompt Manager</div>
</div>

<eve-prompt-manager [organisationNode]="data.organisationNode"></eve-prompt-manager>
`,

  styleUrls: ['./eve-prompt-manager-dialog.component.css']
}) 
export class EvePromptManagerDailogComponent {



  constructor(
    public dialogRef: MatDialogRef<EvePromptManagerDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EvePromptManagerDailogData,
  ) {


  }

 
}


interface EvePromptManagerDailogData {

  organisationNode:string
}
