import { Component, OnInit, ViewChild } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, map } from 'rxjs';
import { GetNumbersQuery,  GetNumbersGQL } from '../../../generated/graphql';
import { AppService } from 'src/app/services/app.service';
import { EveSipTrunkNumberService } from './eve-sip-trunk-number.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eve-sip-trunk-number-modal',
  templateUrl: './eve-sip-trunk-number-modal.component.html',
  styleUrls: ['./eve-sip-trunk-number-modal.component.css']
})
export class EveSipTrunkNumberModalComponent implements OnInit {
  
  sipTrunkAvailableNumbers$!: Observable<any[]>|undefined;
  sipTrunkSelectedNumbers: any[] = this.sipTrunkNumberService.sipTrunkSelectedNumbers;
  
  originalNumbers = [... this.sipTrunkNumberService.sipTrunkSelectedNumbers];

  constructor(
    public dialogRef: MatDialogRef<EveSipTrunkNumberModalComponent>,
    private appService: AppService,
    private sipTrunkNumberService: EveSipTrunkNumberService,
    public getNumbersGQL: GetNumbersGQL,) { }

  ngOnInit() {
    this.sipTrunkAvailableNumbers$= this.getNumbersGQL.fetch({
      organisationId: this.appService.organisationId,
      inUse:false,
      number: '',
      take: 50,
      skip:0,
      and:[
        {
          numberTypeId:{
            eq: 1
          }
        }
      ]
    },{fetchPolicy:'network-only'})
    .pipe(
      map((response: ApolloQueryResult<GetNumbersQuery>) => {
        const page = response.data.numbers?.items ?? [];
        const results = page
          .filter(x => { 
             return this.sipTrunkSelectedNumbers.find(n => n.numberId == x.numberId) == null;
          })
          .map(x => {
            return {
              numberId: x.numberId,
              number: x.number,
              disabled: false
            };
          });
        

        return results;
      })
    );
  }

  updateNumberList = ()=>{
    this.dialogRef.close({cancelled: false});
  }
  onCancel = () => {
    this.dialogRef.close({cancelled: true});
  }
}
