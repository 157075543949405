



<eve-container title="Set Skill" icon="fa fa-list">

  <div class="clearfix">
    <div [sortablejs]="skillRating.unallocated" [sortablejsOptions]="unallocatedOptions" [style.border-left]="'none'" style="display: flex; flex-wrap: wrap;" >
      <eve-user-carousel-item  *ngFor="let eveUser of skillRating.unallocated" [eveUser]="eveUser">
      </eve-user-carousel-item>
    </div>
  </div>


  <ng-template #controls let-eveUsers="eveUsers" let-skillRating="skillRating">

    <div class="rating-column">
      <div class="portlet-star-header"> 

        <i class="fa fa-star " aria-hidden="true" *ngIf="skillRating <= 1"></i>
        <i class="fa fa-star " aria-hidden="true" *ngIf="skillRating <= 2"></i>
        <i class="fa fa-star " aria-hidden="true" *ngIf="skillRating <= 3"></i>
        <i class="fa fa-star " aria-hidden="true" *ngIf="skillRating <= 4"></i>
        <i class="fa fa-star " aria-hidden="true" *ngIf="skillRating <= 5"></i>
        <i class="fa fa-star star-fade" aria-hidden="true" *ngIf="skillRating > 1"></i>
        <i class="fa fa-star star-fade" aria-hidden="true" *ngIf="skillRating > 2"></i> 
        <i class="fa fa-star star-fade" aria-hidden="true" *ngIf="skillRating > 3"></i>
        <i class="fa fa-star star-fade" aria-hidden="true" *ngIf="skillRating > 4"></i>
        <i class="fa fa-star star-fade" aria-hidden="true" *ngIf="skillRating > 5"></i>

        
      </div>
      <div [sortablejs]="eveUsers" [sortablejsOptions]="skillListOptions" class="users-list" [style.border-left]="'none'" >
        <eve-user-carousel-item  *ngFor="let eveUser of eveUsers" [eveUser]="eveUser">
        </eve-user-carousel-item>
      </div>

    </div>
  </ng-template>

  <ng-container [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{ eveUsers: skillRating.skillRating5, skillRating:5}">
  </ng-container>


  <ng-container [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{ eveUsers: skillRating.skillRating4, skillRating:4}">
  </ng-container>

  <ng-container [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{ eveUsers: skillRating.skillRating3, skillRating:3}">
  </ng-container>

  <ng-container [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{ eveUsers: skillRating.skillRating2, skillRating:2}">
  </ng-container>

  <ng-container [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{ eveUsers: skillRating.skillRating1, skillRating:1}">
  </ng-container>

</eve-container>
