import {Injectable} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CallQueue, CallRecording, DistributionType, EveUser, GetCallQueueIdByQueueNameGQL, Number } from 'src/generated/graphql';
import { callQueueNameUniqueValidator } from './eve-call-queue-details-async-validator';
import { BasicValidation, BasicValidations } from '../../../../helpers/validations/basic-validation';
import { validate } from 'graphql';
import { getCompanyNode } from '../../../../components/eve-prompt/eve-prompt.component';

@Injectable({providedIn: 'root'})
export class EveCallQueueDetailsValidationService {

    constructor(private fb : FormBuilder, private getCallQueueIdByQueueNameGQL : GetCallQueueIdByQueueNameGQL) {}

  public generateValidationForm(callQueue: CallQueue): FormGroup<CallQueueDetailsFormGroup> {
    const formGroup = this.fb.group<CallQueueDetailsFormGroup>({
        isLight: this.fb.control(callQueue.isLight),
        avatarImageId: this.fb.control(callQueue.avatarImageId),
      name: this.fb.control(callQueue.name, [Validators.required, Validators.maxLength(40)], [callQueueNameUniqueValidator(this.getCallQueueIdByQueueNameGQL, callQueue.callQueueId, getCompanyNode(callQueue.organisationNode))]),
        description: this.fb.control(callQueue.description, [Validators.maxLength(40)]),
        number: this.fb.control(callQueue.number, [Validators.required]),

        callRecording: this.fb.control(callQueue.callRecording, [Validators.required]),

        distributionType: this.fb.control(callQueue.distributionType, [Validators.required]),
        queueSize: this.fb.control(callQueue.queueSize, [Validators.required, BasicValidations.number(), Validators.min(5), Validators.max(50)]),
        users: this.fb.control(callQueue.users.map(u => u.eveUser))


      });

     
      return formGroup;
  }

}



export interface CallQueueDetailsFormGroup {
  isLight: FormControl<boolean | null | undefined>;
  avatarImageId: FormControl<number | null | undefined>;
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  number: FormControl<Number | null>;
  callRecording: FormControl<CallRecording | null>;
  distributionType: FormControl<DistributionType | null>;
  queueSize: FormControl<number | null>;
  users: FormControl<EveUser[] | null>;
}
