Internal number ranges



<div class="eve-list-row" *ngFor="let internalNumberRange of internalNumberRanges">
  from  <input pInputText />
  To  <input pInputText />
</div>


<div style="text-align: center;">
  <button mat-fab color="primary" class="icon-button-large" (click)="onAddClick()">
    <mat-icon>add</mat-icon>
  </button>
  <br />
  Add new range
</div>

