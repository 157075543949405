<p-autoComplete 
    [(ngModel)]="scheduleAction"
    (ngModelChange)="onNgModelChange($event)"
    [showEmptyMessage]="true" 
    [suggestions]="filteredScheduleActions" 
    (completeMethod)="filterScheduleAction($event)" 
    field="name" 
    [dropdown]="true"
    [minLength]="1"
    [showClear]="value != null"
    (onClear)="onNgModelChange(null)"
    placeholder="Action Type"
    styleClass="w-full">
</p-autoComplete>

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>
