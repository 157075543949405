import { HttpClient, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Directive, ElementRef, Input } from '@angular/core';
import { Host, Self, Optional } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import {  distinctUntilChanged, map, of, ReplaySubject, switchMap, tap } from 'rxjs';


const cache: Map<string, String> = new Map<string, String>();


@Directive({
  selector: 'img'
})
export class ImageDirective {

  @Input("src")
  set src(src: string) {
    this.srcSubject.next(src);
  }

  srcSubject: ReplaySubject<string> = new ReplaySubject(1);

  constructor(
    httpClient: HttpClient,
    @Host() @Self() @Optional() public imgElementRef: ElementRef) {
    // Now you can access specific instance members of host directive
    


    this.srcSubject.pipe(
      distinctUntilChanged(),
      switchMap(src => {

        if (cache.has(src)) return of(cache.get(src));

        return httpClient.get(src, { responseType: 'blob', observe: 'response' }).pipe(
          map(response => URL.createObjectURL(response?.body ?? new Blob())),
          tap(response => cache.set(src, response))
        );
      })
    ).subscribe(string => {
      imgElementRef.nativeElement.src = string;
    });
  }
}

