import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EveSipTrunkDetailsValidationService } from '../details/eve-sip-trunk-details-validation.service';
import { IEveSipTrunkDetails } from '../interfaces/i-eve-sip-trunk-details';
import { AbstractControl, FormGroup } from '@angular/forms';
import { firstValueFrom, combineLatest } from 'rxjs';

import { AppService } from 'src/app/services/app.service';
import { GetOrganisationGQL, CallRecording, CreateSipTrunkGQL, GetUserNamePasswordGQL } from '../../../../generated/graphql';
import { IEveSipTrunkNumberInput } from '../interfaces/i-eve-sip-trunk-number-input';

@Component({
  selector: 'app-eve-create-sip-trunk-page',
  templateUrl: './eve-create-sip-trunk-page.component.html',
  styleUrls: ['./eve-create-sip-trunk-page.component.css']
})
export class EveCreateSipTrunkPageComponent implements OnInit {
  public sipTrunkDetails!: IEveSipTrunkDetails;
  public sipTrunkDetailsForm!: FormGroup;
  
  constructor(private route: ActivatedRoute, 
    private sipTrunkDetailsValidationService: EveSipTrunkDetailsValidationService,
    private createSipTrunkGQL: CreateSipTrunkGQL,
    private getOrganisationGQL: GetOrganisationGQL,
    private getUserNamePasswordGQL: GetUserNamePasswordGQL,
    private appService: AppService
    ) { 
    var snapShot = this.route.snapshot.queryParams["id__organisationId"];
    var organisationId = parseInt(snapShot, 16) - 56747;

    combineLatest([
        this.getOrganisationGQL.fetch({organisationId: organisationId}),
        this.getUserNamePasswordGQL.fetch()
      ]).subscribe({
        next: ([response, authResponse]: [any, any]) => {
          this.appService.organisationNode = response.data.organisation.organisationNode;
    
          if(snapShot !== null)
          {
            this.sipTrunkDetails = {
              // sipTrunkId: null,                 
              organisationNode: this.appService.organisationNode,
              organisationId: this.appService.organisationId,         
              inboundTranslation: 0,         
              number: {numberId: 0},
              authenticationUsername: authResponse.data.userNamePassword.key,
              authenticationPassword: authResponse.data.userNamePassword.value,
              busyOnChannelsExceeded: false,
              channels: 0,
              inboundChannelsLimit: 0,
              outboundChannelsLimit: 0,
              callRecording: CallRecording.None,


              barringLocal: false,
              barringMobile: false,

              barringFreephone: false,
              barring084: false,
              barringDirectory: false,
              barringPremium: false,
              barringInternational: false,

              sipTrunkNumbers: []
            } as any;    
          } 
    }});
  }

  //  toHex = (d: any): string =>{
  //   return  Number(d).toString(16).toUpperCase()
  // }
  ngOnInit() {
    this.sipTrunkDetailsForm = this.sipTrunkDetailsValidationService.generateValidationForm();
  }

  onCancelClick = () => {
    location.href = '/Search/groups'
  }

  onSaveButtonClick = async (formGroup: AbstractControl<any,any> | null) => {
    try {
      if (formGroup != null) {
        this.markAllAsTouched(formGroup as FormGroup);
        formGroup?.updateValueAndValidity();
      }

      const inboundTranslation = this.sipTrunkDetails.inboundTranslation
      delete inboundTranslation.__typename;

      const sipTrunkNumberInputs : IEveSipTrunkNumberInput[] =
      this.sipTrunkDetails.sipTrunkNumbers.map(item=>{
        return {
          numberId: item.numberId,
          failoverNumber: item.failoverNumber,
          divertNumber: item.divertNumber,
          isDiverted: item.isDiverted
        }
      }) as any[]

      const sipTrunkInput : any = {
        sipTrunkId: this.sipTrunkDetails.sipTrunkId,
        organisationNode: this.sipTrunkDetails.organisationNode,
        organisationId: this.sipTrunkDetails.organisationId,
        name: this.sipTrunkDetails.name,
        number: { numberId: this.sipTrunkDetails.number.numberId},
        ipAddress: this.sipTrunkDetails.ipAddress,
        authenticationType: this.sipTrunkDetails.authenticationType,
        authenticationUsername: this.sipTrunkDetails.authenticationUsername,

        authenticationPassword: this.sipTrunkDetails.authenticationPassword,
        inboundTranslation: this.sipTrunkDetails.inboundTranslation,
        sipTrunkPresentation: this.sipTrunkDetails.sipTrunkPresentation,
        channels: this.sipTrunkDetails.channels,
        inboundChannelsLimit: this.sipTrunkDetails.inboundChannelsLimit,

        outboundChannelsLimit: this.sipTrunkDetails.outboundChannelsLimit,
        callRecording: this.sipTrunkDetails.callRecording,
        busyOnChannelsExceeded: this.sipTrunkDetails.busyOnChannelsExceeded,
        barringLocal: this.sipTrunkDetails.barringLocal,
        barringMobile: this.sipTrunkDetails.barringMobile,

        barringFreephone: this.sipTrunkDetails.barringFreephone,
        barring084: this.sipTrunkDetails.barring084,
        barringDirectory: this.sipTrunkDetails.barringDirectory,
        barringPremium: this.sipTrunkDetails.barringPremium,
        barringInternational: this.sipTrunkDetails.barringInternational,

        addSipTrunkNumbers: sipTrunkNumberInputs,
      }

      if (formGroup?.invalid == false){
        await firstValueFrom(this.createSipTrunkGQL.mutate({sipTrunk: sipTrunkInput}));
      }
    } catch (error) {
      alert("Failed - " + JSON.stringify(error));
    }
  }
   
  public markAllAsTouched(formGroup: FormGroup): void {
    if (formGroup.controls == null) return;
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }

}
