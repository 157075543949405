import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EveSipTrunkPageComponent } from './eve-sip-trunk-page.component';
import { EveCreateSipTrunkPageComponent } from './create/eve-create-sip-trunk-page.component';

const routes: Routes = [{
  path:'SipTrunk/SipTrunking',
  component: EveSipTrunkPageComponent
},
{
  path:'SipTrunk/SipTrunking/Create',
  component: EveCreateSipTrunkPageComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EveSipTrunkPageRoutingModule { }
