import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EveSipTrunkPageRoutingModule } from './eve-sip-trunk-page-routing.module';
import { EveSipTrunkPageComponent } from './eve-sip-trunk-page.component';
import { EveSipTrunkDetailsComponent } from './details/eve-sip-trunk-details.component';
import { EveComponentsModule } from '../../components/eve-components.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectivesModule } from '../../directives/directives.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes/pipes.module';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EveCreateSipTrunkPageComponent } from './create/eve-create-sip-trunk-page.component';

@NgModule({
  declarations: [
    EveSipTrunkPageComponent,
    EveCreateSipTrunkPageComponent,
    EveSipTrunkDetailsComponent
  ],
  imports: [
    CommonModule,
    EveSipTrunkPageRoutingModule,
    EveComponentsModule,
    MatGridListModule,
    MatCardModule,
    FontAwesomeModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,

    PipesModule,
    
    InputTextModule,
    AutoCompleteModule,
  ]
})
export class EveSlipTrunkPageModule { }
