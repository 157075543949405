import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {  ScheduleAction } from '../../../generated/graphql';

@Component({
  selector: 'eve-schedule-action-types-autocomplete',
  templateUrl: './eve-schedule-action-types-autocomplete.component.html',
  styleUrls: ['./eve-schedule-action-types-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveScheduleActionTypesAutocompleteComponent),
      multi: true
    }
  ]
})
export class EveScheduleActionTypesAutocompleteComponent implements OnInit, ControlValueAccessor {

  

  @Input() placeholder : string = '';
  @Input() name : string = '';
  

  @Input() value: ScheduleAction | null = null;
  @Output() valueChange: EventEmitter<ScheduleAction | null> = new EventEmitter<ScheduleAction | null>();

 
  

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;


  scheduleAction: any | null = null;
  scheduleActions: any[] = [
    { name: "Open", value: ScheduleAction.Open },
    { name: "Closed", value: ScheduleAction.Closed },
    { name: "Forward", value: ScheduleAction.Forward }
  ];


  filteredScheduleActions!: any[]
  constructor() { }

  ngOnInit(): void {
    this.scheduleAction = this.scheduleActions.find(s => s.value == this.value);
  }
  
  filterScheduleAction = (event: any) => {

    let filtered : any[] = [];
    let query = event.query;

    for (let i = 0; i < this.scheduleActions.length; i++) {
      let item = this.scheduleActions[i];
        if (item.value.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(item);
        }
    }

    this.filteredScheduleActions = filtered;

  }

  onChange: any = () => { }
  onTouch: any = () => { }



  onNgModelChange(prompt: any) {

    this.scheduleAction = prompt;
    this.value = prompt?.value;
    this.valueChange.emit(this.value);

    this.onChange(this.value);
    this.onTouch(this.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.value = value;
    this.scheduleAction = this.scheduleActions.find(s => s.value == this.value);
  }

}
