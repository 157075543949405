import { Component, Input } from '@angular/core';

@Component({
  selector: 'eve-button',
  template: `
<button [class]="buttonStyle">
  <ng-content></ng-content>
</button>
`,
  styleUrls: ['./eve-button.component.css']
})

export class EveButtonComponent {
  @Input() Disabled: boolean = false;
  buttonStyle = 'eve-button ';
}


