<ng-container [formGroup]="callQueue">

  <eve-container title="Message Settings" icon="fa fa-phone-square">


    <div class="container">
      <div class="row input-group-row  " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Placed in Queue Message
        </div>
        <div class="col-5">
          <eve-prompt name="Placed in Queue Message" formControlName="placedInQueueMessagePrompt" [organisationNode]="organisationNode">
          </eve-prompt>

        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Progress Message
        </div>
        <div class="col-5">
          <eve-prompt name="Progress Message" formControlName="progressMessagePrompt" [organisationNode]="organisationNode">
          </eve-prompt>
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Progress Repeat
        </div>
        <div class="col-4">
          <eve-slider name="Progress Repeat" [min]=1 [max]=60 formControlName="progressRepeat" [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Seconds
        </div>
      </div>

      <div class="row input-group-row  " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Queuing Message
        </div>
        <div class="col-5">
          <eve-prompt name="Queuing Message" formControlName="queueingMessagePrompt" [organisationNode]="organisationNode">
          </eve-prompt>
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Play Ring Calling Agent
        </div>
        <div class="col-4">
          <eve-toggle name="Play Ring Calling Agent" formControlName="playRingCallingAgent"></eve-toggle>
        </div>
      </div>

      <div class="row input-group-row  " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Play Queue Position
        </div>
        <div class="col-2">
          <eve-toggle name="Play Queue Position" formControlName="playQueuePosition"></eve-toggle>
        </div>

        <div class="label">
          Play Estimated Time
        </div>
        <div class="col-2">
          <eve-toggle name="Play Estimated Time" formControlName="playEstimatedTime"></eve-toggle>
        </div>
      </div>



      <div class="row input-group-row  " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Alternate
        </div>
        <div class="col-4"><eve-toggle name="Alternate" formControlName="alternateQueueInfo"></eve-toggle>
        </div>
      </div>
    </div>




  </eve-container>

  <eve-container title='Wallboard Settings' icon="fa fa-list" *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
    <div class="container">
      <div class="row input-group-row  ">
        <div class="label">
          Statistics Period
        </div>
        <div class="col-4">
          <eve-slider name="Statistics Period" formControlName="statisticsPeriod" [min]=1 [max]=24 [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Hours
        </div>
      </div>


      <div class="row input-group-row  ">
        <div class="label">
          24hr Statistics Fixed
        </div>
        <div class="col-5">
          <eve-toggle name="24hr Statistics Fixed" formControlName="_24hrStatisticsFixed">
          </eve-toggle>
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          24hr Statistics Reset Time
        </div>
        <div class="col-5">
          <eve-time-picker name="24hr Statistics Reset Time" formControlName="_24hrStatisticsResetTime">
          </eve-time-picker>
        </div>
      </div>

    </div>
  </eve-container>

  <eve-container title="Callback Settings" icon="fa fa-phone-square" *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">


    <div class="container">
      <div class="row input-group-row  ">
        <div class="label">
          Callback Requeue
        </div>
        <div class="col-4">
          <eve-slider name="Callback Requeue" formControlName="callbackRequeue" [min]=0 [max]=120 [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Seconds
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Callback Attempts
        </div>
        <div class="col-4">
          <eve-slider name="Callback Attempts" formControlName="callbackAttempts" [min]=0 [max]=10 [step]="1">
          </eve-slider>
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Callback Validity
        </div>
        <div class="col-4">
          <eve-slider name="Callback Validity" formControlName="callbackValidity" [min]=0 [max]=240 [step]="1">
          </eve-slider>
        </div>
      </div>

      <div class="row input-group-row  ">
        <div class="label">
          Callback Message
        </div>
        <div class="col-5">
          <eve-prompt name="Callback Message" formControlName="callbackMessagePrompt" [organisationNode]="organisationNode">
          </eve-prompt>
        </div>
      </div>
    </div>

     
  </eve-container>

</ng-container>
