import {Directive, Input} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import moment from "moment";


@Directive({
  selector: '[lessThan]',
  providers: [{provide: NG_VALIDATORS, useExisting: LessThanDirective, multi: true}]
})
export class LessThanDirective implements Validator {
  @Input('lessThan') lessThan: any = '';
  @Input('lessThan-DataType') dataType: string = '';

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {

    var controlValue = control.value;
    var displayControlValue = controlValue;

    var comparisonValue = this.lessThan;
    var displayComparisonValue = comparisonValue;

    if (controlValue == null || comparisonValue == null) return null;


    if (this.dataType == "timespan") {
      var controlValueD = moment.duration(controlValue);
      controlValue = controlValueD.asSeconds();
      displayControlValue = controlValueD.hours() + ":" + controlValueD.minutes();


      var comparisonValueD = moment.duration(comparisonValue);
      comparisonValue = comparisonValueD.asSeconds();
      displayComparisonValue = comparisonValueD.hours() + ":" + comparisonValueD.minutes();
    }


    if (controlValue < comparisonValue) {
      return null;
    }

    return { lessThan: { value: displayControlValue, lessThan: displayComparisonValue } }
  }
}
