<p-pickList [source]="sipTrunkAvailableNumbers$ | async" [target]="sipTrunkSelectedNumbers" sourceHeader="Available" targetHeader="Selected" [dragdrop]="true"
    [responsive]="true" [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}" filterBy="number"
    sourceFilterPlaceholder="Search by number" targetFilterPlaceholder="Search by number">
    <ng-template let-option pTemplate="item">
        <div [ngStyle]="option.disabled ? {'color': '#ccc', 'cursor': 'default'} : ''">   
            <h5 class="mb-2">{{option.number}}</h5>        
        </div>
    </ng-template>
</p-pickList>

<div style="text-align: center; margin-top: 10px;">
    <button class="evebutton" (click)="onCancel()" >Cancel</button>
    <button class="evebutton" (click)="updateNumberList()" >update</button>
</div>



