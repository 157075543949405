<span *ngIf="invalidationMessage" class="tooltip-error field-validation-error">

  {{invalidationMessage}}
  <!-- <span *ngIf="value.errors?.['required']">
    {{ value.name }} required
  </span>

  <span *ngIf="value.errors?.['maxlength']">
    Max length {{value.errors?.['maxlength'].requiredLength}} characters
  </span>

  <span *ngIf="value.errors?.['greaterThanOrEqual']">
    Must be greater than or equal to {{value.errors?.['greaterThanOrEqual'].value}}
  </span>

  <span *ngIf="value.errors?.['greaterThan']">
    Must be greater than {{value.errors?.['greaterThan'].value}}
  </span> -->
</span>