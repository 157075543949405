import {Directive, Input} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import moment from "moment";


@Directive({
  selector: '[greaterThanOrEqual]',
  providers: [{provide: NG_VALIDATORS, useExisting: GreaterThanOrEqualDirective, multi: true}]
})
export class GreaterThanOrEqualDirective implements Validator {
  @Input('greaterThanOrEqual') greaterThanOrEqual:any = '';
  @Input('greaterThanOrEqual-DataType') dataType: string = '';

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {

    var controlValue = control.value;
    var displayControlValue = controlValue;

    var comparisonValue = this.greaterThanOrEqual;
    var displayComparisonValue = comparisonValue;

    if (controlValue == null || comparisonValue == null) return null;


    if (this.dataType == "timespan") {
      var controlValueD = moment.duration(controlValue);
      controlValue = controlValueD.asSeconds();
      displayControlValue = controlValueD.hours() + ":" + controlValueD.minutes();


      var comparisonValueD = moment.duration(comparisonValue);
      comparisonValue = comparisonValueD.asSeconds();
      displayComparisonValue = comparisonValueD.hours() + ":" + comparisonValueD.minutes();
    }


    if (controlValue >= comparisonValue) {
      return null;
    }

    return { greaterThanOrEqual: { value: displayControlValue, greaterThanOrEqual: displayComparisonValue } }
  }
}
