import { ChangeDetectorRef, Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EveSipTrunkNumberModalComponent } from '../eve-sip-trunk-number-modal/eve-sip-trunk-number-modal.component';
import { EveSipTrunkNumberService } from '../eve-sip-trunk-number-modal/eve-sip-trunk-number.service';
import { firstValueFrom, filter } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { EveSipTrunkDetailsValidationService } from '../../pages/sip-trunk/details/eve-sip-trunk-details-validation.service';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'eve-sip-trunk-number-manager',
  templateUrl: './eve-sip-trunk-number-manager.component.html',
  styleUrls: ['./eve-sip-trunk-number-manager.component.css']
})
export class EveSipTrunkNumberManagerComponent
{
  private _sipTrunkNumbers: any[] = []
  @Input() set sipTrunkNumbers(value: any){

    this._sipTrunkNumbers = value;
    this.filteredSipTrunkNumbers = value
    this.dataSource.data = this.filteredSipTrunkNumbers;

    this.onCommoanFilterSipTrunkNumbersArray();
    // this.filteredSipTrunkNumbers.forEach((x: any) => this.sipTrunkNumbersArray.push(this.sipTrunkValidationService.buildSipTrunkNumberFormGroup(x.number)))

  };
  get sipTrunkNumbers(): any[]{
    return this._sipTrunkNumbers;
  }

  @Input() sipTrunkId: any = {};
  displayedColumns: string[] = ['number', 'failoverNumber', 'divertNumber', 'isDiverted'];
  constructor(
    public dialog: MatDialog, 
    private sipTrunkNumberService: EveSipTrunkNumberService,
    private sipTrunkValidationService: EveSipTrunkDetailsValidationService,
    private cdr: ChangeDetectorRef
     ) {
     }
  dataSource = new MatTableDataSource<any>([]);
  commonFailoverNumber: string = '';
  commonDivertNumber: string = '';
  commonIsDiverted: string = '';

  searchNumber: string = '';
  filteredSipTrunkNumbers: any[] = [];

  sipTrunkNumberGroup = this.sipTrunkValidationService.sipTrunkNumberGroup;
  sipTrunkNumberArray = this.sipTrunkValidationService.sipTrunkNumberFormArray;
  sipTrunkNumbersArrayControls = this.sipTrunkValidationService.sipTrunkNumberFormArray.controls;
  filteredSipTrunkNumbersArrayControls:AbstractControl<any,any>[] = this.sipTrunkValidationService.generateArray().controls;
  
  onPlusAvailableNumbers = async (event: any) => {
    let originalNumbers = [...this.sipTrunkNumberService.sipTrunkSelectedNumbers]
    const dialogRef = this.dialog.open(EveSipTrunkNumberModalComponent, {
      width: '700px',
      height: 'auto', 
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
  
    if(result.cancelled === true){
     this.sipTrunkNumberService.sipTrunkSelectedNumbers = originalNumbers;
    }else{
     let currentNumbers = this.sipTrunkNumberService.sipTrunkSelectedNumbers;
     let newNumbers = currentNumbers.filter(item => originalNumbers.indexOf(item)< 0);
     let deletedNumbers = originalNumbers.filter(item => currentNumbers.indexOf(item) < 0);
     const sipTrunkNumbersArrayNumberIds = this.sipTrunkNumbersArrayControls.map(x=>x.get('numberId')?.value);
     deletedNumbers.forEach(item=>{
      const index = this.sipTrunkNumbers.map(x=>x.numberId).indexOf(item.numberId,0);
      if(index > -1){  
        this.sipTrunkNumbers.splice(index,1)
        console.log('dataSource: ', JSON.stringify(this.sipTrunkNumbers))  
      }

      const indexArray = sipTrunkNumbersArrayNumberIds.indexOf(item.numberId,0);
      if(indexArray > -1){  
        this.sipTrunkNumbersArrayControls.splice(indexArray,1) 
      }
     });

     newNumbers.forEach(item => {
      this._sipTrunkNumbers.push({
        sipTrunkId: this.sipTrunkId,
        numberId: item.numberId,
        number: item.number,
        failoverNumber: null,
        divertNumber: null,
        isDiverted: false
      });

      this.sipTrunkNumberArray.push(this.sipTrunkValidationService.buildSipTrunkNumberFormGroup(item.numberId, item.number));
     });


     if(deletedNumbers.length > 0 || newNumbers.length > 0){
      this.sipTrunkNumberGroup.markAsDirty();
      this.onCommonFilterSipTrunkNumber();
     }
    }
  }
  onCommonFailoverNumberKeyPress = (event: any) => {
    console.log(event);
    if(event.code === 'Enter'){
      this.filteredSipTrunkNumbers.forEach(item => item.failoverNumber = this.commonFailoverNumber)
      this.sipTrunkNumberGroup.markAsDirty();
    }
  }

  onClearCommonFailoverNumberClick = ()=>{
    this.filteredSipTrunkNumbers.forEach(item => item.failoverNumber = null);
    this.sipTrunkNumberGroup.markAsDirty();
  }

  onCommonDivertNumberKeyPress = (event: any) => {
    console.log(event);
    if(event.code === 'Enter'){
      this.filteredSipTrunkNumbers.forEach(item => item.divertNumber = this.commonDivertNumber);
      this.sipTrunkNumberGroup.markAsDirty();
    }
  }

  onClearDivertNumberClick = ()=>{
    this.filteredSipTrunkNumbers.forEach(item => item.divertNumber = null);
    this.sipTrunkNumberGroup.markAsDirty();
  }

  onCommonIsDivertedChange = (event: any) => {
    this.filteredSipTrunkNumbers.forEach(item => item.isDiverted = this.commonIsDiverted);
    this.sipTrunkNumberGroup.markAsDirty();
  }

  onFilterSipTrunkNumber = (event:any) => {
    if(event.code === 'Enter'){
     this.onCommonFilterSipTrunkNumber();
    }
  }

  onSearchFilterSipTrunkNumber = (event:any) => {
    this.onCommonFilterSipTrunkNumber();
  }

  onCommonFilterSipTrunkNumber = () => {
    this.filteredSipTrunkNumbers = this.sipTrunkNumbers.filter(item=>item.number.indexOf(this.searchNumber) > -1 );
    this.dataSource.data = this.filteredSipTrunkNumbers
    this.onCommoanFilterSipTrunkNumbersArray();
  }

  onCommoanFilterSipTrunkNumbersArray = () => {
    this.filteredSipTrunkNumbersArrayControls = this.sipTrunkNumbersArrayControls.filter(item => (item as FormGroup).get('number')?.value.indexOf(this.searchNumber) > -1);
    this.cdr.detectChanges();
  }

  getSipTrunkNumberControl = (i:number, controlName:string) => {
    let control = this.filteredSipTrunkNumbersArrayControls[i].get(controlName);
    return control;
  }
}
