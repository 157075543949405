import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { first, firstValueFrom, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {



    return this.authService.Jwt.pipe(
      first(),
      switchMap(token => {

        if (!token) {
          return next.handle(request);
        }

        request = request.clone({
          headers: request.headers.set('eveportal-jwt', token),
        });

        return next.handle(request);

      })
     );
    
  }
}
