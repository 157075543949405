import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EveSipTrunkNumberService {

constructor() { }

public sipTrunkSelectedNumbers: any[] = [];

}
