import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, map } from "rxjs";

export function NameUniqueValidator(obserableFn: (name: string) => Observable<boolean>): AsyncValidatorFn {

    return(c : AbstractControl) : Promise <ValidationErrors | null> | Observable <ValidationErrors | null > => {
      return obserableFn(c.value).pipe(map(unique => {
        return unique ? null : { nameUnique: true };
      }));
        
    }
}
