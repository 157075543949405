import {
    AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  startWith,
  switchMap,
  tap,
  debounceTime,
  map
} from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';


import  'core-js/full/object/group-by';

export interface EveCarouselSearchResult<TData> {

  items: TData[],
  totalCount: number
} 


@Component({
  selector: 'eve-carousel',
  templateUrl: './eve-carousel.component.html',
  styleUrls: ['./eve-carousel.component.css']
})
export class EveCarousel<TData> implements AfterViewInit {


  @Input() searchFunc !: (search: string, pageSize: number, page: number) => Observable<EveCarouselSearchResult<TData>>;
  @Input() title !: string;



  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  search: BehaviorSubject<string> = new BehaviorSubject<string>('');
  page !: Observable<any>;
  totalCount?: number;



  @ContentChild('itemTemplate') itemTemplate!: TemplateRef<any>;
   
  


  ngAfterViewInit(): void { 
    setTimeout(() => {
      this.page = combineLatest(
        this.search.pipe(debounceTime(300), tap(() => this.paginator.pageIndex = 0)),
        this.paginator.page.pipe(startWith(null))
      ).pipe(
        switchMap(([search]) => {
          return this.searchFunc(search.trim(), this.paginator.pageSize, this.paginator.pageIndex);
        }),
        tap(searchResult => this.totalCount = searchResult?.totalCount),
        map(page => {
          const columns = (Object as any).groupBy(page.items, (e:TData, i: number) => Math.floor(i /2));
          return columns;
        })
      );

    });

  }

  onSearchChange(event: any) {
    this.search.next(event.currentTarget.value);
  }
}
