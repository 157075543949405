import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class EveExternalRangeValidationService {

    private _formGroup !: FormGroup;
    constructor(private fb : FormBuilder) {}

    public get externalRangeFormGroup(): FormGroup {
        return this._formGroup;
    }
    public generateValidationForm(): FormGroup {
        const formGroup = this.fb.group({
            area: [
                null,
                [Validators.required]
            ],
            displayStandardNumber: true,
            displayGoldenNuber: true,
            selectedNumbers: [
                null,
                [Validators.required]
            ]
        })

        this._formGroup = formGroup;
        return formGroup;
    }

}
