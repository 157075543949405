<div class="container">
  <div class="row">
    <div class="col-7 groupvoicemail_search"></div>
      <div class="col-3 groupvoicemail_search">
          <div class="p-inputgroup">
              <button type="button" pButton class="p-element p-ripple p-button p-component p-button-icon-only" (click)="onSearchFilterSipTrunkNumber($event)">
                  <i class="fa fa-search"></i>
                </button>
              <input type="text" pinputtext="" placeholder="Keyword" class="p-inputtext p-component p-element" [(ngModel)]="searchNumber" (keypress)="onFilterSipTrunkNumber($event)">
          </div>
      </div>
      <div class="col-2 groupvoicemail_new">
          <button type="button" pButton class="p-element p-ripple p-button p-component"(click)="onPlusAvailableNumbers($event)">
              <i class="fa fa-search"></i>
              assign ddis
          </button>
      </div>
  </div>
  <ng-template #controls>
    <div class="row" style="text-align: center;">
        <div style="display:inline-block;">
            <button class="evebutton"><< </button>
            <button class="evebutton"><</button>
            <button class="evebutton">></button>
            <button class="evebutton">>></button>
        </div>
    </div>
</ng-template>
<ng-container [ngTemplateOutlet]="controls"></ng-container>
  <div class="row">
    <div class="col-12" [formGroup]="sipTrunkNumberGroup">
      <table mat-table [dataSource]="dataSource" style="width: 100%" class="evetable" formArrayName="sipTrunkNumbers">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row;  columns: displayedColumns;"></tr>
    
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> DDI
            <div>
              <p>Apply to all</p>
            </div>
          </th>
          <td mat-cell *matCellDef="let element;let i=index" [formGroupName]="i"> 
            <label><!-- {{element.sipTrunkNumberId}} - {{element.numberId}} -  -->{{element.number}}</label> 
          </td>
        </ng-container>

        <ng-container matColumnDef="failoverNumber">
          <th mat-header-cell *matHeaderCellDef> Failover
            <div>   
              <input type="text" pinputtext="" placeholder="" 
                class="p-inputtext p-component p-element" 
                [(ngModel)]="commonFailoverNumber" [ngModelOptions]="{standalone: true}"
                (keypress)="onCommonFailoverNumberKeyPress($event)">
              <button type="button" pButton class="p-element p-ripple p-button p-component p-button-icon-only" (click)="onClearCommonFailoverNumberClick()">
                <i class="fa fa-close"></i>
              </button>
            </div> 
          </th>
          <td mat-cell *matCellDef="let element; let i=index" [formGroupName]="i"> 
            <div style="position:relative;">
              <div class="p-inputgroup">
                <input id="{{'failoverNumber'+i}}" type="text" pinputtext placeholder="" class="p-inputtext p-component p-element" 
                  [(ngModel)]="element.failoverNumber"
                  formControlName="failoverNumber">
              </div>
              <eve-validation-errors name="Failover" [validationControl]="getSipTrunkNumberControl(i, 'failoverNumber')">
                <span *ngIf="getSipTrunkNumberControl(i, 'failoverNumber')?.errors?.['pattern']">
                  Please use a valid UK number. Internal numbers are not supported.
                </span>
              </eve-validation-errors>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="divertNumber">
          <th mat-header-cell *matHeaderCellDef> Divert
            <div>
              <input type="text" pinputtext="" placeholder="" class="p-inputtext p-component p-element" 
                [(ngModel)]="commonDivertNumber" (keypress)="onCommonDivertNumberKeyPress($event)" 
                [ngModelOptions]="{standalone: true}">
              <button type="button" pButton class="p-element p-ripple p-button p-component p-button-icon-only" (click)="onClearDivertNumberClick()">
                <i class="fa fa-close"></i>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i=index"  [formGroupName]="i" > 
            <div style="position:relative;">
              <div class="p-inputgroup">
                <input id="{{'divertNumber'+i}}" type="text" pinputtext placeholder="" class="p-inputtext p-component p-element" 
                  [(ngModel)]="element.divertNumber"
                  formControlName="divertNumber">
              </div>
              <eve-validation-errors name="Divert" [validationControl]="getSipTrunkNumberControl(i, 'divertNumber')">
                <span *ngIf="getSipTrunkNumberControl(i, 'divertNumber')?.errors?.['pattern']">
                  Please use a valid UK number. Internal numbers are not supported.
                </span>

                <span *ngIf="getSipTrunkNumberControl(i, 'divertNumber')?.errors?.['divertNumberRequired']">
                  please supply divert number.
                </span>
              </eve-validation-errors>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="isDiverted">
          <th mat-header-cell *matHeaderCellDef> Divert Active
            <div>
              <eve-toggle name="isDivertedForAll" 
                [(ngModel)]="commonIsDiverted" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onCommonIsDivertedChange($event)"></eve-toggle>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i=index"  [formGroupName]="i" >
            <eve-toggle id="{{'isDiverted'+i}}" name="Divert Active"
              [(ngModel)]="element.isDiverted"
              formControlName="isDiverted"
            ></eve-toggle>
          </td>
        </ng-container>
      </table>

      <!-- <div formArrayName="sipTrunkNumbers">
        <div *ngFor="let sipTrunkNumber of sipTrunkNumbers; let i=index;" [formGroupName]="i">
          <input formControlName="failoverNumber" [(ngModel)]="sipTrunkNumber.failoverNumber">
        </div>
      </div> -->

    </div>
  </div>
  <ng-container [ngTemplateOutlet]="controls"></ng-container>
</div>