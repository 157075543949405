<div class="avatar" style="">

  <img #defaultAvatar src="{{'/assets/images/Avatar.png' | evePortalCoreUrl }}" style="position:absolute; top:0">
  <img #avatar *ngIf="eveUser.avatarImageId !== null" src="{{ '/library/' + eveUser.avatarImageId | evePortalCoreUrl  }}" style="position: absolute; top: 0;">



  <!--<span class="selected-icon">
    <span class="glyphicon glyphicon-ok">df</span>
  </span>-->
  
  <ng-container *ngTemplateOutlet="avatarExtras"></ng-container>

</div>
<p class="carousel-name">{{eveUser.firstName}} {{eveUser.surname}}</p>

<ng-content></ng-content>
