<div class="eve-container">
  <div class="eve-container-header">

    <div class="eve-container-header-icon">
      <i class="{{icon}}"></i>
    </div>

    <div class="eve-container-header-title">{{title}}</div>

    <div class="eve-minimizer">
      <button mat-icon-button type="button" class="eve-minimizer-expand" *ngIf="collapsed" (click)="expandPanel()">
        <i class="fa fa-chevron-down"></i>
      </button>
      <button mat-icon-button type="button" class="eve-minimizer-collapse" *ngIf="!collapsed" (click)="collapsePanel()">
        <i class="fa fa-chevron-up"></i>
      </button>

    </div>

  </div>


    <div class="eve-container-body " [@collapseEveContainer]="collapsed" >

      <ng-content></ng-content>
    </div>



</div>

