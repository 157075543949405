<div #scrollTo *ngIf="sipTrunkDetails !== null">
  <ng-container>
      <eve-sip-trunk-details [sipTrunkDetails]="sipTrunkDetails"
          [sipTrunkDetailsForm]="sipTrunkDetailsForm"></eve-sip-trunk-details>
  </ng-container>

  <div class="right-align">
      <button class="evebutton" (click)="onCancelClick()">Cancel</button>
      <button class="evebutton" (click)="onSaveButtonClick(sipTrunkDetailsForm)">Save</button>
  </div>
</div>