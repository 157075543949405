import { Pipe, PipeTransform } from '@angular/core';
import { SipTrunkPresentationType } from '../../../generated/graphql';

@Pipe({
  name: 'sipTrunkPresentation'
})
export class SipTrunkPresentationPipe implements PipeTransform {

  transform(value: SipTrunkPresentationType): string {
    if(value == SipTrunkPresentationType.Anonymous) return "Anonymous";
    if(value == SipTrunkPresentationType.Passthrough) return "Passthrough";
    if(value == SipTrunkPresentationType.UserDefined) return "User Defined";

    return '';
  }

}
