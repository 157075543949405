import {Injectable} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BankHoliday, BankHolidayInput, CallQueue, CallQueueInput, CallQueueUser, Destination, DestinationInput, DistributionType, EveUser, EveUserInput, Number, NumberInput, Prompt, PromptSelectionInput, Schedule, ScheduleAction, ScheduleInput } from '../../../generated/graphql';
import { CallQueueDetailsFormGroup, EveCallQueueDetailsValidationService } from './components/details/eve-call-queue-details-validation.service';
import { EveCallQueueAgentSettingsFormGroup, EveCallQueueAgentSettingsValidationService } from './components/agent-settings/eve-call-queue-agent-settings-validation.service';
import { EveCallQueueConfigurationFormGroup, EveCallQueueConfigurationValidationService } from './components/configuration/eve-call-queue-configuration-validation.service';
import { SchedulingFormGroup, SchedulingValidationService } from './components/scheduling/eve-call-queue-details-validation.service';
import { SkillRating } from './components/agent-priority/components/skill-rating/skill-rating.component';
import { ScheduleFormGroup } from './components/scheduling/eve-select-dates/eve-select-dates-validation.service';

@Injectable({providedIn: 'root'})
export class EveCallQueuePageValidationService {

  constructor(private fb: FormBuilder,
    private eveCallQueueDetailsValidationService: EveCallQueueDetailsValidationService,
    private eveCallQueueAgentSettingsValidationService: EveCallQueueAgentSettingsValidationService,
    private eveCallQueueConfigurationValidationService: EveCallQueueConfigurationValidationService,
    private schedulingValidationService: SchedulingValidationService,
  ) { }

    public generateValidationForm(callQueue: CallQueue): FormGroup {
      const formGroup = this.fb.group<CallQueueFormGroup>({
        details: this.eveCallQueueDetailsValidationService.generateValidationForm(callQueue),

        distribution: this.fb.group<DistributionFormGroup>({


          priorityUsers: this.fb.control(callQueue.users.sort((a, b) => a.priority - b.priority).map(u => u.eveUser)),
          skillRating: this.fb.control<SkillRating>({
            skillRating1: callQueue.users.filter(u => u.skillRating == 1).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
            skillRating2: callQueue.users.filter(u => u.skillRating == 2).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
            skillRating3: callQueue.users.filter(u => u.skillRating == 3).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
            skillRating4: callQueue.users.filter(u => u.skillRating == 4).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
            skillRating5: callQueue.users.filter(u => u.skillRating == 5).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
            unallocated: callQueue.users.filter(u => u.skillRating != 1 && u.skillRating != 2 && u.skillRating != 3 && u.skillRating != 4 && u.skillRating != 5).sort((a, b) => a.priority - b.priority).map(u => u.eveUser),
          })
        }),

        agentSettings: this.eveCallQueueAgentSettingsValidationService.generateValidationForm(callQueue),
        configuration: this.eveCallQueueConfigurationValidationService.generateValidationForm(callQueue),
        supervisors: this.fb.control(callQueue.supervisors),
        scheduling: this.schedulingValidationService.generateValidationForm(callQueue),
      });



      formGroup.get("details")?.get("users")?.valueChanges.subscribe(users => {

        const priorityUsersControls = formGroup.get("distribution")?.get("priorityUsers");
        const priorityUsers: EveUser[] = priorityUsersControls?.value ?? [];
        const newUsers = users?.filter(e => priorityUsers.find(u => u.eveUserId == e.eveUserId) == null) ?? [];
        const existingUsers = priorityUsers.filter(u => users?.find(e => e.eveUserId == u.eveUserId) != null);
        const newPriorityUsers = existingUsers?.concat(newUsers);
        priorityUsersControls?.setValue(newPriorityUsers || []);

      });


      formGroup.get("details")?.get("users")?.valueChanges.subscribe(users => {

        const skillRatingUsersControls = formGroup.get("distribution")?.get("skillRating");
        const skillRatingUsers = skillRatingUsersControls?.value

        
        const newSkillRatingUsers: SkillRating = {
          "skillRating1": skillRatingUsers?.skillRating1.filter((u: EveUser) => users?.find(e => u.eveUserId == e.eveUserId) != null) ?? [],
          "skillRating2": skillRatingUsers?.skillRating2.filter((u: EveUser) => users?.find(e => u.eveUserId == e.eveUserId) != null) ?? [],
          "skillRating3": skillRatingUsers?.skillRating3.filter((u: EveUser) => users?.find(e => u.eveUserId == e.eveUserId) != null) ?? [],
          "skillRating4": skillRatingUsers?.skillRating4.filter((u: EveUser) => users?.find(e => u.eveUserId == e.eveUserId) != null) ?? [],
          "skillRating5": skillRatingUsers?.skillRating5.filter((u: EveUser) => users?.find(e => u.eveUserId == e.eveUserId) != null) ?? [],
          "unallocated": []
        };
        
        newSkillRatingUsers.unallocated = users?.filter(e =>
          newSkillRatingUsers.skillRating1?.find(u => u.eveUserId == e.eveUserId) == null &&
          newSkillRatingUsers.skillRating2?.find(u => u.eveUserId == e.eveUserId) == null &&
          newSkillRatingUsers.skillRating3?.find(u => u.eveUserId == e.eveUserId) == null &&
          newSkillRatingUsers.skillRating4?.find(u => u.eveUserId == e.eveUserId) == null &&
          newSkillRatingUsers.skillRating5?.find(u => u.eveUserId == e.eveUserId) == null
        ) ?? [];


        skillRatingUsersControls?.setValue(newSkillRatingUsers);

      });
     
      return formGroup;
  }


  public generateCallQueueInput(formGroup: FormGroup<CallQueueFormGroup>, callQueue: CallQueue): CallQueueInput | null  {

    const form = formGroup.value;

    const users = form.details?.distributionType == DistributionType.Priority ?
      form.distribution?.priorityUsers?.map((e, i) => ({ priority: i + 1, skillRating: 0, eveUser: ToEveUserInput(e) })) :
      [
        ...form.distribution?.skillRating?.skillRating1.map((e, i) => ({ priority: i + 1, skillRating: 1, eveUser: ToEveUserInput(e) })) ?? [],
        ...form.distribution?.skillRating?.skillRating2.map((e, i) => ({ priority: i + 1, skillRating: 2, eveUser: ToEveUserInput(e) })) ?? [],
        ...form.distribution?.skillRating?.skillRating3.map((e, i) => ({ priority: i + 1, skillRating: 3, eveUser: ToEveUserInput(e) })) ?? [],
        ...form.distribution?.skillRating?.skillRating4.map((e, i) => ({ priority: i + 1, skillRating: 4, eveUser: ToEveUserInput(e) })) ?? [],
        ...form.distribution?.skillRating?.skillRating5.map((e, i) => ({ priority: i + 1, skillRating: 5, eveUser: ToEveUserInput(e) })) ?? [],
      ];


    const schedules = form.scheduling?.schedules?.map((s, i) => ({

      scheduleId: s.scheduleId,
      timeZoneString: form.scheduling?.timezone,

      scheduleType: s.scheduleType,
      monday: (s.recurringSchedule?.indexOf("Monday") ?? -1) > -1,
      tuesday: (s.recurringSchedule?.indexOf("Tuesday") ?? -1) > -1,
      wednesday: (s.recurringSchedule?.indexOf("Wednesday") ?? -1) > -1,
      thursday: (s.recurringSchedule?.indexOf("Thursday") ?? -1) > -1,
      friday: (s.recurringSchedule?.indexOf("Friday") ?? -1) > -1,
      saturday: (s.recurringSchedule?.indexOf("Saturday") ?? -1) > -1,
      sunday: (s.recurringSchedule?.indexOf("Sunday") ?? -1) > -1,

      bankHolidays: s.bankHolidays,

      dateFrom: s.dateFrom,
      dateTo: s.dateTo,

      timeFrom: s.timeFrom,
      timeTo: s.timeTo,


      scheduleAction: s.scheduleAction,
      forwardDestination: s.forwardDestination,
      prompt: s.prompt,

      priority: i
    } as Schedule));

    debugger;

    const callQueueInput: CallQueueInput = {
      callQueueId: callQueue.callQueueId,
      organisationNode: callQueue.organisationNode,

      avatarImageId: form.details?.avatarImageId,
      name: form.details?.name,
      description: form.details?.description,
      number: ToNumberInput(form.details?.number),
      callRecording: form.details?.callRecording,
      queueSize: form.details?.queueSize,
      distributionType: form.details?.distributionType,

      addCallQueueUsers: users?.filter(u => callQueue.users.find(e => e.eveUser.eveUserId == u.eveUser.eveUserId) == null),
      updateCallQueueUsers: users?.filter(u => callQueue.users.find(e => e.eveUser.eveUserId == u.eveUser.eveUserId && (e.priority != u.priority || e.skillRating != u.skillRating)) != null),
      deleteCallQueueUsers: callQueue.users?.filter(u => users?.find(e => e.eveUser.eveUserId == u.eveUser.eveUserId) == null).map(u => ({ eveUser: { eveUserId: u.eveUser.eveUserId} })),

      ringingAgents: form.agentSettings?.ringingAgents,
      agentTimeOut: form.agentSettings?.agentTimeOut,
      checkAvailablePresence: form.agentSettings?.checkAvailablePresence,
      wrapUpTime: form.agentSettings?.wrapUpTime,
      missedCallsLogout: form.agentSettings?.missedCallsLogout,
      helpNumber: ToDestinationInput(form.agentSettings?.helpNumber),
      useLongestWait: form.agentSettings?.useLongestWait,
      excludeMobile: form.agentSettings?.excludeMobile,

      showInSearch: form.agentSettings?.showInSearch,
      maxCallsInQueue: form.agentSettings?.maxCallsInQueue,
      maxWaitTime: form.agentSettings?.maxWaitTime,
      queueFullSendToBusy: form.agentSettings?.queueFullSendToBusy,
      queueFullForwardTo: ToDestinationInput(form.agentSettings?.queueFullForwardTo),
      maxAgentAttempts: form.agentSettings?.maxAgentAttempts,
      maxAgentAttemptsFallback: ToDestinationInput(form.agentSettings?.maxAgentAttemptsFallback),
      forwardCallsAfter: form.agentSettings?.forwardCallsAfter,
      forwardCallsTo: ToDestinationInput(form.agentSettings?.forwardCallsTo),



      placedInQueueMessagePrompt: ToPromptSelectionInput(form.configuration?.placedInQueueMessagePrompt),
      progressMessagePrompt: ToPromptSelectionInput(form.configuration?.progressMessagePrompt),
      progressRepeat: form.configuration?.progressRepeat,
      queueingMessagePrompt: ToPromptSelectionInput(form.configuration?.queueingMessagePrompt),
      playRingCallingAgent: form.configuration?.playRingCallingAgent,
      playQueuePosition: form.configuration?.playQueuePosition,
      playEstimatedTime: form.configuration?.playEstimatedTime,
      alternateQueueInfo: form.configuration?.alternateQueueInfo,

      statisticsPeriod: form.configuration?.statisticsPeriod,
      _24hrStatisticsFixed: form.configuration?._24hrStatisticsFixed,
      _24hrStatisticsResetTime: form.configuration?._24hrStatisticsResetTime,

      callbackRequeue: form.configuration?.callbackRequeue,
      callbackAttempts: form.configuration?.callbackAttempts,
      callbackValidity: form.configuration?.callbackValidity,
      callbackMessagePrompt: ToPromptSelectionInput(form.configuration?.callbackMessagePrompt),



      addSupervisors: form.supervisors?.filter(s => callQueue.supervisors.find(e => s.eveUserId == e.eveUserId) == null).map(e => ToEveUserInput(e)),
      deleteSupervisors: callQueue.supervisors.filter(s => form.supervisors?.find(e => s.eveUserId == e.eveUserId) == null).map(e => ToEveUserInput(e)),

      addCallQueueSchedules: schedules?.filter(s => s.scheduleId == null).map(s => ToScheduleInput(s)),
      updateCallQueueSchedules: schedules?.filter(s => callQueue.schedules?.find(e => s.scheduleId == e.scheduleId && (compareSchedule(s, e) == false)) != null).map(s => ToScheduleInput(s)),
      deleteCallQueueSchedules: callQueue.schedules.filter(s => schedules?.find(e => s.scheduleId == e.scheduleId) == null).map(s => ToScheduleInput(s)),
    }


    if (callQueue.callQueueId > 0) {
      //on update only send changed values
      delete callQueueInput.organisationNode;


      if (callQueueInput.avatarImageId == callQueue.avatarImageId) delete callQueueInput.avatarImageId;
      if (callQueueInput.name == callQueue.name) delete callQueueInput.name;
      if (callQueueInput.description == callQueue.description) delete callQueueInput.description;
      if (callQueueInput.number?.numberId == callQueue.number?.numberId) delete callQueueInput.number;
      if (callQueueInput.callRecording == callQueue.callRecording) delete callQueueInput.callRecording;
      if (callQueueInput.distributionType == callQueue.distributionType) delete callQueueInput.distributionType;
      if (callQueueInput.queueSize == callQueue.queueSize) delete callQueueInput.queueSize;

      if (callQueueInput.addCallQueueUsers?.length == 0) delete callQueueInput.addCallQueueUsers;
      if (callQueueInput.updateCallQueueUsers?.length == 0) delete callQueueInput.updateCallQueueUsers;
      if (callQueueInput.deleteCallQueueUsers?.length == 0) delete callQueueInput.deleteCallQueueUsers;



      if (callQueueInput.ringingAgents == callQueue.ringingAgents) delete callQueueInput.ringingAgents;
      if (callQueueInput.agentTimeOut == callQueue.agentTimeOut) delete callQueueInput.agentTimeOut;
      if (callQueueInput.checkAvailablePresence == callQueue.checkAvailablePresence) delete callQueueInput.checkAvailablePresence;
      if (callQueueInput.wrapUpTime == callQueue.wrapUpTime) delete callQueueInput.wrapUpTime;
      if (callQueueInput.missedCallsLogout == callQueue.missedCallsLogout) delete callQueueInput.missedCallsLogout;
      if (CompareDestination(callQueueInput.helpNumber, callQueue.helpNumber)) delete callQueueInput.helpNumber;
      if (callQueueInput.useLongestWait == callQueue.useLongestWait) delete callQueueInput.useLongestWait;
      if (callQueueInput.excludeMobile == callQueue.excludeMobile) delete callQueueInput.excludeMobile;


      if (callQueueInput.showInSearch == callQueue.showInSearch) delete callQueueInput.showInSearch;
      if (callQueueInput.maxCallsInQueue == callQueue.maxCallsInQueue) delete callQueueInput.maxCallsInQueue;
      if (callQueueInput.maxWaitTime == callQueue.maxWaitTime) delete callQueueInput.maxWaitTime;
      if (callQueueInput.queueFullSendToBusy == callQueue.queueFullSendToBusy) delete callQueueInput.queueFullSendToBusy;
      if (CompareDestination(callQueueInput.queueFullForwardTo, callQueue.queueFullForwardTo)) delete callQueueInput.queueFullForwardTo;
      if (callQueueInput.maxAgentAttempts == callQueue.maxAgentAttempts) delete callQueueInput.maxAgentAttempts;
      if (CompareDestination(callQueueInput.maxAgentAttemptsFallback, callQueue.maxAgentAttemptsFallback)) delete callQueueInput.maxAgentAttemptsFallback;
      if (callQueueInput.forwardCallsAfter == callQueue.forwardCallsAfter) delete callQueueInput.forwardCallsAfter;
      if (CompareDestination(callQueueInput.forwardCallsTo, callQueue.forwardCallsTo)) delete callQueueInput.forwardCallsTo;



      if (callQueueInput.placedInQueueMessagePrompt?.promptId == callQueue.placedInQueueMessagePrompt?.promptId) delete callQueueInput.placedInQueueMessagePrompt;
      if (callQueueInput.progressMessagePrompt?.promptId == callQueue.progressMessagePrompt?.promptId) delete callQueueInput.progressMessagePrompt;
      if (callQueueInput.progressRepeat == callQueue.progressRepeat) delete callQueueInput.progressRepeat;
      if (callQueueInput.queueingMessagePrompt?.promptId == callQueue.queueingMessagePrompt?.promptId) delete callQueueInput.queueingMessagePrompt;
      if (callQueueInput.playRingCallingAgent == callQueue.playRingCallingAgent) delete callQueueInput.playRingCallingAgent;
      if (callQueueInput.playQueuePosition == callQueue.playQueuePosition) delete callQueueInput.playQueuePosition;
      if (callQueueInput.playEstimatedTime == callQueue.playEstimatedTime) delete callQueueInput.playEstimatedTime;
      if (callQueueInput.alternateQueueInfo == callQueue.alternateQueueInfo) delete callQueueInput.alternateQueueInfo;


      if (callQueueInput.statisticsPeriod == callQueue.statisticsPeriod) delete callQueueInput.statisticsPeriod;
      if (callQueueInput._24hrStatisticsFixed == callQueue._24hrStatisticsFixed) delete callQueueInput._24hrStatisticsFixed;
      if (callQueueInput._24hrStatisticsResetTime == callQueue._24hrStatisticsResetTime) delete callQueueInput._24hrStatisticsResetTime;

      if (callQueueInput.callbackRequeue == callQueue.callbackRequeue) delete callQueueInput.callbackRequeue;
      if (callQueueInput.callbackAttempts == callQueue.callbackAttempts) delete callQueueInput.callbackAttempts;
      if (callQueueInput.callbackValidity == callQueue.callbackValidity) delete callQueueInput.callbackValidity;
      if (callQueueInput.callbackMessagePrompt?.promptId == callQueue.callbackMessagePrompt?.promptId) delete callQueueInput.callbackMessagePrompt;


      if (callQueueInput.addSupervisors?.length == 0) delete callQueueInput.addSupervisors;
      if (callQueueInput.deleteSupervisors?.length == 0) delete callQueueInput.deleteSupervisors;

      if (callQueueInput.addCallQueueSchedules?.length == 0) delete callQueueInput.addCallQueueSchedules;
      if (callQueueInput.updateCallQueueSchedules?.length == 0) delete callQueueInput.updateCallQueueSchedules;
      if (callQueueInput.deleteCallQueueSchedules?.length == 0) delete callQueueInput.deleteCallQueueSchedules;


      if (Object.keys(callQueueInput).filter(k => k != "callQueueId" && k != "organisationNode").length == 0) return null;
    }
    else
    {
      delete callQueueInput.callQueueId;
    }

   
    return callQueueInput;


  }

}

export function CompareDestination(destinationInput: DestinationInput | null | undefined, destination: Destination | null | undefined): boolean {
  return destination?.destinationId == destinationInput?.destinationId && destination?.name == destinationInput?.name;
}



export function ToBankHolidayInput(bankHoliday: BankHoliday): BankHolidayInput {
  return {
    bankHolidayId: bankHoliday.bankHolidayId
  };
}

export function ToNumberInput(number: Number | null | undefined): NumberInput | null {
  if (number == null) return null;

  return {
    numberId: number.numberId
  };
}

export function ToDestinationInput(destination: Destination | null | undefined): DestinationInput | null {
  if (destination == null) return null;

  return {
    destinationId: destination.destinationId,
    name: destination.name
  };
}

export function ToPromptSelectionInput(prompt: Prompt | null | undefined): PromptSelectionInput | null {
  if (prompt == null) return null;

  return {
    promptId: prompt.promptId
  };
}

export function ToEveUserInput(eveUser: EveUser): EveUserInput {

  return {
    eveUserId: eveUser.eveUserId
  };
}


export function ToScheduleInput(s: Schedule): ScheduleInput {

  var scheduleInput : ScheduleInput = {

    scheduleId: s.scheduleId,
    timeZoneString: s?.timeZoneString,

    scheduleType: s.scheduleType,
    monday: s.monday,
    tuesday: s.tuesday,
    wednesday: s.wednesday,
    thursday: s.thursday,
    friday: s.friday,
    saturday: s.saturday,
    sunday: s.sunday,

    bankHolidays: s.bankHolidays?.map(b => ToBankHolidayInput(b)),

    dateFrom: s.dateFrom,
    dateTo: s.dateTo,

    timeFrom: s.timeFrom,
    timeTo: s.timeTo,


    scheduleAction: s.scheduleAction,
    forwardDestination: ToDestinationInput(s.forwardDestination),
    prompt: ToPromptSelectionInput(s.prompt),

    priority: s.priority
  };

  if (scheduleInput.scheduleId == null) delete scheduleInput.scheduleId;

  return scheduleInput;
}


function compareSchedule(s1: Schedule, s2: Schedule) {

  const result = s1.scheduleType == s2.scheduleType &&
    s1.monday == s2.monday &&
    s1.tuesday == s2.tuesday &&
    s1.wednesday == s2.wednesday &&
    s1.thursday == s2.thursday &&
    s1.friday == s2.friday &&
    s1.saturday == s2.saturday &&
    s1.sunday == s2.sunday &&

    JSON.stringify(s1.bankHolidays.map(b => b.bankHolidayId).sort()) === JSON.stringify(s2.bankHolidays.map(b => b.bankHolidayId).sort()) &&

    s1.dateFrom == s2.dateFrom &&
    s1.dateTo == s2.dateTo &&

    s1.timeFrom == s2.timeFrom &&
    s1.timeTo == s2.timeTo &&

    s1.scheduleAction == s2.scheduleAction &&
    s1.forwardDestination?.destinationId == s2.forwardDestination?.destinationId && s1.forwardDestination?.name == s2.forwardDestination?.name &&
    s1.prompt?.promptId == s2.prompt?.promptId &&
    s1.priority == s2.priority &&
    s1.timeZoneString == s2.timeZoneString;


  return result;

}



export interface DistributionFormGroup {

  priorityUsers: FormControl<EveUser[] | null>;
  skillRating: FormControl<SkillRating | null>;
}


export interface CallQueueFormGroup {

  details: FormGroup<CallQueueDetailsFormGroup>;
  distribution: FormGroup<DistributionFormGroup>;
  agentSettings: FormGroup<EveCallQueueAgentSettingsFormGroup>;
  configuration: FormGroup<EveCallQueueConfigurationFormGroup>;
  supervisors: FormControl<EveUser[] | null>;
  scheduling: FormGroup<SchedulingFormGroup>;
}

