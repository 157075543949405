<form novalidate autocomplete="off" [formGroup]="sipTrunkDetailsForm">
    <ng-container *ngIf="sipTrunkDetails">
        <eve-container title='Sip Trunk' icon='fa fa-phone-square'>
            <div class="container">
                <div class="row input-group-row ">
                    <div class="label">
                        Name
                    </div>
                    <div class="col-5">
                        <div style="position:relative;">
                            <div class="p-inputgroup">
                                <input pInputText placeholder="Name" [(ngModel)]="sipTrunkDetails.name"
                                    formControlName="name" name="name" />
                            </div>
                            <eve-validation-errors name="Name" [validationControl]="sipTrunkDetailsForm.controls['name']"></eve-validation-errors>
                        </div>
                    </div>
                </div>
                <div class="row input-group-row ">
                    <div class="label">
                        Number
                    </div>
                    <div class="col-5">
                        <eve-number-autocomplete placeholder="DDI" [(ngModel)]="sipTrunkDetails.number" [organisationNode]="sipTrunkDetails.organisationNode" formControlName="number" [numberFilter]="numberFilter" (ngModelChange)="onNumberModelChange($event)"></eve-number-autocomplete>
                    </div>
                </div>
                <div class="row input-group-row ">
                    <div class="label">
                        Authenticate
                    </div>
                    <div class="col-5">
                        <eve-authentication formControlName="authenticationType" class="p-inputwrapper"
                            [(ngModel)]="sipTrunkDetails.authenticationType">
                        </eve-authentication>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        IP Address 
                    </div>
                    <div class="col-5">
                        <div style="position:relative;">
                            <div class="p-inputgroup">
                                <input pInputText placeholder="IP Address"
                                    [(ngModel)]="sipTrunkDetails.ipAddress"                  
                                    formControlName="ipAddress" name="IpAddress" />
                            </div>
                            <eve-validation-errors name="IpAddress" [validationControl]="sipTrunkDetailsForm.controls['ipAddress']">
                                <span *ngIf="sipTrunkDetailsForm.get('ipAddress')?.errors?.['ipAddressValidationRequired']">
                                    valid ip address required
                                </span>
                            </eve-validation-errors>
                        </div>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType === 'USERNAME'">
                    <div class="label">
                        UserName
                    </div>
                    <div class="col-5">
                        <div style="position:relative;">
                            <div class="p-inputgroup">
                                <input pInputText placeholder="UserName" disabled="true"
                                    [(ngModel)]="sipTrunkDetails.authenticationUsername"
                                    formControlName="authenticationUsername" name="Username" />
                            </div>
                            <eve-validation-errors name="Username" [validationControl]="sipTrunkDetailsForm.controls['authenticationUsername']"></eve-validation-errors>
                        </div>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType === 'USERNAME'" >
                    <div class="label">
                        Password
                    </div>
                    <div class="col-5">
                        <div style="position:relative;">
                            <div class="p-inputgroup">
                                <input pInputText placeholder="Password" disabled="true"
                                    [(ngModel)]="sipTrunkDetails.authenticationPassword"
                                    formControlName="authenticationPassword" name="Password" />
                            </div>
                            <eve-validation-errors name="Password" [validationControl]="sipTrunkDetailsForm.controls['authenticationPassword']"></eve-validation-errors>
                        </div>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType === 'USERNAME'" >
                    <div class="label">
                        Domain
                    </div>
                    <div class="col-5">
                        <div style="position:relative;">
                            <div class="p-inputgroup">
                                <input pInputText placeholder="Domain" disabled="true" name="Domain" value="register.iameve.co.uk"/>
                            </div>
                            <eve-validation-errors name="Password" [validationControl]="sipTrunkDetailsForm.controls['authenticationPassword']"></eve-validation-errors>
                        </div>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        Inbound Format
                    </div>
                    <div class="col-5">
                        <eve-number-translation formControlName="inboundTranslation" class="p-inputwrapper"
                            placeholder="Please Select an inbound format..."
                            [(ngModel)]="sipTrunkDetails.inboundTranslation">
                        </eve-number-translation>
                    </div>
                </div>
                <div class="row input-group-row">
                    <div class="label">
                        Outbound Presentation
                    </div>
                    <div class="col-5">
                        <eve-sip-trunk-presentation formControlName="sipTrunkPresentation" class="p-inputwrapper"
                            placeholder="Select an outbound Presentation..."
                            [(ngModel)]="sipTrunkDetails.sipTrunkPresentation" >
                        </eve-sip-trunk-presentation>
                    </div>
                </div>
                
                <div class="row input-group-row" >
                    <div class="label">
                        Channels
                    </div>
                    <div class="col-5">
                        <eve-slider name="channels" formControlName="channels" 
                        [(ngModel)]="sipTrunkDetails.channels"
                        [min]=0 [max]=100 [step]="1">
                        </eve-slider>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        Inbound Channel Limit
                    </div>
                    <div class="col-5">
                        <!-- need to be cascaded from channels -->
                        <eve-slider name="inboundChannelsLimit" formControlName="inboundChannelsLimit" 
                            [(ngModel)]="sipTrunkDetails.inboundChannelsLimit"
                            [min]=inboundChannelsLimitMin [max]=inboundChannelsLimitMax [step]="1">
                        </eve-slider>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        Outbound Channel Limit
                    </div>
                    <div class="col-5">
                        <!-- need to be cascaded from channels -->
                        <eve-slider name="outboundChannelsLimit" formControlName="outboundChannelsLimit" 
                            [(ngModel)]="sipTrunkDetails.outboundChannelsLimit"
                            [min]=outboundChannelsLimitMin [max]=outboundChannelsLimitMax [step]="1">
                        </eve-slider>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        Call Recording
                    </div>
                    <div class="col-5">
                        <eve-call-recording formControlName="callRecording" class="p-inputwrapper"
                            [(ngModel)]="sipTrunkDetails.callRecording"
                            placeholder="Call Recording">
                        </eve-call-recording>
                    </div>
                </div>
                <div class="row input-group-row" *ngIf="sipTrunkDetails.authenticationType !== 'USERNAME'">
                    <div class="label">
                        Busy On Channels Exceeded
                    </div>
                    <div class="col-5">
                        <eve-toggle name="Busy On Channels Exceeded"
                            formControlName="busyOnChannelsExceeded"
                            [(ngModel)]="sipTrunkDetails.busyOnChannelsExceeded"    
                        ></eve-toggle>
                    </div>
                </div>
            </div>
        </eve-container>

        <eve-container title="Barring" icon="fa fa-list-ul">
            <div style="padding-bottom:20px; padding-top: 0px;">
                * Enabling toggle will bar call destination
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">Local / National</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringLocal"
                                    formControlName="barringLocal"
                                    [(ngModel)]="sipTrunkDetails.barringLocal"    
                                ></eve-toggle>
                            </div>
                        </div>
        
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">Mobile</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringMobile"
                                    formControlName="barringMobile"
                                    [(ngModel)]="sipTrunkDetails.barringMobile"></eve-toggle>
                            </div>
                        </div>
        
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">Freephone</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringFreephone"
                                    formControlName="barringFreephone"
                                    [(ngModel)]="sipTrunkDetails.barringFreephone"></eve-toggle>
                            </div>
                        </div>
        
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">084/087</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="Barring084"
                                    formControlName="barring084"
                                    [(ngModel)]="sipTrunkDetails.barring084"></eve-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">Directory</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringDirectory"
                                    formControlName="barringDirectory"
                                    [(ngModel)]="sipTrunkDetails.barringDirectory"></eve-toggle>
                            </div>
                        </div>
        
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">UK Premium</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringPremium"
                                    formControlName="barringPremium"
                                    [(ngModel)]="sipTrunkDetails.barringPremium"></eve-toggle>
                            </div>
                        </div>
        
                        <div class="input-group input-wrapper">
                            <label class="col-5 control-label">International</label>
                            <div class="col-7 no-padding">
                                <eve-toggle name="BarringInternational"
                                    formControlName="barringInternational"
                                    [(ngModel)]="sipTrunkDetails.barringInternational"></eve-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </eve-container>
            
        <eve-container title="Numbers" icon="fa fa-pencil-square-o" >
            <eve-sip-trunk-number-manager [sipTrunkId]="sipTrunkDetails.sipTrunkId" [sipTrunkNumbers]="sipTrunkDetails.sipTrunkNumbers"></eve-sip-trunk-number-manager>
        </eve-container>
    </ng-container>
    
</form>