import { Component, ViewChild, Input, Inject, ElementRef, AfterViewInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { IImageModel } from './i-image-model';
import { EveAvatarService } from './eve-avatar.service';
declare var $: any;
@Component({
  selector: 'eve-avatar-content',
  templateUrl: './eve-avatar-modal.component.html',
  styleUrls: ['./eve-avatar-modal.component.css'],
})
export class EveAvatarModalComponent implements AfterViewInit {

  @Input() title: string = '';
  @Input() width: string = '650px';
  @Input() height: string = '500px';

 
  @ViewChild('img')
  public img: ElementRef = {} as any;

  @ViewChild('imageupload')
  public imageupload:ElementRef = {} as any;

  @ViewChild('drag')
  public drag:ElementRef = {} as any;

  @ViewChild('zoomIn')
  public zoomIn: ElementRef = {} as any;

  @ViewChild('saveImage')
  public saveImage: ElementRef = {} as any;

  
  avatarImageId: number | null = null;
  organisationNode!: string;



  
  constructor(public dialogRef: MatDialogRef<EveAvatarModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    public eveAvatarService: EveAvatarService
    ) {
    this.avatarImageId = data.avatarImageId;
    this.organisationNode = data.organisationNode;
  }

  
  private originalXPos: number = 30
  private originalYPos: number = 30
  ngAfterViewInit()
  {

    if (this.eveAvatarService.base64String != null) {

      $(this.img.nativeElement).attr('src', this.eveAvatarService.base64String);
      $(this.img.nativeElement).css({ top: this.eveAvatarService.top, left: this.eveAvatarService.left, width: this.eveAvatarService.width, height: this.eveAvatarService.height  });

      $(this.drag.nativeElement).attr('src', this.eveAvatarService.base64String);
      $(this.drag.nativeElement).css({ top: this.eveAvatarService.top, left: this.eveAvatarService.left, width: this.eveAvatarService.width, height: this.eveAvatarService.height });

      this.originalXPos = this.eveAvatarService.left ?? 30;
      this.originalYPos = this.eveAvatarService.top ?? 30;
    }


    $(this.imageupload.nativeElement).on('change', () => {
      // this.readURL(this.imageupload, this.img, this.drag);
      console.log('Change made -- ngAfterViewInit');
      this.imageUploadChange();
    });
    


    $(this.drag.nativeElement).draggable({
     drag: (event: any, ui: any) => {
      var xPos = this.originalXPos + ui.offset.left;
      var yPos = this.originalYPos + ui.offset.top;

      console.log('position left:', ui.position.left)
      console.log('position top:', ui.position.top)

      console.log('xposition:', xPos)
      console.log('yposition:', yPos)

      ui.position.left = xPos;
      ui.position.top = yPos;
      $(this.img.nativeElement).css({ top: yPos, left: xPos });
     }
    });

    $(this.drag.nativeElement).on('mouseup', (event: any) => {
      this.originalXPos = parseInt(this.img.nativeElement.style.left);
      this.originalYPos = parseInt(this.img.nativeElement.style.top);
    })
  }

  onSaveImageClick = async () => {
    var x = parseInt(this.img.nativeElement.style.left);
    var y = parseInt(this.img.nativeElement.style.top);

    var width = parseInt($(this.img.nativeElement).width());
    var height = parseInt($(this.img.nativeElement).height());

    var imgName = this.eveAvatarService.fileName;

    console.log("Save Image - x = " + x);
    console.log("Save Image - y = " + y);

    console.log("Save Image - width = " + width);
    console.log("Save Image - height = " + height);

     let image: IImageModel = {
        imageData: $(this.img.nativeElement).attr('src'),
        imageName: imgName,
        x: x,
        y: y,
        width: width,
        height: height
      } as any;

      this.eveAvatarService.uploadImage(this.organisationNode, image)
      .subscribe({
       next: (imageId: any) => {
          this.eveAvatarService.base64String = image.imageData;
          this.eveAvatarService.left = x;
          this.eveAvatarService.top = y;
          this.eveAvatarService.width = width;
          this.eveAvatarService.height = height;

         this.dialogRef.close({mode: 'edit', cancelled: false, avatarImageId: imageId });
       },
       error: (error: any) => {
         console.error(error);
       }
      }); 
   }


  onZoomInClick = () => {
    this.zoom($(this.img.nativeElement), $(this.drag.nativeElement), 1.11111111);
  }

  onZoomOutClick = () => {
    this.zoom($(this.img.nativeElement), $(this.drag.nativeElement), 0.9);
  }

  onRemoveImageClick = () => {
    this.eveAvatarService.base64String = null;
    this.dialogRef.close({cancelled: false, avatarImageId: null});
  }

  onCancelClick = () => {
    this.dialogRef.close({cancelled: true});
  }

  private zoom = (img: any, drag: any, increment: any) => {
    var imgWidth = img.width() * increment;
    var imgHeight = img.height() * increment;

    imgWidth = Math.round(imgWidth);
    imgHeight = Math.round(imgHeight);

    var hOffset = Math.round((img.width() - imgWidth));
    var vOffset = Math.round((img.height() - imgHeight));

    var x = hOffset * ((130 - parseInt(img[0].style.left)) / img.width());
    var y = vOffset * ((130 - parseInt(img[0].style.top)) / img.height());

    x = Math.round(x + parseInt(img[0].style.left));
    y = Math.round(y + parseInt(img[0].style.top));

    this.originalXPos = x;
    this.originalYPos = y;

    img.css({ width: imgWidth, height: imgHeight, top: y, left: x });
    drag.css({ width: imgWidth, height: imgHeight, top: y, left: x });
  }

  private setupImagePreview = (img: any, drag: any) =>{
    var w = img.get(0).width;
    var h = img.get(0).height;

    var l = 0;
    var t = 0;

    if (w > h) {
        h = (h / w) * 200;
        w = 200;
    }
    else {
        w = (w / h) * 200;
        h = 200;
    }

    l = 130 - (w / 2);
    t = 130 - (h / 2);

    img.css({ top: Math.round(t), left: Math.round(l), width: Math.round(w), height: Math.round(h) });
    drag.css({ top: Math.round(t), left: Math.round(l), width: Math.round(w), height: Math.round(h) });
  }

  private imageUploadChange = () => {
    console.log('Change made -- imageUploadChange');
    this.readURL($(this.imageupload.nativeElement)[0], $(this.img.nativeElement), $(this.drag.nativeElement));
  }

  private readURL = (input: any, img: any, drag: any) => {
    if (input.files && input.files[0]) {
      var re = new RegExp(/(?:\.([^.]+))?$/);

      this.eveAvatarService.fileName = input.files[0].name;
      let filenameExtensions = re.exec(input.files[0].name);
      if( filenameExtensions != null)
      {
        var extension = filenameExtensions[1].toString()?.toLowerCase();

        if (extension == null || 
          !(
              extension == "png" ||
              extension == "jpeg" ||
              extension == "jpg" ||
              extension == "bmp" 
          ))
        {
          console.error("Image Upload Failed : unsupported file type");
          return;
        }
        var reader = new FileReader();
        reader.onload = (e:any) => {
          if (e.target.result.length > 3000000) {
            console.error("Image Upload Failed : Image is too large - limit is 2MB.");
            return;
          }
  
          img.css({ top: '', left: '', width: 'auto', height: 'auto' });
          drag.css({ top: '', left: '', width: 'auto', height: 'auto' });

          img.attr('src', e.target.result);
          drag.attr('src', e.target.result);
          img.attr('data-filename', input.files[0].name);

          this.setupImagePreview($(this.img.nativeElement), $(this.drag.nativeElement));
          this.originalXPos = parseInt(this.img.nativeElement.style.left);
          this.originalYPos = parseInt(this.img.nativeElement.style.top);
        }
  
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
}
