import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AntiForgeryToken } from './export-cons';


const antiForgeryToken = document.getElementsByTagName('anti-forgery-token')[0]?.attributes?.getNamedItem('value')?.value ?? ""

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],

  providers: [
    { provide: AntiForgeryToken, useValue: antiForgeryToken },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ]
})
export class InterceptorsModule { }
