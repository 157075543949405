import {
  Component, ComponentFactoryResolver, Directive, ElementRef, HostBinding, Inject, Input, ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'eve-confirm-dialog',
  templateUrl: './eve-confirm-dialog.component.html',
  styleUrls: ['./eve-confirm-dialog.component.css']
})
export class EveConfirmDialogComponent{

  options!: EveConfirmDialogComponentOptions;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EveConfirmDialogComponentOptions) {

    this.options = data;
  }
}



interface EveConfirmDialogComponentOptions {
  icon: string;
  title: string;
  message: string;
}
