import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ImgResolution,
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperLoaderConfig
} from '@alyle/ui/image-cropper';
import { lyl, StyleRenderer } from '@alyle/ui';


const styles = () => {
  return {
    actions: lyl `{
      display: flex
    }`,
    cropper: lyl `{
      max-width: 400px
      height: 300px
    }`,
    flex: lyl `{
      flex: 1
    }`
  };
};

@Component({
  selector: 'eve-crop',
  templateUrl: './eve-crop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})

export class EveCropComponent implements OnInit, AfterViewInit{

  @Input() avatarImageId!: number;
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready!: boolean;
  result!: string;

  cropperConfig : ImgCropperConfig = {
    width: 150, // Default `250`
    height: 150, // Default `200`,
    round: true,
    output: ImgResolution.Default, // Default ImgResolution.Default
    extraZoomOut: true,
  };

  loaderConfig : ImgCropperLoaderConfig = {
    // scale: 0.745864772531767,
    // xOrigin: 642.380608078103,
    // yOrigin: 236.26357452128866,
    // areaWidth: 100, // Use when cropper area is not fixed
    // areaHeight: 100,
    rotation: 0,
    originalDataURL: '',

  };

  @ViewChild(LyImageCropper, { static: true }) readonly cropper!: LyImageCropper;

  constructor(readonly sRenderer: StyleRenderer) {
  }

  ngOnInit(): void {
    if(this.avatarImageId != null){
      this.loaderConfig.originalDataURL =
        "/library/"
        + this.avatarImageId;
    }
  }

  ngAfterViewInit(): void {
    let config = this.loaderConfig;
    this.cropper.loadImage(config);
  }

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
  }
}
