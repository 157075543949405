import { Component, ContentChild, Input, OnInit, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthenticationPipe } from '../../pipes/authentication/authentication.pipe';
import { AuthenticationOptionsType } from '../../../generated/graphql';

const authenticationPipe: AuthenticationPipe = new AuthenticationPipe();

@Component({
  selector: 'eve-authentication',
  templateUrl: './eve-authentication.component.html',
  styleUrls: ['./eve-authentication.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveAuthenticationComponent),
      multi: true
    }
  ]
})
export class EveAuthenticationComponent implements ControlValueAccessor {

  public controlDisabled=false;
  public AuthenticationOptions: any[] = [{
    label: authenticationPipe.transform(AuthenticationOptionsType.IpAddress),
    value: AuthenticationOptionsType.IpAddress
  },{
    label: authenticationPipe.transform(AuthenticationOptionsType.Username),
    value:AuthenticationOptionsType.Username
  }];

  filteredAuthenticationOption!: string[];
  public selectedAuthenticationOption: any = null;

  public eveModel!: AuthenticationOptionsType
  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  @Input()
  placeholder: string = "Authentication Type";

  onChange: any = () => { }
  onTouch: any = () => { }
  
  constructor() { }
  writeValue(value: AuthenticationOptionsType): void {
    this.eveModel = value;
    this.selectedAuthenticationOption = this.AuthenticationOptions.filter(x => x.value.toLowerCase() === this.eveModel?.toLowerCase())[0];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
   this.controlDisabled = isDisabled
  }

  onClear(){
    this.onNgModelChange(null);
  }

  onNgModelChange(option: any) {
    this.eveModel = option.value;
    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  filterResults($event: any) {
    let filtered : any[] = [];
    let query = $event.query;
   
    for(let i = 0; i < this.AuthenticationOptions.length; i++) {
      let option = this.AuthenticationOptions[i];
      if (option.label.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        filtered.push(option);
      }
    }

    this.filteredAuthenticationOption = [...filtered];
  }
}
