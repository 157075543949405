import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { subscribe } from 'graphql';
import { first, map, Observable, of, refCount, shareReplay } from 'rxjs';
import { AntiForgeryToken } from '../interceptors/export-cons';

const jwtUrl = document.getElementsByTagName('jwt-url')[0]?.attributes?.getNamedItem('value')?.value ?? ""

@Injectable({providedIn: 'root'})
export class AuthService {


  private httpClient: HttpClient;
  public Jwt!: Observable<string>;

  constructor(
    private handler: HttpBackend,
    @Inject(AntiForgeryToken) private antiForgeryToken: string
  ) {

    this.httpClient = new HttpClient(handler);

    let headers = new HttpHeaders();
    headers.append('RequestVerificationToken', antiForgeryToken);




    this.Jwt = new Observable<string>((subscriber) => {
      this.httpClient.get(jwtUrl, { headers: headers, responseType: 'text' }).subscribe(newJwt => {
        subscriber.next(newJwt)
      });
      
      const intervalId = setInterval(() => {
        this.httpClient.get(jwtUrl, { headers: headers, responseType: 'text' }).subscribe(newJwt => {
          subscriber.next(newJwt)
        });
      }, 50000);


      return () => {
        clearInterval(intervalId);
      };

    }).pipe( 
      shareReplay({ bufferSize: 1, refCount: true }),
      first()
    );

    
  }
}

