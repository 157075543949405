import {Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'eve-modal',
  templateUrl: './eve-modal.component.html',
  styleUrls: ['./eve-modal.component.css']
})
export class EveModalComponent implements OnInit {

  display : string = "none";
  @Input() title: string = '';
  @Input() width: string = '650px';
  @Input() height: string = '500px';

  constructor() {}

  ngOnInit(): void {
  }

  showModal(): void {
    this.display = "block";
  }

  handleCancel(): void {
    this.display = "none";
  }

  crop() {

  }
}

