import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {  GetAvailableAreasGQL } from '../../../../generated/graphql';

@Component({
  selector: 'eve-area-number',
  templateUrl: './eve-area-number.component.html',
  styleUrls: ['./eve-area-number.component.css'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveAreaNumberComponent),
      multi: true
    }
  ]
})
export class EveAreaNumberComponent implements ControlValueAccessor {
  
  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

 
  // @Output() eveModelChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Input()
  placeholder: string = "Area";

  @Input()
  eveModel: string | null = null;
  selectedAreaNumber: any;

  
  onChange: any = () => { }
  onTouch: any = () => { }

  
  // need to be changed
  // areaNumberOptions: any[] = [
  //   { label: '5555', value: '5555' },
  //   { label: '6666', value: '6666' },
  //   { label: '7777', value: '7777' }
  // ];
  areaNumberOptions: any[] = [];
  filteredAreaNumberOptions = [...this.areaNumberOptions];

  constructor(private getAvailableAreasGQL: GetAvailableAreasGQL) { 
    this.getAvailableAreasGQL.fetch().subscribe(x=>{
      let tempArray = JSON.parse(JSON.stringify(x.data.availableAreas)) as string[];
      tempArray.forEach(x => {
        this.areaNumberOptions.push({label: x, value: x});
      });
    })
  }

  ngOnInit() {
  }

  onClear(){
    this.onNgModelChange(null);
  }

  filterAreaNumber($event: any) {
    let filtered : any[] = [];
    let query = $event.query;
   
    for(let i = 0; i < this.areaNumberOptions.length; i++) {
      let option = this.areaNumberOptions[i];
      if (option.label.toLowerCase().indexOf(query.toLowerCase()) != -1) {
        filtered.push(option);
      }
    }

    this.filteredAreaNumberOptions = [...filtered];

  }
    
  onNgModelChange(option: any) {
    this.selectedAreaNumber = option;
    this.eveModel = option?.value;
    // this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }
}
