import { Directive, HostListener, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Directive({
  selector: 'a[download="true"]',
  exportAs: 'downloadFile',
})
export class DownloadFileDirective {
  constructor(private readonly httpClient: HttpClient) { }

  public isDownloading: boolean = false;

  @Input('href')
  href!: string;

  @HostListener('click')
  public async onClick(): Promise<void> {
    // Download the document as a blob
    this.isDownloading = true;

    try {
      const getRequest = await this.httpClient.get(
        this.href,
        { responseType: 'blob', observe: 'response' }
      );

      const response = await firstValueFrom(getRequest);

      // Create a URL for the blob
      const url = URL.createObjectURL(response.body ?? new Blob());

      // Create an anchor element to "point" to it
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = "_blank";

      // Get the suggested filename for the file from the response headers

      const disposition = response.headers.get('Content-Disposition');

      if (disposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        let filename = '';
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }

        anchor.download = filename;
      }


      // Simulate a click on our anchor element 
      anchor.click();

      // Discard the object data
      URL.revokeObjectURL(url);

    }
    finally {
      this.isDownloading = false;
    }
  }
}
