<!--<eve-auto-complete [name]="name"-->
<!--                   [placeholder]="placeholder"-->
<!--                   [getResults]="getHelpNumberOptions.bind(this)"-->
<!--                   [autoCompleteOptionDisplay]="'${this.name}'"-->
<!--                   [ngModel]="eveModel"-->
<!--                   (ngModelChange)="onNgModelChange($event)"-->
<!--                   [disabled]="disabled$ | async"-->
<!--                   #helpNumber="ngModel">-->
<!--  <ng-template #eveAutoCompleteItemTemplate let-helpNumber="model">-->
<!--    {{helpNumber.name}}-->
<!--  </ng-template>-->
<!--</eve-auto-complete>-->

<p-autoComplete [ngModel]="eveModel"
                (ngModelChange)="onNgModelChange($event)"
                [suggestions]="filteredDestinations"
                (completeMethod)="filterDestination($event)"
                field="name"
                [minLength]="1"
                [dropdown]="true"
                [showClear]="true"
                [showEmptyMessage]="true"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
                [lazy]="true"
                (onLazyLoad)="onLazyLoad($event)"
                (onClear)="onClear()"
                [disabled]="disabled"
                [placeholder]="placeholder">
  <ng-template let-destination pTemplate="item">
    <div class="destination-row">
      <span class="destination-icon fa destinationType-{{ destination.destinationType }}"></span>
      <div class="destination-label">
        {{ destination.name }}
      </div>
    </div>

  </ng-template>
</p-autoComplete>

<span *ngIf="eveModel != null" class="selected-destination-icon fa destinationType-{{ eveModel?.destinationType }}"></span>

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors [name]="placeholder"></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
  <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>
