
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, debounceTime, of, switchMap } from 'rxjs';

export  class BasicValidation {
  public static getInvalidationMessage = (c: AbstractControl, validationMessages: { [key: string]: string }): string | null => {
    let message: string | null = null;
    if ((c.touched || c.dirty) && c.errors) {
      message = Object.keys(c.errors)
        .map(key => validationMessages[key])
        .join('   ');
    }

    return message;
  }

  
  public static getInvalidationErrorMessage = (formGroup : FormGroup, controlName : string,  inValidationMessages : {
    [key: string]: string
    }, delayTime : number = 500): Observable<string | null> => {
       
        const control = formGroup.get(controlName);
     
        if(control === null){
            return of(null);
        }

        return  control.statusChanges

        .pipe(debounceTime(delayTime), switchMap(() => {
            let invalidationMessage = this.getInvalidationMessage(control, inValidationMessages);
            return of( invalidationMessage);
        }));
    }

}


export class BasicValidations {

  public static number(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isNumber = new RegExp("^(-|)\\d*$").test(control.value);
      return isNumber == false ? { number: { value: control.value } } : null;
    };
  }
}
