import {Injectable} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { BankHoliday, Destination, DestinationInput, Prompt, Schedule, ScheduleAction, ScheduleType } from '../../../../../../generated/graphql';



@Injectable({providedIn: 'root'})
export class ScheduleValidationService {

    constructor(private fb : FormBuilder) {}

  public generateFromGroup(schedule: Schedule): FormGroup<ScheduleFormGroup> {

      var recurring = [];
      if (schedule.monday) recurring.push("Monday");
      if (schedule.tuesday) recurring.push("Tuesday");
      if (schedule.wednesday) recurring.push("Wednesday");
      if (schedule.thursday) recurring.push("Thursday");
      if (schedule.friday) recurring.push("Friday");
      if (schedule.saturday) recurring.push("Saturday");
      if (schedule.sunday) recurring.push("Sunday");


    const formGroup = this.fb.group<ScheduleFormGroup>({
        scheduleId: this.fb.control(schedule.scheduleId),
        scheduleType: this.fb.control(schedule.scheduleType, [Validators.required]),

      recurringSchedule: this.fb.control<string[]>(recurring, [RequiredIfValidatorFunc(f => f.parent?.get('scheduleType')?.value == ScheduleType.Recurring)]),
      bankHolidays: this.fb.control([...schedule.bankHolidays], [RequiredIfValidatorFunc(f => f.parent?.get('scheduleType')?.value == ScheduleType.BankHoliday)]),



      dateFrom: this.fb.control(schedule.dateFrom, [RequiredIfValidatorFunc(f => f.parent?.get('scheduleType')?.value == ScheduleType.Date)]),
        dateTo: this.fb.control(schedule.dateTo, [
          RequiredIfValidatorFunc(f => f.parent?.get('scheduleType')?.value == ScheduleType.Date),
          GreaterThanOrEqualValidatorFunc(f => f.parent?.get('dateTo')?.value, f => f.parent?.get('dateFrom')?.value)
        ]),

        timeFrom: this.fb.control(schedule.timeFrom, [Validators.required]),
        timeTo: this.fb.control(schedule.timeTo, [Validators.required,
          GreaterThanTimSpanValidatorFunc(f => f.parent?.get('timeTo')?.value, f => f.parent?.get('timeFrom')?.value),
          
        ]),


      scheduleAction: this.fb.control(schedule.scheduleAction, [Validators.required]),
      forwardDestination: this.fb.control(schedule.forwardDestination, [RequiredIfValidatorFunc(f => f.parent?.get('scheduleAction')?.value == ScheduleAction.Forward), DestinationValidator]),
        prompt: this.fb.control(schedule.prompt, []),
      },{ updateOn: "blur" });


    formGroup.get("dateFrom")?.valueChanges.subscribe(r => formGroup.get("dateTo")?.updateValueAndValidity());
    formGroup.get("timeFrom")?.valueChanges.subscribe(r => formGroup.get("timeTo")?.updateValueAndValidity());
    formGroup.get("scheduleAction")?.valueChanges.subscribe(r => formGroup.get("forwardNumber")?.updateValueAndValidity());

      return formGroup;
  }

}


export interface ScheduleFormGroup {
  scheduleId: FormControl<number | null>;
  scheduleType: FormControl<ScheduleType | null>;
  recurringSchedule: FormControl<string[] | null>;
  bankHolidays: FormControl<BankHoliday[] | null>;
  dateFrom: FormControl<any | null>;
  dateTo: FormControl<any | null>;
  timeFrom: FormControl<any | null>;
  timeTo: FormControl<any | null>;
  scheduleAction: FormControl<ScheduleAction | null>;
  forwardDestination: FormControl<Destination | null | undefined>;
  prompt: FormControl<Prompt | null | undefined>;
}



export function RequiredIfValidatorFunc(func: (formControl: AbstractControl) => boolean) {

  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }

    if (func(formControl)) {
      return Validators.required(formControl);
    }
    return null;
  }
}


const phoneRegex = /^[0-9]*$/;
export function DestinationValidator(formControl: AbstractControl<DestinationInput>) {


  if (formControl.value == null) return null;
  if (formControl.value.destinationId != null) return null;
  if (phoneRegex.test(formControl.value.name ?? "")) return null;
  return {
    destination: {

    }

  }
}


function LessThanOrEqualValidatorFunc(aFunc: (formControl: AbstractControl) => number | null, bFunc: (formControl: AbstractControl) => number, setError?: (formControl: AbstractControl, validationErrors: ValidationErrors| null) => void| null) {

  return (formControl: AbstractControl) => {


    const a = aFunc(formControl);
    const b = bFunc(formControl);

    if (a == null || b == null || a <= b) {
      if (setError != null) setError(formControl, null);
      return null;
    }


    const error = {
      lessThanOrEqual: {
        value: a,
        lessThanOrEqual: b
      }
    };

    if (setError != null) setError(formControl, error);

    return error;
  }
}



function GreaterThanValidatorFunc(aFunc: (formControl: AbstractControl) => number, bFunc: (formControl: AbstractControl) => number) {

  return (formControl: AbstractControl) => {


    const a = aFunc(formControl);
    const b = bFunc(formControl);

    if (a == null || b == null || a > b) return null;
    return {
      greaterThan: {
        value: a,
        greaterThan: b
      }
    };
  }
}

function GreaterThanOrEqualValidatorFunc(aFunc: (formControl: AbstractControl) => number, bFunc: (formControl: AbstractControl) => number) {

  return (formControl: AbstractControl) => {
    

    const a = aFunc(formControl);
    const b = bFunc(formControl);

    if (a == null || b == null || a >= b) return null;
    return {
      greaterThanOrEqual: {
        value: a,
        greaterThanOrEqual: b
      }
    };
  }
}


function GreaterThanTimSpanValidatorFunc(aFunc: (formControl: AbstractControl) => number | null | undefined, bFunc: (formControl: AbstractControl) => number | null | undefined, setError?: (formControl: AbstractControl, validationErrors: ValidationErrors | null) => void | null): ValidatorFn {

  return (formControl: AbstractControl) => {


    const a = aFunc(formControl);
    const b = bFunc(formControl);

    if (a == null || b == null) return null;

    const aDuration = moment.duration(a);
    const bDuration = moment.duration(b);

    if (aDuration.asSeconds() >= bDuration.asSeconds()) {
      if (setError != null) setError(formControl, null);
      return null;
    }

    const aValue = aDuration.hours() + ":" + aDuration.minutes();
    const bValue = bDuration.hours() + ":" + bDuration.minutes();

    const error = {
      greaterThan: {
        value: aValue,
        greaterThan: bValue
      }
    };

    if (setError != null) setError(formControl, error);

    return error;
  }
}



function LessThanTimSpanValidatorFunc(aFunc: (formControl: AbstractControl) => number | null | undefined, bFunc: (formControl: AbstractControl) => number | null | undefined, setError?: (formControl: AbstractControl, validationErrors: ValidationErrors | null) => void | null): ValidatorFn {

  return (formControl: AbstractControl) => {


    const a = aFunc(formControl);
    const b = bFunc(formControl);

    if (a == null || b == null) return null;

    const aDuration = moment.duration(a);
    const bDuration = moment.duration(b);

    if (aDuration.asSeconds() < bDuration.asSeconds()) {
      if (setError != null) setError(formControl, null);
      return null;
    }

    const error = {
      lessThan: {
        value: a,
        lessThan: b
      }
    };

    if (setError != null) setError(formControl, error);

    return error;
  }
}
