import { Inject, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import { AuthService } from './services/auth.service';
import { firstValueFrom } from 'rxjs';
import { EvePortalCoreUrl } from './interceptors/export-cons';



const errorLink = onError(({ graphQLErrors, networkError }) => {
  debugger;
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});


export function createApollo(authService: AuthService, evePortalCoreUrl: String): ApolloClientOptions<any> {

  const auth = setContext(async (operation: any, context) => {

    var token = await firstValueFrom(authService.Jwt);
    return {
      uri: `${evePortalCoreUrl}/graphql`,
      headers: {
        "eveportal-jwt": token,
      },
    };
  });


  return {
    link: ApolloLink.from([errorLink, auth, createHttpLink()]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [AuthService, EvePortalCoreUrl],
    },
  ]
})
export class GraphQLModule {}
