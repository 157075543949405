import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { MatSliderChange } from "@angular/material/slider";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'eve-slider',
  templateUrl: './eve-slider.component.html',
  styleUrls: ['./eve-slider.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveSliderComponent),
      multi: true
    }
  ]
})

export class EveSliderComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @Input() min: number = 5;
  @Input() max: number = 50;
  @Input() step: number = 5;

  @Input() enforceMax: boolean = true;
  //Initial value as default.
  @Input()
  value!: number;
  @Output()
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  onChange: any = () => { }
  onTouch: any = () => { }



  @Output()
  change: EventEmitter<number> = new EventEmitter<number>();


  isAnimationDisabled: boolean = true;

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;


  constructor() { }

  ngOnInit(): void {
    /* if(this.value === 0 || this.value === undefined){
       this.eveModelChange.emit(this.min);
     }
     else {this.eveModel = this.value;}*/
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isAnimationDisabled = false;
    })
  }

  inputSelected($event: MatSliderChange) {
    if ($event.value == null) return;
    this.value = $event.value;
    this.valueChange.emit(this.value);

    this.onChange(this.value);
    this.onTouch(this.value);
  }



  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.value = value;
  }



  onKeyUp(event: any) {

    if (new RegExp("^\\d+$").test(event.target.value)) {

      let value = parseInt(event.target.value);
      this.value = value;
    }
    else {
      this.value = event.target.value;
    }

    this.valueChange.emit(this.value);
    this.onChange(this.value);
    this.onTouch(this.value);

  }

  onChangeEvent(event: any) {


    let value = parseInt(event.target.value);

    if (isNaN(value) == false) {

      if (value > this.max && this.enforceMax == true) {
        this.value = this.max;
        event.target.value = this.value;
      }
      else if (value < this.min) {
        this.value = this.min;
        event.target.value = this.value;
      }
      else this.value = value;
    }
    else {
      this.value = this.min;
    }


    this.valueChange.emit(this.value);
    this.change.emit(this.value);
    this.onChange(this.value);
    this.onTouch(this.value);

  }
}
