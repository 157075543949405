
<div style="padding-bottom: 30px;" class="clearfix">
  <h1 class="carousel-title">{{title}}</h1>
  <input pInputText (keyup)="onSearchChange($event)" style="float:right; width:267px;" />
</div>
<div class="eveUsersPage" >
  <ng-container *ngIf="page | async as page else loading">



    <div *ngFor="let column of page | keyvalue" [style.width]="(200 / paginator.pageSize) + '%'">

      <ng-container *ngFor="let item of column.value" >
        <ng-container *ngTemplateOutlet="itemTemplate;context:{item}"></ng-container>
      </ng-container>

    </div>

    <span (click)="paginator.previousPage()"
          class="fa fa-solid fa-angle-left eveUsersPaginationControl eveUsersPaginationControl-previous" 
          [attr.disabled]="paginator.hasPreviousPage() == false"></span>
    <span (click)="paginator.nextPage()"
          class="fa fa-solid fa-angle-right eveUsersPaginationControl eveUsersPaginationControl-next"
          [attr.disabled]="paginator.hasNextPage() == false">

    </span>
  </ng-container>
  <ng-template #loading>
    loading
  </ng-template>
</div>
<mat-paginator #paginator [length]="totalCount" [pageSize]="12" [pageSizeOptions]="[8, 12, 14, 18]">
</mat-paginator>
