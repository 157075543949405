import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';


@Component({
  selector: 'eve-call-queue-configuration',
  templateUrl: './eve-call-queue-configuration.component.html',
  styleUrls: ['./eve-call-queue-configuration.component.css'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }
  ]
})
export class EveCallQueueConfigurationComponent {
 

  
  @Input() callQueue!: FormGroup;
  @Input() organisationNode!: String;


}
