import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CallRecording } from '../../../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class EveSipTrunkDetailsValidationService {
  private formGroup!: FormGroup;

  constructor(private fb: FormBuilder) { }
  public generateValidationForm(): FormGroup{

    const formGroup = this.fb.group({
      sipTrunkId:   [null],
      organisationId: [null],            
      name:            [null, [Validators.required]],          
      number:          [null, [Validators.required]],         
      authenticationType: [null, [Validators.required]],        
      authenticationUsername: [null],     
      authenticationPassword: [null], 
      // ipAddress: [null, [Validators.required, ipAddressRequired(f => f.parent?.get('authenticationType')?.value === 'USERNAME')]],   
      // inboundTranslation: [null, [Validators.required]],
      channels:[null, [Validators.required]],                   
      // inboundChannelsLimit: [null, [Validators.required]],      
      // outboundChannelsLimit:  [null, [Validators.required]],    
      sipTrunkPresentation:  [null, [Validators.required]],    
      // callRecording:     [CallRecording.None, [Validators.required]],   
      // busyOnChannelsExceeded:  [false, [Validators.required]],
      
      barringLocal:[false],
      barringMobile:[false],
      barringFreephone:[false],
      barring084:[false],
      barringDirectory:[false],
      barringPremium:[false],
      barringInternational:[false],

      sipTrunkNumberGroup: this.fb.group({
        sipTrunkNumbers: this.fb.array([])
      })

    });
    this.formGroup = formGroup;

    this.formGroup.get('authenticationType')?.valueChanges.subscribe((authenticationType: any)=>{
      if(authenticationType !== 'USERNAME'){
        this.formGroup.addControl('ipAddress', new FormControl(null, [Validators.required, ipAddressRequired(f => f.parent?.get('authenticationType')?.value === 'USERNAME')]));
        this.formGroup.addControl('inboundTranslation', new FormControl(null, [Validators.required]));
        this.formGroup.addControl('inboundChannelsLimit', new FormControl(null, [Validators.required]));
        this.formGroup.addControl('outboundChannelsLimit', new FormControl(null, [Validators.required]));
        this.formGroup.addControl('callRecording', new FormControl(CallRecording.None, [Validators.required]));
        this.formGroup.addControl('busyOnChannelsExceeded', new FormControl(false, [Validators.required]));
      }else{
        this.formGroup.removeControl('ipAddress');
        this.formGroup.removeControl('inboundTranslation');
        this.formGroup.removeControl('inboundChannelsLimit');
        this.formGroup.removeControl('outboundChannelsLimit');
        this.formGroup.removeControl('callRecording');
        this.formGroup.removeControl('busyOnChannelsExceeded');
      }
    });
    return formGroup
  }

  generateValidationSipTrunkNumberGroup = () => {
    return 
    this.fb.group({
      sipTrunkNumbers: this.fb.array([])
    })
  }
  generateArray = () => {
    return this.fb.array([])
  }
  public get sipTrunkNumberGroup():FormGroup{
    return this.formGroup.get("sipTrunkNumberGroup") as FormGroup 
  }

  public get sipTrunkNumberFormArray():FormArray{
    return this.sipTrunkNumberGroup.get("sipTrunkNumbers") as FormArray 
  }

  buildSipTrunkNumberFormGroup = (numberId: any, number: any): FormGroup =>{
    let formgroup = this.fb.group({
      numberId:[numberId],
      number:[number],
      failoverNumber:[null,[Validators.pattern(/^$|^(\+44|0)\d{9,10}$/)]],
      divertNumber:[null,[Validators.pattern(/^$|^(\+44|0)\d{9,10}$/), 
      divertNumberRequired(f => f.parent?.get('isDiverted')?.value)
    ]],
      isDiverted:[false]
    });

    return formgroup;
  }
}

function divertNumberRequired(isDivertedFunc: (formControl: AbstractControl) => boolean | null ){
  return (formControl: AbstractControl) => {
    let isDiverted = isDivertedFunc(formControl);
    var divertNumberValue = formControl?.value;
    let regexp = new RegExp('.+');
    let divertNumberRegResult = regexp.test(divertNumberValue);

    const divertNumberValid =  isDiverted == false || divertNumberRegResult;

    return !(divertNumberValid)? { divertNumberRequired: true} : null;
  }
}

function ipAddressRequired(authenticationTypeFunc:(formControl: AbstractControl)=> boolean | null){
  return (formControl: AbstractControl) => {
    let isUserNameType = authenticationTypeFunc(formControl);
    var ipAddressValue = formControl?.value;

    let regexp = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$');
    let ipAddressRegResult = regexp.test(ipAddressValue);

    const ipAddressValid =  isUserNameType == true || (ipAddressValue !== null && ipAddressValue !== '' && ipAddressRegResult === true) ;

    return !(ipAddressValid)? { ipAddressValidationRequired: true} : null;

  }
}
