import { Component, ContentChild, EventEmitter, forwardRef, Injector, Input, OnInit, Output, TemplateRef} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgModel, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Observable, of, startWith } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ObservableInput } from 'ngx-observable-input';

@Component({
  selector: 'eve-validation-errors',
  templateUrl: './eve-validation-errors.component.html',
  styleUrls: ['./eve-validation-errors.component.css']
 
})

export class EveValidationErrorsComponent implements OnInit   {


  @Input()
  validationControl!: NgModel | null 

  @Input()
  name!:String | null



  constructor(private injector: Injector) {

     
  }
  ngOnInit(): void {

    if (this.validationControl == null) {
      this.validationControl = this.injector.get(NgControl, null) as NgModel;
    }
  }

}
