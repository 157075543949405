import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'eve-internal-number-ranges',
  templateUrl: './eve-internal-number-ranges.component.html',
  styleUrls: ['./eve-internal-number-ranges.component.css'],
 
})
export class EveInteralNumberRangesComponent implements OnInit {

  internalNumberRanges: String[] = ["", "", "",""]


 
  constructor() {
  }

  ngOnInit(): void {
  }



  onAddClick() {
    this.internalNumberRanges.push("");
  }
 
}
