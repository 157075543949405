import { Directive } from '@angular/core';
import { Host, Self, Optional } from '@angular/core';
import { Calendar } from 'primeng/calendar';

@Directive({
  selector: 'p-calendar',
})
export class PrimeNgCalendarShadowdomFix {

  constructor(
    @Host() @Self() @Optional() public hostSel: Calendar) {
  
    let app = (<any>hostSel)._app;



    hostSel.bindDocumentClickListener = () => {
      if (!hostSel.documentClickListener) {
        setTimeout(() => {


          const documentTarget = hostSel.el ? (hostSel.el.nativeElement.closest("[shadowdomRoot]") ?? hostSel.el.nativeElement.ownerDocument) : 'document';
          hostSel.documentClickListener = hostSel.renderer.listen(documentTarget, 'mousedown', (event) => {
            if (hostSel.isOutsideClicked(event) && hostSel.overlayVisible) {
              setTimeout(() => {
                hostSel.hideOverlay();
                hostSel.onClickOutside.emit(event);
                hostSel.cd.markForCheck();
              });
            }
          });
        });
      }
    }
  }
}
