
<p-autoComplete [ngModel]="timeZone" class="eveInput"
                (ngModelChange)="onNgModelChange($event)"
                [suggestions]="filtered"
                (completeMethod)="filter($event)"
                [minLength]="1"
                [dropdown]="true"
                [showClear]="timeZone != null"
                [showEmptyMessage]="true"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
                [lazy]="true"
                (onLazyLoad)="onLazyLoad($event)"
                (onClear)="onClear()"
                placeholder="Timezone"
                [forceSelection]="true"
                styleClass="w-full">
</p-autoComplete>

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
  <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>
