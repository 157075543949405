import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {GetNumbersGQL, GetTimeZonesGQL, GetTimeZonesQuery} from "../../../generated/graphql";
import {map} from "rxjs/operators";
import {BehaviorSubject, lastValueFrom, Observable, ReplaySubject} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AutoComplete} from "primeng/autocomplete";
import { ApolloQueryResult } from '@apollo/client/core/types';

@Component({
  selector: 'eve-timezone-autocomplete',
  templateUrl: './eve-timezone-autocomplete.component.html',
  styleUrls: ['./eve-timezone-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveTimeZoneAutocompleteComponent),
      multi: true
    }
  ]
})
export class EveTimeZoneAutocompleteComponent implements ControlValueAccessor {


  filtered!: any[]|undefined;

  @Input() placeholder : string = '';
  @Input() name : string = '';

  @Input()timeZone!: string;
  @Output() timeZoneChange: EventEmitter<string> = new EventEmitter<string>();


  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  onChange: any = () => { }
  onTouch: any = () => { }


  search:string = "";

  constructor(public getTimeZonesGQL:GetTimeZonesGQL) { }

  filter(event: any) {
    this.search = event.query;

    const source = this.getTimeZonesGQL.fetch({
      name: this.search
    }).pipe(
      map((response: ApolloQueryResult<GetTimeZonesQuery>) => {

        const page = response.data.timeZones?.items?.map(i => i.name) ?? [];
        // @ts-ignore
        const results = [...page];
        // @ts-ignore
        results.length = response.data.timeZones?.totalCount ?? 0;

        return results;
      })
    ).subscribe(n => {

      this.filtered = n;

    });

  }

  onLazyLoad($event:any){
    const search = this.search;

    var source = this.getTimeZonesGQL.fetch({
      name: search,
      skip: $event.first,
      take:$event.last - $event.first,
    }).pipe(
      map(response =>{
        const page = response.data.timeZones?.items?.map(i => i.name) ?? [];
        return page;
      })
    ).subscribe(page => {

      if (search != this.search) return;

      page.forEach((item, i) => {
        if (this.filtered == null) return;
        this.filtered[$event.first + i] = item;
      });
      // @ts-ignore
      this.filtered = [...this.filtered];
    });

  }

  onClear(){
    this.onNgModelChange(null);
  }

  onNgModelChange(prompt: any) {

    this.timeZone = prompt;
    this.timeZoneChange.emit(this.timeZone);

    this.onChange(this.timeZone);
    this.onTouch(this.timeZone);
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.timeZone = value;
  }
}



export const getCompanyId = (organisationNode: String|null):number => {
  if (organisationNode == null) return -1;
  const companyId = organisationNode.split("/")[4];
  return parseInt(companyId);
}
