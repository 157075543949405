import {Injectable} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CallQueue } from 'src/generated/graphql';
import { ScheduleFormGroup, ScheduleValidationService } from './eve-select-dates/eve-select-dates-validation.service';


@Injectable({providedIn: 'root'})
export class SchedulingValidationService {

  constructor(
    private fb: FormBuilder,
    private scheduleValidationService: ScheduleValidationService
  ) { }

  public generateValidationForm(callQueue: CallQueue): FormGroup<SchedulingFormGroup> {
    const formGroup = this.fb.group<SchedulingFormGroup>({
         
       timezone: this.fb.control(
           callQueue.schedules[0]?.timeZoneString ?? "Europe/London",
              [Validators.required]
        ),
        schedules: this.fb.array((callQueue?.schedules.sort((a, b) => a.priority - b.priority).map(s => this.scheduleValidationService.generateFromGroup(s)) ?? [])),
      });

     
      return formGroup;
  }

}



export interface SchedulingFormGroup {

  timezone: FormControl<string| null>;
  schedules: FormArray<FormGroup<ScheduleFormGroup>>;
}
