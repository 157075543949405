import {Component, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, takeUntil} from 'rxjs';
import * as moment from 'moment';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  overlayRef = this.overlay.create({
    positionStrategy: this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically(),
    hasBackdrop: false
  })

  constructor(private overlay: Overlay) {
  }

  showLoader() {
    this.overlayRef.attach(new ComponentPortal(LoaderComponent))
  }

  hideLoader() {
    this.overlayRef.detach()
  }
}



@Component({
  selector: 'app-loader',
  template: `
        <div>
        </div>
    `,
  styles: [`
        
        div{
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba( 255, 255, 255, 0.8) url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAUAAAAALAAAAAABAAEAAAICRAEAOw==) /*../../Images/loading.gif;; 50% 50% no-repeat; */;
        }
    `
  ]
})
export class LoaderComponent {
}
