import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CallRecording } from '../../../generated/graphql';
import { EvePortalCoreUrl } from '../../interceptors/export-cons';


@Pipe({name: 'evePortalCoreUrl'})
export class EvePortalCoreUrlPipe implements PipeTransform {

  constructor(@Inject(EvePortalCoreUrl) private evePortalCoreUrl: string) {


  }

  transform(value: string): string {
    
    return `${this.evePortalCoreUrl ?? ""}${value}`;
  }
}



