<form class="example-form" >
  <div style="display:flex">

    <mat-form-field appearance="fill">
      <mat-select [value]="ngModel" (valueChange)="onOptionSelected($event)">
        <mat-option *ngFor="let option of options" [value]="option.value">
          <ng-container *ngIf="eveSelectItemTemplate != null">
            <ng-container *ngTemplateOutlet="eveSelectItemTemplate;context:{model:option}"></ng-container>
          </ng-container>

          <ng-container *ngIf="eveSelectItemTemplate == null">
            {{ option }}
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</form>


