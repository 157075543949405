<span *ngIf="validationControl?.invalid && validationControl?.errors != null && (validationControl?.dirty || validationControl?.touched)" class="tooltip-error field-validation-error">



  <span *ngIf="validationControl?.errors?.['required']">
    {{ name ?? validationControl?.name  }} required
  </span>

  <span *ngIf="validationControl?.errors?.['maxlength']">
    Max length {{validationControl?.errors?.['maxlength'].requiredLength}} characters
  </span>

  <span *ngIf="validationControl?.errors?.['greaterThanOrEqual']">
    Must be greater than or equal to {{validationControl?.errors?.['greaterThanOrEqual'].greaterThanOrEqual}}
  </span>

  <span *ngIf="validationControl?.errors?.['min']">
    Must be greater than or equal to {{validationControl?.errors?.['min'].min}}
  </span>

  <span *ngIf="validationControl?.errors?.['greaterThan']">
    Must be greater than {{validationControl?.errors?.['greaterThan'].greaterThan}}
  </span>

  <span *ngIf="validationControl?.errors?.['lessThanOrEqual']">
    Must be less than or equal to {{validationControl?.errors?.['lessThanOrEqual'].lessThanOrEqual}}
  </span>

  <span *ngIf="validationControl?.errors?.['max']">
    Must be less than or equal to {{validationControl?.errors?.['max'].max}}
  </span>

  <span *ngIf="validationControl?.errors?.['lessThan']">
    Must be less than {{validationControl?.errors?.['lessThan'].lessThan}}
  </span>


  <span *ngIf="validationControl?.errors?.['nameUnique']">
    The name <b>{{validationControl?.errors?.['nameUnique'].value}}</b>
    already exists.
  </span>


  <span *ngIf="validationControl?.errors?.['destination']">
    Invalid destination
  </span>

  <span *ngIf="validationControl?.errors?.['overlappingranges']">
    Over lapping ranges
  </span>

  <span *ngIf="validationControl?.errors?.['reservedNumber']">
    You have selected a number that is reserved. For a full list of reserved numbers please <a href="https://mailchi.mp/b091d0622e9d/evereservednumbers" target="_blank" style="color: grey;">click here</a>
  </span>

  <span *ngIf="validationControl?.errors?.['promptUniqName']">
    The name <b>{{validationControl?.errors?.['promptUniqName'].value}}</b>
    already exists.
  </span>

  <span *ngIf="validationControl?.errors?.['internalNumberLength']">
    Internal range must be {{validationControl?.errors?.['internalNumberLength'].value}} numbers.
  </span>


  <span *ngIf="validationControl?.errors?.['number']">
    The field must be a number
  </span>

  <span *ngIf="validationControl?.errors?.['queueSize']">
    Must not be greater than Queue Size
  </span>

  <!-- this ng content doesnt seem to work correctly :( -->
  <ng-content></ng-content>
</span>
