import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  Observable, startWith } from 'rxjs';
import { CallQueue, Destination } from 'src/generated/graphql';
import { BasicValidations } from '../../../../helpers/validations/basic-validation';
import { DestinationValidator, RequiredIfValidatorFunc } from '../scheduling/eve-select-dates/eve-select-dates-validation.service';



@Injectable({ providedIn: 'root' })
export class EveCallQueueAgentSettingsValidationService {

  constructor(private fb: FormBuilder) { }

  public generateValidationForm(callQueue: CallQueue): FormGroup<EveCallQueueAgentSettingsFormGroup> {
    const formGroup = this.fb.group<EveCallQueueAgentSettingsFormGroup>({
      ringingAgents: this.fb.control(
        callQueue.ringingAgents,
        [Validators.required, BasicValidations.number(), Validators.min(1), Validators.max(60)]
      ),

      agentTimeOut: this.fb.control(
        callQueue.agentTimeOut,
        [Validators.required, BasicValidations.number(), Validators.min(1), Validators.max(179)]
      ),

      checkAvailablePresence: this.fb.control(
        callQueue.checkAvailablePresence,
        [Validators.required]
      ),

      wrapUpTime: this.fb.control(
        callQueue.wrapUpTime,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(120)]
      ),

      missedCallsLogout: this.fb.control(
        callQueue.missedCallsLogout,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(120)]
      ),

      helpNumber: this.fb.control(
        callQueue.helpNumber,
        [DestinationValidator]
      ),

      useLongestWait: this.fb.control(
        callQueue.useLongestWait,
        [Validators.required]
      ),

      excludeMobile: this.fb.control(
        callQueue.excludeMobile,
        [Validators.required]
      ),

      showInSearch: this.fb.control(
        callQueue.showInSearch,
        [Validators.required]
      ),

      maxCallsInQueue: this.fb.control(
        callQueue.maxCallsInQueue,
        [Validators.required, BasicValidations.number(), Validators.min(1),
          (control: AbstractControl) => {

            const queueSize = control.parent?.parent?.get("details")?.get("queueSize")?.value;
            if (control.value > queueSize) return { queueSize: { value: control.value, queueSize } };
            return null
          }
        ]
        ),

      maxWaitTime: this.fb.control(
        callQueue.maxWaitTime,
        [Validators.required, BasicValidations.number(), Validators.min(1), Validators.max(240)]
      ),

      queueFullSendToBusy: this.fb.control(
        callQueue.queueFullSendToBusy,
        [Validators.required]
      ),

      queueFullForwardTo: this.fb.control(
        callQueue.queueFullForwardTo,
        [RequiredIfValidatorFunc(f => f.parent?.get("queueFullSendToBusy")?.value == false), DestinationValidator]
      ),

      maxAgentAttempts: this.fb.control(
        callQueue.maxAgentAttempts,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(30)]
      ),

      maxAgentAttemptsFallback: this.fb.control(
        callQueue.maxAgentAttemptsFallback,
        [RequiredIfValidatorFunc(f => f.parent?.get("maxAgentAttempts")?.value  > 0), DestinationValidator]
      ),

      forwardCallsAfter: this.fb.control(
        callQueue.forwardCallsAfter,
        [Validators.required, BasicValidations.number(), Validators.min(0), Validators.max(120)]
      ),

      forwardCallsTo: this.fb.control(
        callQueue.forwardCallsTo,
        [RequiredIfValidatorFunc(f => f.parent?.get("forwardCallsAfter")?.value > 0), DestinationValidator]
      )

    });


    const queueFullSendToBusyControl = formGroup.get("queueFullSendToBusy");
    queueFullSendToBusyControl?.valueChanges.pipe(startWith(queueFullSendToBusyControl.value)).subscribe((sendToBusy) => {
      const queueFullForwardToControl = formGroup.get("queueFullForwardTo");

      if (sendToBusy) queueFullForwardToControl?.disable();
      else queueFullForwardToControl?.enable();

      queueFullForwardToControl?.updateValueAndValidity();
    });

    const maxAgentAttemptsControl = formGroup.get("maxAgentAttempts");
    maxAgentAttemptsControl?.valueChanges.pipe(startWith(maxAgentAttemptsControl.value)).subscribe((maxAgentAttempts) => {
      const maxAgentAttemptsFallbackControl = formGroup.get("maxAgentAttemptsFallback");

      if (maxAgentAttempts == 0) maxAgentAttemptsFallbackControl?.disable();
      else maxAgentAttemptsFallbackControl?.enable();
      maxAgentAttemptsFallbackControl?.updateValueAndValidity();
    });


    const forwardCallsAfterControl = formGroup.get("forwardCallsAfter");
    forwardCallsAfterControl?.valueChanges.pipe(startWith(forwardCallsAfterControl.value)).subscribe((forwardCallsAfter) => {
      const forwardCallsToControl = formGroup.get("forwardCallsTo");

      if (forwardCallsAfter == 0) forwardCallsToControl?.disable();
      else forwardCallsToControl?.enable();
      forwardCallsToControl?.updateValueAndValidity();
    });

    return formGroup;
  }

}






export interface EveCallQueueAgentSettingsFormGroup {

  ringingAgents: FormControl<number | null>;
  agentTimeOut: FormControl<number | null>;
  checkAvailablePresence: FormControl<boolean | null>;
  wrapUpTime: FormControl<number | null>;
  missedCallsLogout: FormControl<number | null>;
  helpNumber: FormControl<Destination | null | undefined>;
  useLongestWait: FormControl<boolean | null>;
  excludeMobile: FormControl<boolean | null>;
  showInSearch: FormControl<boolean | null>;
  maxCallsInQueue: FormControl<number | null>;
  maxWaitTime: FormControl<number | null>;
  queueFullSendToBusy: FormControl<boolean | null>;
  queueFullForwardTo: FormControl<Destination | null | undefined>;
  maxAgentAttempts: FormControl<number | null>;
  maxAgentAttemptsFallback: FormControl<Destination | null | undefined>;
  forwardCallsAfter: FormControl<number | null>;
  forwardCallsTo: FormControl<Destination | null | undefined>;
}
