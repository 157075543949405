import { Component, Input, ViewChild } from '@angular/core';
import { CallQueue, DistributionType } from "../../../../../generated/graphql";
import { ControlContainer, NgForm, FormGroup } from '@angular/forms';

@Component({
  selector: 'eve-call-queue-agent-settings',
  templateUrl: './eve-call-queue-agent-settings.component.html',
  styleUrls: ['./eve-call-queue-agent-settings.component.css'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }
  ]
})
export class EveCallQueueAgentSettingsComponent {

  @Input() callQueue!: FormGroup;

  @Input() organisationNode!: string;



  distributionType = DistributionType;

  constructor() {  }
}
