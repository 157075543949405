
<ng-container [formGroup]="callQueue">

  <eve-container title='Agent Settings' icon="fa fa-phone-square">


    <div class="container">
      <div class="row input-group-row  ">
        <div class="label">
          Ringing Agents
        </div>
        <div class="col-4">
          <eve-slider name="Ringing Agents"
                      formControlName="ringingAgents"
                      [min]=1
                      [max]=60
                      [step]="1">
          </eve-slider>
        </div>
      </div>


      <div class="row input-group-row ">
        <div class="label">
          Agent Timeout
        </div>
        <div class="col-4">
          <eve-slider name="Agent Timeout"
                      formControlName="agentTimeOut"
                      [min]=1
                      [max]=179
                      [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Seconds
        </div>
      </div>

      <div class="row input-group-row ">
        <div class="label">
          Check Available Presence
        </div>
        <div class="col-4">
          <eve-toggle name="Check Available Presence" formControlName="checkAvailablePresence"></eve-toggle>
        </div>
      </div>

      <div class="row input-group-row " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Wrap up Time
        </div>
        <div class="col-4">
          <eve-slider name="Wrap up Time"
                      formControlName="wrapUpTime"
                      [min]=0
                      [max]=120
                      [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Seconds
        </div>
      </div>


      <div class="row input-group-row " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Missed Call Log Out
        </div>
        <div class="col-4">
          <eve-slider name="Missed Call Log Out"
                      formControlName="missedCallsLogout"
                      [min]=0
                      [max]=120
                      [step]="1">
          </eve-slider>
        </div>
      </div>


      <div class="row input-group-row " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="label">
          Help Number
        </div>
        <div class="col-5">
          <eve-destination name="Help Number"
                           placeholder="Help Number"
                           formControlName="helpNumber"
                           [organisationNode]="organisationNode">
          </eve-destination>
        </div>
      </div>

      <div class="row input-group-row " *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false && callQueue.parent?.get('details')?.get('distributionType')?.value == distributionType.Skills">
        <div class="label">
          Use Longest Wait
        </div>
        <div class="col-4">
          <eve-toggle name="Use Longest Wait" formControlName="useLongestWait"></eve-toggle>
        </div>
      </div>

      <div class="row input-group-row ">
        <div class="label">
          Exclude Mobile
        </div>
        <div class="col-4">
          <eve-toggle name="Exclude Mobile" formControlName="excludeMobile"></eve-toggle>
        </div>
      </div>
    </div>
  </eve-container>

  <eve-container title="Queue Settings" icon="fa fa-phone-square">

    <div class="container">
      <div class="row input-group-row ">
        <div class="label">
          Show In Contacts
        </div>
        <div class="col-4">
          <eve-toggle name="Show In Contacts" formControlName="showInSearch"></eve-toggle>
        </div>
      </div>

      <div class="row input-group-row ">
        <div class="label">
          Max Calls In Queue
        </div>
        <div class="col-4">
          <eve-slider name="Max Calls In Queue"
                      formControlName="maxCallsInQueue"
                      [min]=0
                      [max]=10
                      [step]="1"
                      [enforceMax]="false">
          </eve-slider>
        </div>
      </div>

      <div class="row input-group-row ">
        <div class="label">
          Max Wait Time
        </div>
        <div class="col-4">

          

          <eve-slider name="Max Wait Time"
                      formControlName="maxWaitTime"
                      [min]=1
                      [max]=240
                      [step]="1">
          </eve-slider>
        </div>
        <div class="col">
          Minutes
        </div>
      </div>
      <div class="row input-group-row ">
        <div class="label">
          Queue Full
        </div>
      </div>
      <div class="row input-group-row ">
        <div class="sub-label"> 
          Send To Busy
        </div>
        <div class="col-4">
          <eve-toggle name="Send To Busy" formControlName="queueFullSendToBusy"></eve-toggle>
        </div>
      </div>
      <div class="row input-group-row ">
        <div class="sub-label">
          Forward To
        </div>
        <div class="col-5">
          <eve-destination name="Queue Full Forward To"
                           placeholder="Forward To"
                           formControlName="queueFullForwardTo"
                           [organisationNode]="organisationNode">
          </eve-destination>
        </div>
      </div>
      <div class="row input-group-row ">
        <div class="label">
          Max Agent Attempts
        </div>
        <div class="col-4">
          <eve-slider name="Max Agent Attempts"
                      formControlName="maxAgentAttempts"
                      [min]=0
                      [max]=30
                      [step]="1">
          </eve-slider>
        </div>
      </div>
      <div class="row input-group-row ">
        <div class="label">
          No Agents Available
        </div>
        <div class="col-5">
          <eve-destination name="No Agents Available"
                           placeholder="No Agents Available"
                           [organisationNode]="organisationNode"
                           formControlName="maxAgentAttemptsFallback">
          </eve-destination>
        </div>
      </div>
      <ng-container *ngIf="callQueue.parent?.get('details')?.get('isLight')?.value == false">
        <div class="row input-group-row ">
          <div class="label">
            Forward Call After
          </div>
          <div class="col-4">
            <eve-slider name="Forward Call After"
                        formControlName="forwardCallsAfter"
                        [min]=0
                        [max]=120
                        [step]="1">
            </eve-slider>
          </div>
          <div class="col">
            Minutes
          </div>
        </div>
        <div class="row input-group-row ">
          <div class="label">
            Forward To
          </div>
          <div class="col-5">
            <eve-destination name="Forward To"
                             placeholder="Forward To"
                             formControlName="forwardCallsTo"
                             [organisationNode]="organisationNode">
            </eve-destination>
          </div>
        </div>
      </ng-container>
    </div>
  </eve-container>
</ng-container>
