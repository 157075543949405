import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";
import { GraphQLModule } from './graphql.module';
import { ToastrModule } from 'ngx-toastr';


// Eve components
import { EveComponent } from './eve.component';

// Pages
import { EvePagesModule }  from "./pages/eve-pages.module";
import { EveNotFoundComponent } from "./pages/not-found/eve-not-found.component";
import {DirectivesModule} from "./directives/directives.module";
import {ReactiveFormsModule} from "@angular/forms";
import { LyHammerGestureConfig, LY_THEME, LY_THEME_NAME, StyleRenderer, LyTheme2 } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import {MatDialogModule} from "@angular/material/dialog";
import { MatPaginatorModule } from '@angular/material/paginator';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PipesModule } from './pipes/pipes.module';
import { APP_BASE_HREF } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { WebComponentOverlayContainer } from './services/eve-time-picker.component';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { EvePortalCoreUrl } from './interceptors/export-cons';
import { LoaderComponent } from './services/loader.service';




const evePortalCoreUrl = document.getElementsByTagName('eve-portal-core-url')[0]?.attributes?.getNamedItem('value')?.value ?? ""


@NgModule({
  declarations: [
    EveComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([{
          path: '**',
          component: EveNotFoundComponent
        }]
    ),
    HttpClientModule,
    ToastrModule.forRoot(),
    GraphQLModule,
    EvePagesModule,
    DirectivesModule,
    ReactiveFormsModule,
    HammerModule,
    MatDialogModule,
    MatPaginatorModule,
    FontAwesomeModule,
    InterceptorsModule,
    PipesModule,
     
  ],
  providers: [
    { provide: EvePortalCoreUrl, useValue: evePortalCoreUrl },
   
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    StyleRenderer,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    {
      provide: OverlayContainer,
      useClass: WebComponentOverlayContainer,
    }
  ],
  bootstrap: [EveComponent]
})
export class EveModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components

    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
  }
}
