<mat-slider class="col-9" [min]=min [max]=max
            [value]="value" [step]=step
            (input)="inputSelected($event)"
            [ngClass]="{'disableAnimation':isAnimationDisabled}">
</mat-slider>
<input class="col-3" pInputText [ngModel]="value" (keyup)="onKeyUp($event)" (change)="onChangeEvent($event)">

<ng-container *ngIf="validationTemplate == null">
  <eve-validation-errors></eve-validation-errors>
</ng-container>
<ng-container *ngIf="validationTemplate != null">
  <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>
</ng-container>
