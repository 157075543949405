import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, forwardRef, ViewChildren, ElementRef, QueryList, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { faStar, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CallQueue, CallQueueInput, CallQueueUser, CallQueueUserInput, EveUser } from "../../../../../../../generated/graphql";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Sortable, { Options, SortableEvent } from 'sortablejs';

@Component({
  selector: 'skill-rating',
  templateUrl: './skill-rating.component.html',
  styleUrls: ['./skill-rating.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SkillRatingComponent),
      multi: true
    }
  ]
})

export class SkillRatingComponent implements ControlValueAccessor {
 
  @Input()
  skillRating!: SkillRating;


  @Output()
  skillRatingChange: EventEmitter<SkillRating> = new EventEmitter<SkillRating>();

  onChange: any = () => { }
  onTouch: any = () => { }


  constructor(){
  }
  

  ngOnInit(): void {
    
  }

  drop(event: CdkDragDrop<CallQueueUser[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }


    this.skillRatingChange.emit(this.skillRating);
    this.onChange(this.skillRating);
    this.onTouch(this.skillRating);
   
  }



  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.skillRating = value;
  }


   
  setDisabledState?(isDisabled: boolean): void {
  }


 

  onEnd = (event: SortableEvent) => {
    this.skillRatingChange.emit(this.skillRating);
    this.onChange(this.skillRating);
    this.onTouch(this.skillRating);
  }




  unallocatedOptions: Options = {
    group: {
      name: 'shared',
      put: false
    },
    animation: 150,
    sort: false,
    //  onEnd: this.onEnd
    onEnd: this.onEnd
  };

  skillListOptions: Options = {
    group: 'shared',
    animation: 150,
    // onEnd: this.onEnd
    onEnd: this.onEnd
  };
}



export interface SkillRating {
  "skillRating1": EveUser[],
  "skillRating2": EveUser[],
  "skillRating3": EveUser[],
  "skillRating4": EveUser[],
  "skillRating5": EveUser[],
  "unallocated": EveUser[],
}
