import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NgxMaterialTimepickerTheme} from "ngx-material-timepicker";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import moment from 'moment';

@Component({
  selector: 'eve-time-picker',
  templateUrl: './eve-time-picker.component.html',
  styleUrls: ['./eve-time-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveTimePickerComponent),
      multi: true
    }
  ]
})
export class EveTimePickerComponent implements OnInit, ControlValueAccessor {

  eveTimePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffffff',
      buttonColor: '#00B3CE'
    },
    dial: {
      dialBackgroundColor: 'rgba(231,229,229,0.21)',
    },
    clockFace: {
      clockFaceBackgroundColor: 'rgba(231,229,229,0.21)',
      clockHandColor: '#167bc5',
      clockFaceTimeInactiveColor: 'grey'
    }
  };

  


  _ngModel!: string;

  get ngModel(): string {
    return this._ngModel;
  }

  @Input()
  set ngModel(value: string) {
    this._ngModel = value;
    var t = moment.duration(value);
    this.time = t.hours() + ":" + t.minutes();
  }
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();


 

 

  @Input()
  disabled: boolean = false;

  time!: string;

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor() { }

  ngOnInit(): void {
  }

  setTime(time: string) {
    this.time = time;
    var t = moment.duration(time);

    this._ngModel = t.toISOString();
    this.ngModelChange.emit(this._ngModel)

    this.onChange(this._ngModel);
    this.onTouch(this._ngModel);
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.ngModel = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
