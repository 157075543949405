import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { interval, Observable, Subject, lastValueFrom, firstValueFrom } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounceTime, map, switchMap, take } from "rxjs/operators";
import { GetPromptsGQL, GetPromptsQuery } from "../../../generated/graphql";
import { ApolloQueryResult } from '@apollo/client/core/types';
import { MatDialog } from '@angular/material/dialog';
import { EvePromptManagerDailogComponent } from '../eve-prompt-manager/eve-prompt-manager-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { EvePortalCoreUrlPipe } from '../../pipes/eve-portal-core-url/eve-portal-core-url.pipe';


@Component({
  selector: 'eve-prompt',
  templateUrl: './eve-prompt.component.html',
  styleUrls: ['./eve-prompt.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvePromptComponent),
      multi: true
    }
  ]
})
export class EvePromptComponent implements  ControlValueAccessor{

  @Input() placeholder = 'Prompt';

  @Input()eveModel!: any;
  @Output() eveModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  organisationNode: string|null = null;



  $player!: HTMLAudioElement;
  isPlaying : boolean = false;

  @ViewChild('audio') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.$player = ref.nativeElement;
  }


  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  onChange: any = () => {}
  onTouch: any = () => { }


  search: Subject<string> = new Subject<string>();
  prompts: Observable<any[]>;

  constructor(
    private readonly httpClient: HttpClient,
    public getPromptsGQL: GetPromptsGQL,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private evePortalCoreUrlPipe: EvePortalCoreUrlPipe
  ) {


    this.prompts = this.search.pipe(
      debounceTime(300),
      switchMap(search =>
        this.getPromptsGQL.fetch({
          organisationNode: this.organisationNode,
          name: search,
        }, {
          fetchPolicy: 'network-only'
        }).pipe(
          map((response: ApolloQueryResult<GetPromptsQuery>) => {
            return [...(response.data.prompts?.items ?? [])]
          })
        )
      )
    )
  }

  filter(event: any) {
    this.search.next(event.query);
  }


  onNgModelChange(prompt:any){
    this.eveModel = prompt;
    this.isPlaying = false;
    this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);

    console.log('playing music id:', this.eveModel?.promptId ?? 'nothing');
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }

  async playPrompt() {

    if (this.eveModel == null) return;

    if ((this.$player as any).promptId == this.eveModel.promptId) {
      this.$player.play();
      return;
    }

    try {
      const getRequest = await this.httpClient.get(
        this.evePortalCoreUrlPipe.transform('/prompt/' + this.eveModel.promptId),
        { responseType: 'blob', observe: 'response' }
      );

      const response = await firstValueFrom(getRequest);

      // Create a URL for the blob
      const url = URL.createObjectURL(response.body ?? new Blob());
      this.$player.src = url;

      this.$player.addEventListener("play", async () => { 
        this.isPlaying = true;
      });


      this.$player.addEventListener("pause", async () => {
        this.isPlaying = false;
      });
      this.$player.addEventListener("ended", async () => {
        this.isPlaying = false;
      });

      this.$player.play();

      (this.$player as any).promptId = this.eveModel.promptId;
    }
    catch (ex: any) {
      this.toastr.error(`Failed to play prompt - ` + ex.message ?? ex);
    }
  }

  pausePrompt() {
    this.$player.pause()
    this.isPlaying = false;
  }

  onOpenPromptManager() {

    const dialogRef = this.dialog.open(EvePromptManagerDailogComponent, {
      data: { organisationNode: getCompanyNode(this.organisationNode) },
      minWidth: 800,
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });

  }
}


export const getCompanyNode = (organisationNode: String | null): String | null => {
  debugger
  if (organisationNode == null) return null;
  const organisationNodeParts = organisationNode.split("/");
  if (organisationNodeParts.length == 6) return organisationNode;

  return `/${organisationNodeParts[1]}/${organisationNodeParts[2]}/${organisationNodeParts[3]}/${organisationNodeParts[4]}/`;
}
