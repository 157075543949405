import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvePortalCoreUrlPipe } from '../../../pipes/eve-portal-core-url/eve-portal-core-url.pipe';
import { IImageModel } from './i-image-model';

@Injectable({
  providedIn: 'root'
})
export class EveAvatarService {


  public base64String: string | null = null;

  public left: number | null = null;
  public top: number | null = null;
  public width: number | null = null;
  public height: number | null = null;

  public fileName! : string;

  constructor(
    private httpClient: HttpClient,
    private evePortalCoreUrlPipe: EvePortalCoreUrlPipe
  ) { }

  public uploadImage(organisationNode: string, image: IImageModel): Observable<any>{
    const url = this.evePortalCoreUrlPipe.transform(`/image?` + 'organisationNode=' + encodeURIComponent(organisationNode));

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

    return this.httpClient.post(
      url,
      image, 
      { 'headers': headers }
    ).pipe(
        map((response: any) => {
          return response.imageId;
        })
      )
      
  }

}
