<div class="avatar-header">
  <div class="avatar-header-title">
    Edit Avatar
  </div>
</div>
<div mat-dialog-content>
  <div class="eve-imageupload-main eve-imageupload-main-setup">
    <div  id="grid" >
      <div id="current-avatar">
        <div class="avatar-upload-current">
          <div class="avatar-upload-current">
     
            <img *ngIf="avatarImageId != null" class="avatar-upload-user-avatar img-circle" src="{{ '/library/' + avatarImageId | evePortalCoreUrl }}" >
            <img *ngIf="avatarImageId == null" class="avatar-upload-user-avatar img-circle" src="{{ '/assets/images/Blank.png'  | evePortalCoreUrl }}">
      
            <span>Current Avatar</span> 
        </div>
      </div>

      </div>
      <div id="edit-avatar">
        <div class="eve-imageupload-preview">
          <img class="eve-imageupload-preview-drag ui-draggable ui-draggable-handle" #drag src="assets/images/Blank.png">
          
          <div class="eve-imageupload-preview-overlay"></div>
          <img class="eve-imageupload-preview-image" #img src="{{ '/assets/images/Blank.png'  | evePortalCoreUrl }}">
        </div>
      </div>
      <div id="right-menu" class="avatar-upload-resize">
        <button type="button" #zoomIn class="evebutton" id="btn-zoom-in" (click)="onZoomInClick()">+</button>
        <button type="button" class="evebutton" id="btn-zoom-out" (click)="onZoomOutClick()">-</button>
        <span class="btn-upload-imageupload avatarUpload" id="btn-avatar-upload">
            <i class="fa fa-folder"></i>
            <input #imageupload type="file" name="files[]" multiple="" title=" ">
        </span>
        <button type="button" class="evebutton avatarUpload" id="btn-remove-imageupload" (click)="onRemoveImageClick()"><i class="fa fa-trash"></i></button>
      </div>
    </div>
   
    <div class="eve-imageupload-buttons">
      <button type="button" style="margin-right: 10px;"  #saveImage class="evebutton" id="btn-save-imageupload" (click)="onSaveImageClick()">add</button>
      <button type="button" style="margin-left: 10px;" class="evebutton" id="btn-cancel-imageupload" data-dismiss="modal" (click)="onCancelClick()">cancel</button>
    </div>
  </div>
</div>
