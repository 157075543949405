import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'eve-input',
  templateUrl: './eve-input.component.html',
  styleUrls: ['./eve-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveInputComponent),
      multi: true
    }
  ]
})

export class EveInputComponent implements OnInit, ControlValueAccessor {
  @Input() eveModel?: string = '';
  @Output() eveModelChange = new EventEmitter<string>();

  @Input() placeholder:string = '';

  onChange: any = () => {}
  onTouch: any = () => {}

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;

  constructor() {  }

  ngOnInit(): void {
  }

  onAddTitle() {
    this.eveModelChange.emit(this.eveModel);

    this.onChange(this.eveModel);
    this.onTouch(this.eveModel);
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.eveModel = value;
  }
}
