import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {
    EveUser,
    GetEveUsersGQL,
    LicenseType,
} from '../../../../../generated/graphql';
import {
    BehaviorSubject,
    Observable,
    of,
    map
} from 'rxjs';
import { EveCarouselSearchResult } from '../../../../components/eve-carousel/eve-carousel.component';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'eve-user-selected-list',
  templateUrl: './eve-user-selected-list.component.html',
  styleUrls: ['./eve-user-selected-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveUserSelectedListComponent),
      multi: true
    }
  ]
})
export class EveUserSelectedListComponent implements ControlValueAccessor {

   @Input() organisationNode : any;
   @Input() currentUsers !: EveUser[];


   @Input() selectedUsers !: EveUser[];
   @Output() selectedUsersChange: EventEmitter<EveUser[]> = new EventEmitter<EveUser[]>();

   @Input() title !: string;

   onChange: any = () => { }
   onTouch: any = () => { }

  constructor(public getEveUsersGQL: GetEveUsersGQL) {
    this.searchFunc_Remove = this.searchFunc_Remove.bind(this);
    this.searchFunc_Add = this.searchFunc_Add.bind(this);
  }
 



  searchFunc_Remove(search: string, pageSize: number, page: number): Observable<EveCarouselSearchResult<EveUser>> {

    var currentUsers = search == "" ? this.currentUsers : this.currentUsers.filter(u => (<EveUser>u).eveUserName.toLowerCase().indexOf(search.toLowerCase()) > -1 || (<EveUser>u).fullName.toLowerCase().indexOf(search.toLowerCase()) > -1 )

    return of({
      items: currentUsers.slice(pageSize * page, pageSize * (page + 1)),
      totalCount: currentUsers.length
    })
  }

  searchFunc_Add(search: string, pageSize: number, page: number): Observable<EveCarouselSearchResult<EveUser>> {
    return this.getEveUsersGQL.fetch({
      endUserSiteNode: this.organisationNode,
      search: search,
      skip: pageSize * page,
      take: pageSize,
      filters: [{
        eveUserId: {
          nin: this.currentUsers.map(u => u.eveUserId) //exclude any of the current users
        },
        licenseType: {
          in: [LicenseType.Entry, LicenseType.Essential, LicenseType.EveryThing, LicenseType.EveryWhere, LicenseType.Base, LicenseType.Prime]
        }
      }]
    }).pipe(
      map(r => {
        return {
          items: r.data.eveUsers?.items ?? [],
          totalCount: r.data.eveUsers?.totalCount ?? 0
        } as EveCarouselSearchResult<EveUser>;
      })
    );

   
  }

  onEveUserClick(user: EveUser) {

    const index = this.indexOf(user);

    if (index == -1) {
      this.selectedUsers.push(user);
    }
    else {
      this.selectedUsers.splice(index, 1);
    }


    this.selectedUsersChange.emit(this.selectedUsers);
    this.onChange(this.selectedUsers);
    this.onTouch(this.selectedUsers);

  }

  indexOf(user: EveUser): number {

    if (this.selectedUsers == null || this.selectedUsers.length === 0) {
      return -1;
    }

    return this.selectedUsers.findIndex(cqu => cqu.eveUserId == user.eveUserId);
  }

  isSelected(user: EveUser) { 
    return this.indexOf(user) > -1;
  }




  registerOnChange(fn: any) {
    this.onChange = fn
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.selectedUsers = value;
  }


  setDisabledState?(isDisabled: boolean): void {
  }



}
