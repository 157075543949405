import {Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'eve-date-picker',
  templateUrl: './eve-date-picker.component.html',
  styleUrls: ['./eve-date-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveDatePickerComponent),
      multi: true
    }
  ]
})
export class EveDatePickerComponent implements ControlValueAccessor{

  minDate: Date;

  @Input()
  ngModel!: Date;

  @Output() ngModelChange: EventEmitter<Date> = new EventEmitter<Date>();

  onChange: any = () => {}
  onTouch: any = () => {}

  @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;


  constructor() {
    this.minDate = new Date();
  }

  onDateChange(date: Date) {

    this.ngModel = date; 
    this.ngModelChange.emit(date);

    this.onChange(date);
    this.onTouch(date);
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.ngModel = value;
  }
}

