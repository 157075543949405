import { NgModule } from '@angular/core';
import { TestValidatorDirective } from "./test-validation/test-validation.directive";
import { LessThanOrEqualDirective } from "./less-than-or-equal/less-than-or-equal.directive";
import { GreaterThanOrEqualDirective } from "./greater-than-or-equal/greater-than-or-equal.directive";
import { GreaterThanDirective } from './greater-than/greater-than.directive';
import { CallQueueNameUniqueAsyncValidatorDirective } from "./call-queue-name-unique-async-validator/call-queue-name-unique-async-validator.directive";
import { LessThanDirective } from './less-than/less-than.directive';




@NgModule({
  declarations: [
    GreaterThanOrEqualDirective,
    GreaterThanDirective,
    CallQueueNameUniqueAsyncValidatorDirective,
    LessThanOrEqualDirective,
    LessThanDirective,

    TestValidatorDirective
  ],
  exports: [
    GreaterThanOrEqualDirective,
    GreaterThanDirective,
    CallQueueNameUniqueAsyncValidatorDirective,
    LessThanOrEqualDirective,
    LessThanDirective,

    TestValidatorDirective
  ]
})
export class ValidationDirectivesModule { }
