
<eve-container title={{title()}} [formGroup]="callQueue">
  <div class="eve-new-call-queue">


    <eve-avatar formControlName="avatarImageId" [organisationNode]="organisationNode"></eve-avatar>

    <div style="position:relative;">
      <div class="p-inputgroup">
        <input pInputText formControlName="name" placeholder="Name" />
      </div>
      <eve-validation-errors name="Name" [validationControl]="callQueue.controls['name']"></eve-validation-errors>
    </div>

    <div style="position:relative;">
      <div class="p-inputgroup">
        <input pInputText formControlName="description" placeholder="Description" />
      </div>
      <eve-validation-errors name="Description" [validationControl]="callQueue.controls['description']"></eve-validation-errors>
    </div>

    <div class="p-inputgroup">
      <eve-number-autocomplete placeholder="Number" [organisationNode]="organisationNode" formControlName="number"></eve-number-autocomplete>
    </div>

    <eve-call-recording formControlName="callRecording" class="p-inputwrapper" placeholder="Call Recording">
    </eve-call-recording>

    <ng-container *ngIf="callQueue?.get('isLight')?.value == false">
      <div style="text-align: center; margin-top:18px;" class="label-text">
        Queue Size
      </div>
      <eve-slider formControlName="queueSize" [min]=5 [max]=50 [step]="5"></eve-slider>
    </ng-container>
  </div>

  <br />


  <eve-tile-menu justify-content="center" formControlName="distributionType" style="width:460px; margin: auto" *ngIf="callQueue?.get('isLight')?.value == false">
    <eve-tile-menu-item icon="fa fa-list-ol fa-5x" name="Priority" [value]="distributionType.Priority"></eve-tile-menu-item>
    <eve-tile-menu-item icon="fa fa-star fa-5x" name="Skill Rating" [value]="distributionType.Skills"></eve-tile-menu-item>
  </eve-tile-menu>



  <eve-user-selected-list formControlName="users" [title]="'Users'" [currentUsers]="users" [organisationNode]="organisationNode"></eve-user-selected-list>



</eve-container>
