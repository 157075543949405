import {
    Component,
    ContentChild,
    Input,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChildren,
    forwardRef
} from '@angular/core';
import {EveMultipleSelectionItemsComponent} from '../eve-multiple-selection-items/eve-multiple-selection-items.component';
import {MultiSelection} from '../../classes/multi-selection';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AreaNumbersFilterInput, AvailableDdiModel} from 'src/generated/graphql';
import {GetAvailableOrdersByAreaGQL, GetAvailableAreasGQL} from '../../../generated/graphql';

@Component({
    selector: 'eve-number-selection-list',
    templateUrl: './eve-number-selection-list.component.html',
    styleUrls: ['./eve-number-selection-list.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EveNumberSelectionListComponent),
            multi: true
        }
    ]
})
export class EveNumberSelectionListComponent implements OnInit, ControlValueAccessor {

    @ContentChild('validationTemplate') validationTemplate!: TemplateRef<any>;
    @Input()
    placeholder : string = "Numbers";

    private _filteredInput !: AreaNumbersFilterInput;
    @Input()set filteredInput(value : AreaNumbersFilterInput) {
        this._filteredInput = value;

        if (value.area === null || value.area === undefined) {
            this.eveModel = null,
            this.numbers = null,
            this.multipleSelection ?. setInactives();
        } else {
            this.getAvailableOrderByAreaGQL.fetch({
                filter: value
            }, {fetchPolicy: 'no-cache'}).subscribe({
                next: (response : any) => {
                    this.numbers = JSON.parse(JSON.stringify(response.data.availableOrdersByArea));
                }
            })
        }
    }

    get filteredInput(): AreaNumbersFilterInput {
        return this._filteredInput;
    }

    numbers : AvailableDdiModel[] | null = null;

    @ViewChildren(EveMultipleSelectionItemsComponent)eveMultipleSelectionItemsComponentQueryList !: QueryList < EveMultipleSelectionItemsComponent >;

    private multipleSelection ! : MultiSelection < EveMultipleSelectionItemsComponent >;

    public eveModel : string[] | null = null;
    onChange : any = () => {}
    onTouch : any = () => {}

    math = Math;

    constructor(private getAvailableOrderByAreaGQL : GetAvailableOrdersByAreaGQL) {}
    writeValue(value : any): void {
        this.eveModel = value
    }
    registerOnChange(fn : any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn : any): void {
        this.onTouch = fn;
    }
    setDisabledState ? (isDisabled
    : boolean) : void {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {}

    ngAfterViewInit() {

        this.eveMultipleSelectionItemsComponentQueryList.changes.subscribe(x => {
            this.multipleSelection = new MultiSelection<EveMultipleSelectionItemsComponent>(this.eveMultipleSelectionItemsComponentQueryList);
            this.multipleSelection.activeChanges$.subscribe((x : any) => {
                this.eveModel = x.map((x : any) => {
                    return x.id;
                });

                this.onChange(this.eveModel);
                this.onTouch(this.eveModel);
            })
        });
    }

    clearOrderNumberSelection = () => {
      this.multipleSelection?.setInactives();
      this.multipleSelection?.clear();
        this.eveModel = null;
    }

}


interface OrderNumberModel {
    number: string,
    levelCode: string,
    supplierCode: string
}

enum DdiLevel {
    Standard = 0,
    Gold = 1
}
