

<div [style.display]="editingPrompt != null ? 'none' : 'block'">

  <div>
    <button mat-button mat-raised-button color="primary" (click)="onNewPromptClick()">
      <i class="fa fa-plus"></i>
      New Prompt
    </button>

    <input pInputText placeholder="Search" (keyup)="onSearchChange($event)" style="float:right; width:267px;" />
  </div>
  <ng-template #controls>
    <div style="display: flex; justify-content: center; width: 100%;">
      <button class="evebutton" (click)="paginator.firstPage()" [disabled]="paginator.hasPreviousPage() == false"><< </button>
      <button class="evebutton" (click)="paginator.previousPage()" [disabled]="paginator.hasPreviousPage() == false"><</button>
      <button class="evebutton" (click)="paginator.nextPage()" [disabled]="paginator.hasNextPage() == false">></button>
      <button class="evebutton" (click)="paginator.lastPage()" [disabled]="paginator.hasNextPage() == false">>></button>
    </div>
  </ng-template>


  <ng-container [ngTemplateOutlet]="controls"></ng-container>


  <table mat-table [dataSource]="prompts | async" style="width: 100%" class="evetable">



    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width: 120px"> Action </th>
      <td mat-cell *matCellDef="let element">

        <audio #audio (pause)="onChangeDetection()" (ended)="onChangeDetection()"></audio>

        <button *ngIf="audio.paused == true" class="evebutton eveiconbutton" style="border-radius: 4px"
                (click)="onPlayPrompt(element, audio);$event.stopPropagation();" [disabled]="element == null">
          <i class="fa fa-play"></i>
        </button>

        <button *ngIf="audio.paused == false" class="evebutton eveiconbutton" style="border-radius: 4px"
                (click)="onPause(audio);$event.stopPropagation();" [disabled]="element == null">
          <i class="fa fa-pause"></i>
        </button>

        <button class="evebutton eveiconbutton" (click)="onEditClick(element)" style="border-radius: 4px">
          <i class="fa fa-pencil"></i>
        </button>

        <button class="evebutton eveiconbutton" (click)="onDeleteClick(element)" style="border-radius: 4px">
          <i class="fa fa-trash-o"></i>
        </button>


      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <mat-paginator #paginator [length]="totalCount" [pageSize]="8" [pageSizeOptions]="[4, 8, 12]">
  </mat-paginator>



  <ng-container [ngTemplateOutlet]="controls"></ng-container>
</div>

<ng-container *ngIf="editingPrompt != null">
  <mat-stepper [linear]="true" #stepper [animationDuration]="0" >
    <mat-step [stepControl]="fileFormGroup">
      <form [formGroup]="fileFormGroup">
        <ng-template matStepLabel>Fill out your name</ng-template>
        <div style="text-align: center;">Drag and Drop your .Wav file below</div>


        <div class="eve-prompt-manager-file" (click)="onFileUploadClick()" *ngIf="fileFormGroup.get('libraryId')?.errors?.['uploadFailed'] == null  && fileFormGroup.get('libraryId')?.value == null">
          <div>
            <div style="text-align: center;">
              <span class="fa fa-upload" style="margin: auto; font-size: 60px; "></span>
            </div>
            <div style="text-align: center;">
              <div class="eve-prompt-manager-file-none">
                Or alternatively, click here to browse
              </div>
            </div>
          </div>
        </div>


        <div class="eve-prompt-manager-file eve-prompt-manager-file-success" (click)="onFileUploadClick()" *ngIf="fileFormGroup.get('libraryId')?.errors?.['uploadFailed'] == null  && fileFormGroup.get('libraryId')?.value != null">
          <div>
            <div style="text-align: center;">
              <span class="fa fa-volume-up" style="margin: auto; font-size: 60px; "></span>
            </div>
            <div style="text-align: center;">
              <div class="eve-prompt-manager-file-selected">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 85%; margin: auto; vertical-align: middle;">{{this.fileFormGroup.get("fileName")?.value}}</div>
                Uploaded Successfully
              </div>
            </div>
          </div>


        </div>




        <div class="eve-prompt-manager-file eve-prompt-manager-file-failed" (click)="onFileUploadClick()" *ngIf="fileFormGroup.get('libraryId')?.errors?.['uploadFailed'] != null">
          <div>
            <div style="text-align: center;">
              <span class="fa fa-exclamation-circle" style="margin: auto; font-size: 60px; "></span>
            </div>

            <div style="text-align: center;">
              <div class="eve-prompt-manager-file-failed">
                <div style="margin: auto; vertical-align: middle;">{{fileFormGroup.get('libraryId')?.errors?.['uploadFailed']}}</div>
                Click here to try again
              </div>
            </div>
          </div>


        </div>

        <input #fileInput type="file" formControlName="file" style="display: none;" accept=".wav">
        <div style="float:right">
          <button class="evebutton" (click)="editingPrompt = null">Cancel</button>
          <button class="evebutton" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="detailsFormGroup" label="Fill out your address">
      <form [formGroup]="detailsFormGroup">
        <div style="text-align:center">
          Enter details for your prompt
        </div>
        <div class="PromptManager-PromptFile" style="border-radius: 10px; background-color: #DFEED6; padding: 5px 10px; font-size: 18px; color: #80BD5A; margin: 10px 0; height: 40px; }">
          <i class="fa fa-volume-up"></i>

          {{this.fileFormGroup.get("fileName")?.value}}

        <div class="PromptManager-PlayBackControls" style="float:right">
          <audio #audio (pause)="onChangeDetection()" (ended)="onChangeDetection()"></audio>

          <i class="fa fa-play prompt-manager-play-button" *ngIf="audio.paused == true" (click)="onPlayLibrary(fileFormGroup.get('libraryId')?.value, audio)"></i>
          <i class="fa fa-pause prompt-manager-play-button" *ngIf="audio.paused == false" (click)="onPause(audio)"></i>
          <i class="fa fa-stop prompt-manager-play-button" (click)="onStop(audio)"></i>
          <a class="fa fa-cloud-download" href="{{ '/library/' + fileFormGroup.get('libraryId')?.value  | evePortalCoreUrl }}" download="true"></a>
        </div>

        </div>

        <div style="position:relative;">
          <div>Name</div>
          <input pInputText formControlName="name" required style="width: 100%;">
          <eve-validation-errors name="Name" [validationControl]="detailsFormGroup.controls['name']"></eve-validation-errors>
        </div>

        <div style="position:relative;">
          <div>Description</div>
          <textarea rows="4" pInputTextarea formControlName="description" required style="width: 100%; resize: none;"></textarea>
          <eve-validation-errors name="Description" [validationControl]="detailsFormGroup.controls['description']"></eve-validation-errors>
        </div>

        <div style="float:right">

          <button class="evebutton" (click)="editingPrompt = null">Cancel</button>
          <button class="evebutton"matStepperPrevious>Prev</button>
          <button class="evebutton" (click)="onSaveClick()">Save</button>
        </div>
      </form>
    </mat-step>

  </mat-stepper>

  </ng-container>


  <eve-loading></eve-loading>

