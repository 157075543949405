import { Pipe, PipeTransform } from '@angular/core';
import { CallRecording } from '../../../generated/graphql';


@Pipe({name: 'callRecording'})
export class CallRecordingPipe implements PipeTransform {
  transform(value: CallRecording): String {
    if (value == CallRecording.None) return "None";
    if (value == CallRecording.Days30) return "30 Days";
    if (value == CallRecording.Months6) return "6 Months";
    if (value == CallRecording.Months12) return "12 Months";
    if (value == CallRecording.Years7) return "7 Years";
    return "";
  }
}



